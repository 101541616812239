import * as React from "react";
import * as C from "components";
import { viewHOC } from "hocs";
import * as LC from "./components";
import { ViewHOCChildProps, Client } from "types";
import { getQueryKey } from "utils";

const View: React.FC<ViewHOCChildProps<Client>> = ({ record }) => {
  return (
    <C.Tabs defaultActiveKey="summary">
      <C.Tabs.TabPane tab="Financial" key="summary">
        <LC.Summary record={record} />
      </C.Tabs.TabPane>
      <C.Tabs.TabPane tab="Contact" key="contact">
        <LC.Contact record={record} />
      </C.Tabs.TabPane>
      <C.Tabs.TabPane tab="Address" key="address">
        <LC.Address record={record} />
      </C.Tabs.TabPane>
    </C.Tabs>
  );
};

export default viewHOC({
  Component: View,
  apiName: "clients",
  pathFn: ({ params }) => `/${params.id}`,
  queryKeyFn: ({ params }) => getQueryKey("clientRecord", params.id),
});
