import { FC } from "react";
import { Space } from "antd";
import ExtendedTable from "components/extended-table";
import { ShowImage } from "./components";
import { SumSubRequiredDocsStatus, Country } from "types";

interface RequiredIdDocsProps {
  countryList: Country[];
  inspectionId: string;
  SELFIE?: SumSubRequiredDocsStatus;
  IDENTITY?: SumSubRequiredDocsStatus;
}

const RequiredIdDocs: FC<RequiredIdDocsProps> = ({
  inspectionId,
  SELFIE,
  IDENTITY,
  countryList,
}) => {
  const dataSource = [
    ...(SELFIE
      ? [
          {
            idDocType: SELFIE.idDocType,
            country: SELFIE.country,
            imageIDs: SELFIE.imageIds,
          },
        ]
      : []),
    ...(IDENTITY
      ? [
          {
            idDocType: IDENTITY.idDocType,
            country: IDENTITY.country,
            imageIDs: IDENTITY.imageIds,
          },
        ]
      : []),
  ] as const;

  return (
    <ExtendedTable<typeof dataSource[number]>
      dataSource={dataSource}
      columns={[
        {
          title: "verifications.idDocType",
          dataIndex: "idDocType",
        },
        {
          title: "common.country",
          dataIndex: "country",
          render: ({ value }) =>
            countryList.find((c) => c.iso_alpha_3 === value)?.full_name,
        },
        {
          title: "common.id",
          dataIndex: "imageIDs",
          render: ({ record }) => (
            <Space>
              {record.imageIDs.map((imgId) => (
                <ShowImage
                  key={imgId}
                  imgId={imgId}
                  inspectionId={inspectionId}
                />
              ))}
            </Space>
          ),
        },
      ]}
    />
  );
};

export default RequiredIdDocs;
