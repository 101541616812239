import { FC } from "react";
import { ExtendedDescriptions } from "components";
import { TrustApplication } from "types";

interface TrustProps {
  app: TrustApplication;
}

const Trust: FC<TrustProps> = ({ app }) => {
  return (
    <ExtendedDescriptions
      items={[
        {
          label: "common.companyName",
          value: app.trustName,
        },
        {
          label: "common.type",
          value: app.trusteeType,
        },
        ...(app.trusteeTypeId === 1
          ? [
              {
                label: "common.first_name",
                value: app.trusteeFirstName,
              },
              {
                label: "common.last_name",
                value: app.trusteeLastName,
              },
            ]
          : [
              {
                label: "common.companyName",
                value: app.trusteeCompanyName,
              },
              {
                label: "common.companyNumber",
                value: app.trusteeCompanyNumber,
              },
            ]),
      ]}
    />
  );
};

export default Trust;
