import { FC } from "react";
import { Tabs, Verification } from "components";
import { viewHOC } from "hocs";
import * as LC from "./components";
import { ViewProps } from "./types";
import { getQueryKey } from "utils";

const View: FC<ViewProps> = ({ record }) => {
  return (
    <Tabs
      items={[
        {
          key: "summary",
          label: "Summary",
          children: <LC.Summary record={record} />,
        },
        {
          key: "personal",
          label: "Personal",
          children: <LC.Personal {...record} />,
        },
        {
          key: "verify",
          label: "Verification",
          children: (
            <Verification parentID={record.uuid} parentRoute="verifyExternal" />
          ),
        },
      ]}
    />
  );
};

export default viewHOC<"uuid">({
  Component: View,
  queryKeyFn: ({ params }) => getQueryKey("verifyExternalRecord", params.uuid),
  apiName: "verifyExternal",
  pathFn: ({ params }) => `/${params.uuid}`,
});
