import { FC } from "react";
import { Types } from "../../duck";
import * as C from "components";

const Branding: FC<Types.TabProps> = ({ account, updating, onFinish }) => {
  return (
    <C.Form layout="vertical" initialValues={account} onFinish={onFinish}>
      <C.Row gutter={[12, 12]}>
        <C.Col span={12}>
          <C.Row gutter={[12, 12]}>
            <C.Col>
              <C.InputText name="product_name" label="account.product_name" />
            </C.Col>
            <C.Col>
              <C.InputText name="website" label="account.website" />
            </C.Col>
            <C.Col>
              <C.InputText
                name="page_header_logo"
                label="account.page_header_logo"
              />
            </C.Col>
            <C.Col>
              <C.InputText name="favicon" label="account.favicon" />
            </C.Col>
          </C.Row>
        </C.Col>
      </C.Row>
      <C.SubmitButton loading={updating} />
    </C.Form>
  );
};

export default Branding;
