import { createSlice, PayloadAction, isAnyOf } from "@reduxjs/toolkit";
import { User, InitialData } from "types";
import { Types, asyncActions } from "./duck";

const initialState: Types.State = {
  authenticating: true,
  loggingOut: false,
  confirmSignup: false,
  loading: false,
  user: null,
  staff: null,
  expiredUser: undefined,
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserStaff: (state, actions: PayloadAction<User>) => {
      state.user = actions.payload;
    },
    authenticateStart: (state) => {
      state.authenticating = true;
    },
    authenticateSuccess: (state, { payload }: PayloadAction<InitialData>) => {
      state.authenticating = false;
      state.user = payload.user;
      state.staff = payload.staff;
      state.expiredUser = undefined;
    },
    loginSuccess: (state, { payload }: PayloadAction<InitialData>) => {
      state.loading = false;
      state.user = payload.user;
      state.staff = payload.staff;
      state.expiredUser = undefined;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(asyncActions.logOut.pending, (state) => {
      state.loggingOut = true;
    });

    builder.addCase(asyncActions.logOut.fulfilled, () => ({
      ...initialState,
      authenticating: false,
    }));

    builder.addCase(asyncActions.loginError, (state, { payload }) => {
      state.loading = false;
      state.confirmSignup = !!payload?.confirmSignup;
    });

    builder.addCase(
      asyncActions.switchStaff.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.staff = payload.staff;
        state.user = payload.user;
      }
    );

    builder.addCase(asyncActions.authError, (state, { payload }) => {
      state.authenticating = false;
      state.expiredUser = payload
        ? {
            email: payload.email,
          }
        : undefined;
    });

    const pendingMatcher = isAnyOf(
      asyncActions.loginStart,
      asyncActions.switchStaff.pending
    );

    const rejectedMatcher = isAnyOf(asyncActions.switchStaff.rejected);

    builder.addMatcher(pendingMatcher, (state) => {
      state.loading = true;
    });
    builder.addMatcher(rejectedMatcher, (state) => {
      state.loading = false;
    });
  },
});

export const { reducer, actions } = auth;
