import { FC } from "react";
import { Skeleton, Tabs } from "components";
import * as LC from "./components";
import { viewHOC } from "hocs";
import { useSelector, useMutation } from "hooks";
import { getQueryKey } from "utils";
import { TabProps } from "./types";
import { Staff, ViewHOCChildProps } from "types";

const View: FC<ViewHOCChildProps<Staff>> = ({ record }) => {
  const { currentStaffID, cognitoID } = useSelector((state) => ({
    currentStaffID: state.auth.staff?.id,
    cognitoID: state.auth.user?.aws_cognito_id,
  }));

  const { isPending, mutate } = useMutation<Staff, Staff>({
    apiName: "staff",
    path: `/${record.id}`,
    method: "patch",
    invalidateQueries: [
      getQueryKey("staffList", cognitoID),
      getQueryKey("staffRecord", record.id),
    ],
  });

  const commonProps: TabProps = {
    submit: mutate,
    submitting: isPending,
    staff: record,
  };

  return (
    <Skeleton active loading={!record}>
      <Tabs
        defaultActiveKey="summary"
        items={[
          {
            key: "summary",
            label: "Summary",
            children: <LC.Summary {...commonProps} />,
          },
          {
            key: "contact",
            label: "Contact",
            children: <LC.Contact {...commonProps} />,
          },
          {
            disabled: currentStaffID === record.id,
            key: "settings",
            label: "Settings",
            children: <LC.Settings staff={record} />,
          },
        ]}
      />
    </Skeleton>
  );
};

export default viewHOC({
  Component: View,
  queryKeyFn: ({ params }) => getQueryKey("staffRecord", params.id),
  apiName: "staff",
  pathFn: ({ params }) => `/${params.id}`,
});
