import { useMemo, FC } from "react";
import { Layout, Routes, Route, Navigate, CatchAll } from "components";
import { useSelector, useLocation, useAccountSubscription } from "hooks";
import * as LC from "./components";
import { settings } from "features";
import { createUseStyles } from "react-jss";
import { routes } from "configs";
import { selectors, hooks, operations } from "./duck";
import { Account } from "types";

export const useStyles = createUseStyles({
  subscriptionError: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& .ant-alert-content": {
      flex: "none",
    },
  },
  layout: { backgroundColor: "#fff", height: "100vh" },
  layoutContent: {
    backgroundColor: "#fff",
    minHeight: 360,
    overflowY: "auto",
    padding: 24,
  },
});

interface LayoutProps {
  account?: Account;
}

const AppLayout: FC<LayoutProps> = ({ account }) => {
  const { user, appVersion, staff } = useSelector((state) => ({
    user: state.auth.user,
    staff: state.auth.staff,
    appVersion: state.appState.appVersion,
  }));
  const { pathname, search } = useLocation();
  const classes = useStyles();

  hooks.useSubscribeApplicationMessages();

  const authenticated = !!user;
  const hasSider = !["/doc-scan", "/login"].includes(pathname) && authenticated;
  const {
    subscriptionActive,
    subscription,
    subscriptionLoading,
    queryKey: subscriptionQueryKey,
  } = useAccountSubscription();

  const appRoutes = useMemo(
    () =>
      selectors.getRoutes({
        permissions: staff?.permissions,
      }),
    [staff?.permissions]
  );

  const redirect = operations.queryString("redirect");

  return (
    <Layout className={classes.layout} hasSider={hasSider}>
      {hasSider && <LC.Menu />}
      <Layout>
        {account && (
          <LC.AccountInfoMessage
            subscriptionActive={subscriptionActive}
            subscriptionLoading={subscriptionLoading}
            subscription={subscription}
            accountStatusID={account?.statusID}
          />
        )}
        {authenticated && <LC.Header />}
        <Layout.Content className={classes.layoutContent}>
          <Routes>
            <Route
              path={routes.login}
              element={
                !authenticated ? (
                  <LC.Login />
                ) : (
                  <Navigate to={redirect || routes.dashboard} replace />
                )
              }
            />
            <Route
              path={routes.settings.account()}
              element={
                authenticated ? (
                  <settings.Account
                    subscription={subscription}
                    subscriptionLoading={subscriptionLoading}
                    subscriptionActive={subscriptionActive}
                    subscriptionQueryKey={subscriptionQueryKey}
                  />
                ) : (
                  <Navigate
                    to={`${routes.login}?redirect=${pathname}${search}`}
                    replace
                  />
                )
              }
            />
            {appRoutes.map(({ Component, path, visible }) => {
              if (!visible) {
                return null;
              }

              return (
                <Route
                  key={path}
                  path={path}
                  element={
                    authenticated ? (
                      <Component />
                    ) : (
                      <Navigate
                        to={`${routes.login}?redirect=${pathname}${search}`}
                        replace
                      />
                    )
                  }
                />
              );
            })}
            <Route
              path="/"
              element={
                <Navigate
                  to={authenticated ? routes.dashboard : routes.login}
                />
              }
            />
            <Route path="*" element={<CatchAll />} />
          </Routes>
        </Layout.Content>
        <LC.Footer appVersion={appVersion} />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
