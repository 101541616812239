import { ListenerEffectAPI, PayloadAction } from "@reduxjs/toolkit";
import { Auth, showNotification } from "utils";
import { notification } from "antd";
import { authActions, authAsyncActions } from "../slices";
import { operations } from "./duck";

export default async (
  action: PayloadAction<{ email: string; password: string }>,
  listenerApi: ListenerEffectAPI<any, any>
) => {
  // Can cancel other running instances
  listenerApi.cancelActiveListeners();
  let expiredPasswordError:
    | {
        email: string | null;
      }
    | undefined;

  try {
    const { payload } = action as any;
    const email = payload.email.toLowerCase();

    const { nextStep } = await Auth.signIn({
      username: email,
      password: payload.password,
    });

    if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
      listenerApi.dispatch(
        authAsyncActions.loginError({
          confirmSignup: true,
        })
      );
      return;
    }

    const { userId: cognitoID } = await Auth.getCurrentUser();

    expiredPasswordError = { email };

    const data = await operations.getInitialData(cognitoID);

    listenerApi.dispatch(authActions.loginSuccess(data));
  } catch (e: any) {
    listenerApi.dispatch(authAsyncActions.loginError());

    if (e.message.indexOf("expired") !== -1) {
      // do not log out cognito user from the browser.
      // we need to retrieve userSub in frontend/src/layout/components/login/components/reset-password-modal/ResetPasswordModal.tsx

      const state = listenerApi.getState() as any;
      showNotification({
        type: "error",
        language: state.appState.language,
        message: "password.expired",
      });

      listenerApi.dispatch(authAsyncActions.authError(expiredPasswordError));
    } else {
      notification.error({
        message: e.message,
      });
    }
  }
};
