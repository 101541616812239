import { NavLink, ExtendedTable } from "components";
import { Client } from "types";
import { useQuery, useSelector } from "hooks";
import { routes } from "configs";
import { getQueryKey } from "utils";

const List = () => {
  const accountID = useSelector((state) => state.auth.staff?.account_id);
  const { data = [], isLoading } = useQuery<Client[]>({
    apiName: "accounts",
    path: `/${accountID}/clients`,
    enabled: !!accountID,
    queryKey: getQueryKey("clients", accountID),
  });

  return (
    <ExtendedTable
      loading={isLoading}
      dataSource={data}
      columns={[
        {
          title: "common.id",
          dataIndex: "id",
          sorter: (a, b) => a.id - b.id,
          defaultSortOrder: "descend",
          render: ({ value }) => (
            <NavLink to={routes.clients.details(value)}>{value}</NavLink>
          ),
        },
        {
          title: "common.name",
          dataIndex: "file_by",
        },
        {
          title: "common.email",
          dataIndex: "email",
        },
      ]}
    />
  );
};

export default List;
