import { FC } from "react";
import { Form, DatePicker } from "antd";
import { useIntl } from "hooks";
import { DATE_FORMAT, DB_DATE_FORMAT } from "consts";
import dayjs from "dayjs";
import * as duck from "./duck";

const InputDatePicker: FC<duck.Types.InputDatePickerProps> = ({
  label,
  name,
  allowClear = false,
  required = false,
  rules = [],
  isAfterToday,
}) => {
  const intl = useIntl();

  return (
    <Form.Item
      label={label && intl.formatMessage({ id: label, defaultMessage: "" })}
      name={name}
      normalize={(dayjsValue) =>
        dayjsValue && dayjsValue.startOf("day").format(DB_DATE_FORMAT)
      }
      getValueProps={(value) =>
        value && { value: dayjs(value, DB_DATE_FORMAT).startOf("day") }
      }
      rules={[
        {
          required,
          message: intl.formatMessage({ id: "validation.required" }),
        },
        {
          validator: (rule, value) => {
            if (!value || !isAfterToday) {
              return Promise.resolve();
            }

            if (dayjs(value).valueOf() > dayjs().valueOf()) {
              return Promise.reject(
                intl.formatMessage({ id: "validation.futureDate" })
              );
            }

            return Promise.resolve();
          },
        },
        ...rules,
      ]}
    >
      <DatePicker
        placeholder={DATE_FORMAT}
        style={{ width: "100%" }}
        format={DATE_FORMAT}
        allowClear={allowClear}
      />
    </Form.Item>
  );
};
export default InputDatePicker;
