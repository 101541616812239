import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { Language, BreakpointMap } from "types";
import { Types, asyncActions } from "./duck";
import { authActions, authAsyncActions } from "../auth";

const initialState: Types.State = {
  loading: false,
  languageSwitching: false,
  language: (localStorage.getItem("language") as Language | null) || "en",
  userGeo: null,
  appVersion: null,
  truliooActive: false,
  streetTypes: [],
  genders: [],
  breakpoint: {
    mobile: false,
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  },
};

export const clients = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setBreakpoints: (state, action: PayloadAction<Partial<BreakpointMap>>) => {
      state.breakpoint = {
        ...state.breakpoint,
        ...action.payload,
        mobile:
          (action.payload.sm && !action.payload.md) || !!action.payload.xs,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getUserGeo.fulfilled, (state, { payload }) => {
      state.userGeo = payload;
    });

    builder.addCase(
      asyncActions.getTranslatedData.fulfilled,
      (state, { payload }) => {
        state.languageSwitching = false;

        state.genders = payload.genders;
        state.streetTypes = payload.streetTypes;
      }
    );

    builder.addCase(asyncActions.getTranslatedData.pending, (state) => {
      state.languageSwitching = true;
    });

    builder.addCase(asyncActions.getTranslatedData.rejected, (state) => {
      state.languageSwitching = false;
    });

    builder.addCase(authAsyncActions.logOut.fulfilled, () => initialState);

    const matcher = isAnyOf(
      authActions.authenticateSuccess,
      authActions.loginSuccess
    );

    builder.addMatcher(matcher, (state, { payload }) => {
      state.loading = false;
      state.appVersion = payload.appVersion;
      state.truliooActive = payload.truliooActive;
    });
  },
});

export const { reducer, actions } = clients;
