import { FC } from "react";
import {
  InputEmail,
  Form,
  InputText,
  Button,
  FormattedMessage,
  Drawer,
} from "components";
import { useSelector, useMutation } from "hooks";
import { VerifyExternal, QueryKey } from "types";
import { getQueryKey } from "utils";

interface FormValues {
  fistName: string;
  lastName: string;
  email: string;
  accountID: number;
}

interface CreateNewProps {
  invalidateQuery: QueryKey;
  drawerOpen: boolean;
  onClose: () => void;
}

const CreateNew: FC<CreateNewProps> = ({
  drawerOpen,
  onClose,
  invalidateQuery,
}) => {
  const [form] = Form.useForm();
  const accountID = useSelector(
    (state) => state.auth.staff?.account_id as number
  );
  const { isPending, mutate } = useMutation<VerifyExternal, FormValues>({
    method: "post",
    apiName: "accounts",
    path: `/${accountID}/verify-external`,
    onSuccess: onClose,
    invalidateQueries: [
      invalidateQuery,
      getQueryKey("subscriptionUsage", accountID),
    ],
  });

  return (
    <Drawer
      open={drawerOpen}
      onClose={onClose}
      destroyOnClose
      extra={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          <FormattedMessage id="button.submit" />
        </Button>
      }
    >
      <Form<FormValues>
        form={form}
        preserve={false}
        layout="vertical"
        onFinish={async (values) => {
          mutate(values);
        }}
      >
        <InputText required name="firstName" label="common.firstName" />
        <InputText required name="lastName" label="common.lastName" />
        <InputEmail required name="email" label="common.email" />
      </Form>
    </Drawer>
  );
};

export default CreateNew;
