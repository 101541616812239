// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
import { loadStripe } from "@stripe/stripe-js";
import { green, gold, blue } from "@ant-design/colors";
import { STRIPE_PUBLIC_KEY } from "./env";
import { BillingPlanEnum } from "types/billing";

export const BILLING_PLAN_COLOR: Record<number, string> = {
  [BillingPlanEnum.UsageOnly]: green[2],
  [BillingPlanEnum.Bronze]: gold[6],
  [BillingPlanEnum.Silver]: blue[2],
  [BillingPlanEnum.Gold]: gold[4],
};

export const STRIPE_PROMISE = loadStripe(STRIPE_PUBLIC_KEY);

export const ACCEPTED_FILES = ".pdf, .doc, .docx, .jpg, .jpeg, .png, .msword";

export const COMMON_COL_PROPS = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
};

export const ROW_GUTTER = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};

export const BREAKPOINTS = {
  MD: "768px",
};

export const ACCOUNT_SETTINGS_TABS = {
  SUMMARY: "SUMMARY",
  CONTACT: "CONTACT",
  BRANDING: "BRANDING",
  SOFTWARE: "SOFTWARE",
  CURRENT_SUBSCRIPTION: "CURRENT_SUBSCRIPTION",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  USAGE: "USAGE",
} as const;
