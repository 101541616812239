import { FC } from "react";
import { Tabs, Verification } from "components";
import { viewHOC } from "hocs";
import { Types } from "./duck";
import * as LC from "./components";
import { getQueryKey } from "utils";

const View: FC<Types.ViewProps> = ({ record }) => {
  return (
    <Tabs
      defaultActiveKey="Summary"
      items={[
        {
          label: "Summary",
          key: "Summary",
          children: <LC.Summary record={record} />,
        },
        {
          label: "Personal",
          key: "Personal",
          children: <LC.Personal record={record} />,
        },
        {
          label: "Verification",
          key: "Verification",
          children: (
            <Verification
              parentID={record.id}
              parentRoute="informationRequests"
            />
          ),
        },
      ]}
    />
  );
};

export default viewHOC({
  Component: View,
  apiName: "informationRequests",
  pathFn: ({ params }) => `/${params.id}`,
  queryKeyFn: ({ params }) =>
    getQueryKey("informationRequestRecord", params.id),
});
