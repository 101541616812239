// Create the middleware instance and methods
import {
  createListenerMiddleware,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { authActions, authAsyncActions } from "../slices";
import { authEffect } from "./auth";
import loginEffect from "./login";
import { InitialData } from "types";
import dayjs from "dayjs";
import { notification, Typography } from "antd";
import routes from "configs/routes";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: authActions.authenticateStart,
  effect: (action, listenerAPI) => {
    authEffect(listenerAPI);
  },
});

listenerMiddleware.startListening({
  actionCreator: authAsyncActions.loginStart,
  effect: (action, listenerAPI) => {
    loginEffect(action, listenerAPI);
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(authActions.authenticateSuccess, authActions.loginSuccess),
  effect: (action) => {
    const { passwordExpiry } = (action as PayloadAction<InitialData>).payload
      .user;

    const diff = dayjs(passwordExpiry).diff(dayjs(), "week");

    if (diff <= 4) {
      const week = diff <= 1 ? "week" : "weeks";
      const str = diff === 0 ? `this ${week}` : `in ${diff} ${week}`;

      notification.warning({
        message: `Warning your password will expire ${str}.`,
        description: (
          <Typography.Link target="_blank" href={routes.settings.userProfile()}>
            You can change it here
          </Typography.Link>
        ),
      });
    }
  },
});

export default listenerMiddleware.middleware;
