import { FC } from "react";
import { ExtendedTable, Table, ExtendedTag } from "components";
import { useQuery } from "hooks";
import { getQueryKey, formatToCurrency } from "utils";
import { DATE_TIME_FORMAT, BILLING_PLAN_COLOR } from "consts";
import dayjs from "dayjs";
import { SubscriptionUsage, ColumnFilterInputTypes } from "types";

interface UsageProps {
  accountID: number;
}

const Usage: FC<UsageProps> = ({ accountID }) => {
  const { data, isLoading, isRefetching } = useQuery<{
    credits: number;
    data: SubscriptionUsage[];
  }>({
    apiName: "accounts",
    path: `/${accountID}/subscription-usage`,
    queryKey: getQueryKey("subscriptionUsage", accountID),
  });

  return (
    <ExtendedTable<SubscriptionUsage>
      loading={isLoading || isRefetching}
      dataSource={data?.data}
      columns={[
        {
          title: "common.description",
          dataIndex: "description",
          render: ({ record, value }) => (
            <ExtendedTag
              backgroundColor={BILLING_PLAN_COLOR[record.billingPlanID]}
            >
              {value}
            </ExtendedTag>
          ),
        },
        {
          title: "common.price",
          dataIndex: "price",
          render: ({ value }) => `$${value}`,
        },
        {
          title: "date.createdAt",
          dataIndex: "createdAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
        },
      ]}
      summary={() => {
        const total = data?.data.reduce((prev, curr) => prev + curr.price, 0);

        return (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                Summary &nbsp;
                <strong>{formatToCurrency(total)}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                Credits &nbsp;
                <strong>{data?.credits || 0}</strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
    />
  );
};

export default Usage;
