import { FC } from "react";
import { useAccountList } from "hooks";
import { Client } from "types";
import {
  Form,
  Row,
  Col,
  InputText,
  InputSelect,
  InputEmail,
  Button,
} from "components";

const Summary: FC<{ record: Client }> = ({ record }) => {
  const { accountList, accountListLoading } = useAccountList();

  return (
    <Form layout="vertical" name="summary" initialValues={record}>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Row gutter={[12, 12]}>
            <Col>
              <InputText required name="first_name" label="common.firstName" />
            </Col>
            <Col>
              <InputText name="middle_name" label="common.middleName" />
            </Col>
          </Row>
          <InputText required name="last_name" label="common.lastName" />
        </Col>
        <Col span={12}>
          <InputSelect
            required
            name="account_id"
            label="common.account"
            loading={accountListLoading}
            options={accountList}
            getOptionProps={(option) => ({
              value: option.id,
              children: option.account_name,
            })}
          />
          <InputEmail required name="email" label="common.email" />
        </Col>
      </Row>
      <Row justify="end">
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Row>
    </Form>
  );
};

export default Summary;
