import { FC } from "react";
import { ExtendedDescriptions, CheckOutlined, CloseOutlined } from "components";
import { COLORS } from "consts";
import { useQuery } from "hooks";
import { Knowledge, IndividualApplication } from "types";
import { getQueryKey } from "utils";
import { createUseStyles } from "react-jss";

interface KnowledgeProps {
  app: IndividualApplication;
}

const useStyles = createUseStyles({
  check: {
    color: COLORS.green.primary,
  },
  close: {
    color: COLORS.red.primary,
  },
});

const KnowledgeComponent: FC<KnowledgeProps> = ({ app }) => {
  const { data, isLoading } = useQuery<Knowledge>({
    apiName: "applications",
    path: `/${app.id}/knowledge`,
    queryKey: getQueryKey("knowledge", { application: app.id }),
  });
  const classes = useStyles();

  const showValue = (value?: boolean) =>
    value ? (
      <CheckOutlined className={classes.check} />
    ) : (
      <CloseOutlined className={classes.close} />
    );

  return (
    <ExtendedDescriptions
      loading={isLoading}
      items={[
        {
          label: "knowledge.tradedLeveraged",
          value: showValue(data?.tradedLeveraged),
        },
        {
          label: "knowledge.tradedOptions",
          value: showValue(data?.tradedOptions),
        },
        {
          label: "knowledge.qualification",
          value: showValue(data?.qualification),
        },
        ...(app.typeId !== 1
          ? []
          : [
              {
                label: "knowledge.cfd",
                value: showValue(app.cfd),
              },
              {
                label: "knowledge.forex",
                value: showValue(app.forex),
              },
              {
                label: "knowledge.stocks",
                value: showValue(app.stocks),
              },
              {
                label: "knowledge.futures",
                value: showValue(app.futures),
              },
              {
                label: "knowledge.options",
                value: showValue(app.options),
              },
            ]),
      ]}
    />
  );
};

export default KnowledgeComponent;
