import {
  useMutation,
  DefaultError,
  useQueryClient,
} from "@tanstack/react-query";
import * as API from "aws-amplify/api";
import { operations, Types } from "./duck";
import { notification } from "antd";

const useMutationRequest = <
  TData extends string | number,
  Variables = number | string | void
>({
  apiName,
  onSuccess,
  mutationKey,
  invalidateQueries,
  path = "",
}: Types.DeleteMutationArgs<TData>) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<TData, DefaultError, Variables>({
    mutationKey,
    retry: 0,
    onSuccess: async (newData) => {
      if (invalidateQueries) {
        await Promise.all(
          invalidateQueries.map((item) =>
            queryClient.invalidateQueries({
              queryKey: item,
            })
          )
        );
      }

      notification.success({
        message: "Success",
      });

      onSuccess?.(newData);
    },
    mutationFn: async (variables) => {
      const operation = API.del({
        apiName,
        path: variables ? `${path}/${variables}` : path,
      });

      return operations.queryFnWrapper<TData>(operation);
    },
  });

  return {
    ...mutation,
    queryClient,
  } as const;
};

export default useMutationRequest;
