import { FC } from "react";
import { Country, QueryKey } from "types";
import { useMutation } from "hooks";
import { Switch } from "components";

interface CountrySwitchProps {
  updatedProp: "disabledLive" | "disabledDemo";
  queryKey: QueryKey;
  record: Country;
  checked: boolean;
  accountID: number;
}

const CountrySwitch: FC<CountrySwitchProps> = ({
  updatedProp,
  checked,
  accountID,
  queryKey,
  record,
}) => {
  // todo get by id
  const { mutate, isPending } = useMutation<
    Country,
    {
      accountID: number;
      disabledLive?: boolean;
      disabledDemo?: boolean;
    }
  >({
    apiName: "countries",
    path: `/${record.id}/settings`,
    method: "patch",
    invalidateQueries: [queryKey],
  });

  return (
    <Switch
      loading={isPending}
      checked={checked}
      onChange={() => {
        const existingProp =
          updatedProp === "disabledLive" ? "disabledDemo" : "disabledLive";

        mutate({
          accountID,
          [updatedProp]: !record[updatedProp],
          [existingProp]: record[existingProp],
        });
      }}
    />
  );
};

export default CountrySwitch;
