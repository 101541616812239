import { useState, FC } from "react";
import {
  Form,
  Card,
  Col,
  Divider,
  Image,
  Row,
  FormattedMessage,
  InputText,
  InputEmail,
  InputPassword,
  SubmitButton,
  Icons,
  Button,
  Typography,
} from "components";
import VerifyPassword from "../verifiy-password";
import { useMutation } from "hooks";
import { createUseStyles } from "react-jss";

const { Paragraph } = Typography;

const useStyles = createUseStyles({
  card: {
    width: "50%",
    maxWidth: 400,
    "& .ant-card-head-wrapper": {
      overflow: "hidden",
    },
    "& .ant-card-extra": {
      maxWidth: "50%",
    },
  },
  form: { marginTop: 20 },
  fullWidth: { width: "100%" },
  row: {
    height: "100%",
  },
  col: {
    height: "100%",
  },
  outerCard: { height: "100%", backgroundColor: "#1677ff22" },
  wrapper: {
    minHeight: 360,
    backgroundColor: "#fff",
    padding: 24,
  },
  btnRow: { marginBottom: 24 },
  flex: {
    height: "100%",
  },
});

const COL_PROPS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
};

interface SignUpProps {
  goBack: () => void;
}

const SignUp: FC<SignUpProps> = ({ goBack }) => {
  const [form] = Form.useForm();
  const [state, setState] = useState<{
    confirmSignUp: boolean;
  }>({
    confirmSignUp: false,
  });
  const classes = useStyles();
  const { mutate, isPending } = useMutation({
    method: "post",
    apiName: "user",
    path: "/sign-up",
    onSuccess: () => {
      setState((prevState) => ({
        ...prevState,
        confirmSignUp: true,
      }));
    },
  });

  if (state.confirmSignUp) {
    const { email, password } = form.getFieldsValue(["email", "password"]);

    return <VerifyPassword email={email} password={password} />;
  }

  return (
    <Row className={classes.row}>
      <Col {...COL_PROPS} className={classes.col}>
        <Card className={classes.outerCard}>
          <Typography.Title level={2}>
            Welcome to eApplicationForm
          </Typography.Title>
          <Divider />
          <Typography.Title level={5}>
            Your premier portal for the online management of your Onboard and
            KYC Verification.
          </Typography.Title>
          <Divider />
          <Paragraph>
            This platform allows you to manage your customer Onboarding Flow, by
            providing a customisable application form system for trading and
            digital currency entities.
          </Paragraph>
          <Paragraph>
            Containing Biometric verification systems with easy-to-use, high
            resolution, facial and document scanning and verify cabilities,{" "}
            <strong>eApplicationForm </strong>
            allows you to be at the forefront of compliance requirements.
          </Paragraph>
          <Divider />
          <Paragraph>
            Login now or{" "}
            <Button
              type="link"
              size="small"
              style={{ padding: 0 }}
              onClick={(e) => {
                e.preventDefault();
                setState((prevState) => ({
                  ...prevState,
                  signUp: true,
                }));
              }}
            >
              Register
            </Button>{" "}
            for a new account.
          </Paragraph>
          <Row justify="center" align="middle" className={classes.row}>
            <Image
              src="https://eaf-app-assets.s3.amazonaws.com/mainPageHeroPageImg.avif"
              height={400}
              alt="eApplicationForm"
              preview={false}
            />
          </Row>
        </Card>
      </Col>
      <Col {...COL_PROPS}>
        <Row justify="center" align="middle" className={classes.row}>
          <Card
            className={classes.card}
            title={
              <Button
                icon={<Icons.ArrowLeftOutlined />}
                type="text"
                onClick={goBack}
              />
            }
            extra={<FormattedMessage id="common.signUp" />}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                mutate(values);
              }}
            >
              <InputText required label="common.first_name" name="firstName" />
              <InputText required label="common.last_name" name="lastName" />
              <InputText
                required
                label="common.businessName"
                name="businessName"
                maxLength={512}
              />
              <InputEmail required label="common.email" name="email" />
              <InputPassword required label="common.password" name="password" />
              <SubmitButton loading={isPending} />
            </Form>
          </Card>
        </Row>
        <Divider />
      </Col>
    </Row>
  );
};

export default SignUp;
