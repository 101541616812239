export const ENDPOINTS = {
  accounts: "accounts",
  staff: "staff",
  user: "user",
  email: "email",
  clients: "clients",
  applications: "applications",
  countries: "countries",
  streetTypes: "street-types",
  settings: "settings",
  subscription: "subscription",
  verification: "verification",
  informationRequests: "information-requests",
  verifyExternal: "verify-external",
  verifyInternal: "verify-internal",
  system: "system",
} as const;
