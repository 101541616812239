import { FC } from "react";
import { NavLink, ExtendedTag, ExtendedTable } from "components";
import { useStaffList } from "hooks";
import { routes } from "configs";
import { Staff } from "types";

const List: FC = () => {
  const { staffList, staffListLoading } = useStaffList();

  // TODO Generic
  return (
    <ExtendedTable<Staff>
      loading={staffListLoading}
      dataSource={staffList}
      columns={[
        {
          title: "common.id",
          dataIndex: "id",
          sorter: (a, b) => a.id - b.id,
          defaultSortOrder: "descend",
          render: ({ value }) => (
            <NavLink to={routes.staff.view(value)}>{value}</NavLink>
          ),
        },
        {
          title: "common.account",
          dataIndex: "accountName",
          render: ({ record }) => (
            <ExtendedTag backgroundColor={record.accountColor}>
              {record.accountName}
            </ExtendedTag>
          ),
        },
        {
          title: "common.firstName",
          dataIndex: "first_name",
        },
        {
          title: "common.lastName",
          dataIndex: "last_name",
        },
        {
          title: "common.email",
          dataIndex: "email",
        },
      ]}
    />
  );
};

export default List;
