import { FC, ReactElement } from "react";
import { Form, FormItemProps, ColorPicker } from "antd";
import { FormattedMessage } from "react-intl";

interface FormColorPickerProps extends Omit<FormItemProps, "label"> {
  label: string;
  children?: ReactElement;
}

const FormColorPicker: FC<FormColorPickerProps> = ({
  children,
  label,
  ...props
}) => {
  return (
    <Form.Item label={<FormattedMessage id={label} />} {...props}>
      <ColorPicker />
    </Form.Item>
  );
};

export default FormColorPicker;
