import { useState, FC, useEffect } from "react";
import { NavLink, ExtendedTable, Button, Icons } from "components";
import { CreateNew } from "./components";
import { getQueryKey } from "utils";
import { useAccountSubscription, useQuery, useSelector } from "hooks";
import { InformationRequest, DashboardHOCChildProps } from "types";

const List: FC<DashboardHOCChildProps> = ({ setConfig }) => {
  const [drawerOpen, toggleDrawer] = useState(false);
  const accountID = useSelector((state) => state.auth.staff?.account_id);
  const queryKey = getQueryKey("informationRequests", accountID);
  const { data = [], isLoading } = useQuery<InformationRequest[]>({
    apiName: "accounts",
    path: `/${accountID}/information-requests`,
    enabled: !!accountID,
    queryKey,
  });
  const { subscriptionActive } = useAccountSubscription();

  useEffect(() => {
    if (subscriptionActive) {
      setConfig({
        extra: (
          <Button
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => toggleDrawer(true)}
          />
        ),
      });
    }
  }, [setConfig, subscriptionActive]);

  // TODO Remove generic
  return (
    <>
      <CreateNew
        accountID={accountID}
        drawerOpen={drawerOpen}
        onClose={() => toggleDrawer(false)}
        informationRequestQueryKey={queryKey}
      />
      <ExtendedTable<InformationRequest>
        loading={isLoading}
        dataSource={data}
        columns={[
          {
            sorter: (a, b) => b.id - a.id,
            dataIndex: "id",
            title: "common.id",
            defaultSortOrder: "ascend",
            render: ({ value }) => (
              <NavLink to={`/information-requests/view/${value}`}>
                {value}
              </NavLink>
            ),
          },
          {
            dataIndex: "email",
            title: "common.email",
          },
        ]}
      />
    </>
  );
};

export default List;
