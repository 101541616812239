import { createIntl, createIntlCache } from "react-intl";
import { Language } from "types";
import translations from "translations";
import flatten from "flat";

const getIntl = (language: Language) => {
  // This is optional but highly recommended
  // since it prevents memory leak
  const cache = createIntlCache();

  return createIntl(
    {
      locale: language,
      messages: flatten(translations[language]),
    },
    cache
  );
};

export default getIntl;
