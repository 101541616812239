import { useState, useEffect, FC } from "react";
import * as C from "components";
import { useSelector, useLocation } from "hooks";
import { routes } from "configs";
import { createUseStyles } from "react-jss";
import { Staff } from "types";

const useStyles = createUseStyles({
  sider: {
    height: "100%",
    "& .ant-layout-sider-children": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  menu: {
    overflow: "auto",
    height: "100%",
  },
  itemGroup: {
    textAlign: "center",
  },
});

const Menu: FC = () => {
  const classes = useStyles();
  const [state, setState] = useState<{
    selectedKeys: string[];
    openKeys: string[];
    collapsed: boolean;
  }>({
    selectedKeys: ["dashboard"],
    openKeys: [],
    collapsed: !!localStorage.getItem("collapsedMenu"),
  });
  const { pathname } = useLocation();
  const staff = useSelector((rootState) => rootState.auth.staff as Staff);

  useEffect(() => {
    const openKeys = [pathname.slice(0, pathname.lastIndexOf("/"))];

    setState((prevState) => ({
      ...prevState,
      openKeys: state.collapsed ? prevState.openKeys : openKeys,
      selectedKeys: [pathname],
    }));
  }, [pathname, state.collapsed]);

  const onCollapse = (collapsed: boolean) => {
    if (collapsed) {
      localStorage.setItem("collapsedMenu", "true");
    } else {
      localStorage.removeItem("collapsedMenu");
    }
    setState((prev) => ({ ...prev, collapsed }));
  };
  const onMenuOpenChange = (openKeys: string[]) => {
    setState((prev) => ({
      ...prev,
      openKeys: [openKeys[openKeys.length - 1]],
    }));
  };

  return (
    <C.Layout.Sider
      collapsible
      collapsed={state.collapsed}
      onCollapse={onCollapse}
      theme="light"
      className={classes.sider}
    >
      <C.Logo />
      <C.Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={["/applications/list"]}
        className={classes.menu}
        selectedKeys={state.selectedKeys}
        openKeys={state.openKeys}
        onOpenChange={onMenuOpenChange}
        items={[
          {
            key: routes.dashboard,
            title: "Dashboard",
            icon: <C.DashboardOutlined />,
            label: <C.NavLink to="/">Dashboard</C.NavLink>,
          },
          {
            key: routes.clients.main,
            title: "Clients",
            label: "Clients",
            children: [
              {
                key: routes.clients.list(),
                label: <C.NavLink to={routes.clients.list()}>List</C.NavLink>,
              },
            ],
          },
          ...(!staff.permissions.staff_view && !staff.permissions.staff_admin
            ? []
            : [
                {
                  key: "/staff",
                  title: "Staff",
                  label: "Staff",
                  children: [
                    ...(!staff.permissions.staff_view
                      ? []
                      : [
                          {
                            key: routes.staff.list(),
                            label: (
                              <C.NavLink to={routes.staff.list()}>
                                List
                              </C.NavLink>
                            ),
                          },
                        ]),
                  ],
                },
              ]),
          {
            key: routes.applications.main,
            title: "Applications",
            label: "Applications",
            children: [
              {
                key: routes.applications.list(),
                label: (
                  <C.NavLink to={routes.applications.list()}>List</C.NavLink>
                ),
              },
            ],
          },
          {
            key: routes.settings.main,
            title: "Settings",
            label: "Settings",
            children: [
              {
                key: routes.settings.account(),
                label: (
                  <C.NavLink to={routes.settings.account()}>Account</C.NavLink>
                ),
              },
              {
                key: routes.settings.countrySetup(),
                label: (
                  <C.NavLink to={routes.settings.countrySetup()}>
                    Country Setup
                  </C.NavLink>
                ),
              },
              {
                key: routes.settings.userProfile(),
                label: (
                  <C.NavLink to={routes.settings.userProfile()}>
                    <C.FormattedMessage id="settings.userProfile" />
                  </C.NavLink>
                ),
              },
            ],
          },
          {
            key: "/information-requests",
            title: "Information Requests",
            label: "Information Requests",
            children: [
              {
                key: "/information-requests/list",
                label: (
                  <C.NavLink to="/information-requests/list">List</C.NavLink>
                ),
              },
            ],
          },
          {
            key: routes.verifyExternal.main,
            title: "Verify External",
            label: "Verify External",
            children: [
              {
                key: routes.verifyExternal.list(),
                label: (
                  <C.NavLink to={routes.verifyExternal.list()}>List</C.NavLink>
                ),
              },
            ],
          },
          {
            key: routes.verifyInternal.main,
            title: "Verify Internal",
            label: "Verify Internal",
            children: [
              {
                key: routes.verifyInternal.list(),
                label: (
                  <C.NavLink to={routes.verifyInternal.list()}>List</C.NavLink>
                ),
              },
              {
                key: routes.verifyInternal.create(),
                label: (
                  <C.NavLink to={routes.verifyInternal.create()}>
                    Create
                  </C.NavLink>
                ),
              },
            ],
          },
        ]}
      />
    </C.Layout.Sider>
  );
};

export default Menu;
