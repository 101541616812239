import { FC, MutableRefObject } from "react";
import { Form, Input, InputRef, FormItemProps } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

interface InputTextProps extends FormItemProps {
  type?: "Password";
  placeholder?: string;
  label?: string;
  required?: boolean;
  isDuplicate?: boolean;
  autoFocus?: boolean;
  innerRef?: MutableRefObject<InputRef | null>;
  maxLength?: number;
}

const InputText: FC<InputTextProps> = ({
  type,
  name,
  isDuplicate,
  autoFocus,
  placeholder,
  rules = [],
  required,
  label,
  innerRef,
  maxLength,
}) => {
  const intl = useIntl();

  return (
    <Form.Item
      normalize={(value) => value.replace(/\s{2,}/g, " ")}
      label={label && <FormattedMessage id={label} />}
      name={name}
      rules={[
        {
          required,
          message: <FormattedMessage id="validation.required" />,
        },
        {
          validator() {
            return isDuplicate
              ? Promise.reject("This item already exists")
              : Promise.resolve();
          },
        },
        ...rules,
      ]}
    >
      <Input
        maxLength={maxLength}
        type={type}
        ref={innerRef}
        placeholder={placeholder && intl.formatMessage({ id: placeholder })}
        autoComplete="new-password"
        autoFocus={autoFocus}
      />
    </Form.Item>
  );
};
export default InputText;
