import { Form, Select } from "antd";
import { useIntl } from "hooks";
import { selectors } from "./duck";
import * as Types from "../types";

const { Option } = Select;

const Dropdown = <TOption,>({
  options = [],
  placeholder,
  allowClear = false,
  getOptionProps,
  style = { width: "100%" },
  innerRef,
  filterOption = selectors.filterOption,
  ...props
}: Types.InnerSelectProps<TOption>) => {
  return (
    <Select
      {...props}
      ref={innerRef}
      style={style}
      placeholder={placeholder}
      allowClear={allowClear}
      showSearch
      filterOption={filterOption}
    >
      {options.map((option, index) => {
        const { value, children, ...rest } = getOptionProps(option, index);

        return (
          <Option key={value} value={value} {...rest}>
            {children}
          </Option>
        );
      })}
    </Select>
  );
};

const InputSelect = <Option,>({
  label,
  name,
  isFormItem = true,
  preserve,
  required,
  rules = [],
  placeholder = "placeholders.select",
  innerRef,
  noStyle,
  className,
  ...props
}: Types.InputSelectProps<Option>) => {
  const intl = useIntl();

  const S = (
    <Dropdown
      {...{
        ...props,
        placeholder: selectors.getPlaceholder(placeholder, intl),
      }}
      innerRef={innerRef}
    />
  );

  if (!isFormItem) {
    return S;
  }

  return (
    <Form.Item
      label={
        label ? intl.formatMessage({ id: label, defaultMessage: "" }) : null
      }
      name={name}
      preserve={preserve}
      noStyle={noStyle}
      className={className}
      rules={[
        {
          required,
          message: intl.formatMessage({ id: "validation.required" }),
        },
        ...rules,
      ]}
    >
      {S}
    </Form.Item>
  );
};

export default InputSelect;
