import { useMemo } from "react";
import { useSelector } from "./useReactRedux";
import { useQuery } from "./tanstack";
import { getQueryKey } from "utils";
import { ApplicationsList } from "types";

const useApplicationsList = () => {
  const accountID = useSelector((state) => state.auth.staff?.account_id);
  const queryKey = useMemo(
    () => getQueryKey("applicationsList", accountID),
    [accountID]
  );

  const {
    data: applicationList = [],
    isLoading,
    isRefetching,
  } = useQuery<ApplicationsList[]>({
    apiName: "accounts",
    enabled: !!accountID,
    path: `/${accountID}/applications`,
    queryKey,
  });

  return {
    applicationList,
    isLoading,
    isRefetching,
    queryKey,
  };
};

export default useApplicationsList;
