import { IntlShape } from "react-intl";
import { OptionChildren } from "../types";

export const getPlaceholder = (intl: IntlShape, placeholder?: string) => {
  return placeholder
    ? intl.formatMessage({ id: placeholder, defaultMessage: "" })
    : placeholder;
};

export const filterOption = (
  input: string,
  option: OptionChildren | undefined
) => {
  return !!option?.children.props.children.some((opt) => {
    if (typeof opt === "string") {
      return opt.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    }

    return false;
  });
};
