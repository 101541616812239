import { FC } from "react";
import { useCountryList, useSelector, useMutation } from "hooks";
import { Form, Button, FormattedMessage, Row } from "components";
import { Info, Address } from "./components";
import { getQueryKey } from "utils";
import * as duck from "./duck";
import { Types } from "../../../shared";

interface CreateVerifyInternalProps {
  onSuccess: (record: Types.VerifyInternalRecord) => void;
}

const CreateVerifyInternal: FC<CreateVerifyInternalProps> = ({ onSuccess }) => {
  const { accountID, genders } = useSelector((state) => ({
    accountID: state.auth.staff?.account_id as number,
    genders: state.appState.genders,
  }));
  const { countryList } = useCountryList();
  const { isPending, mutate } = useMutation<
    Types.VerifyInternalRecord,
    duck.Types.FormValues
  >({
    method: "post",
    apiName: "verifyInternal",
    invalidateQueries: [getQueryKey("verifyInternalList", accountID)],
    onSuccess,
  });

  return (
    <Form
      layout="vertical"
      onFinish={async (values) => {
        const addressesEmpty = Object.values(values.addresses).every(
          (value) => !value
        );

        mutate({
          ...values,
          accountID,
          addresses: addressesEmpty ? [] : [values.addresses],
        });
      }}
    >
      <Info countryList={countryList} genders={genders} />
      <Address countryList={countryList} />

      <Row justify="end">
        <Button loading={isPending} type="primary" htmlType="submit">
          <FormattedMessage id="button.next" />
        </Button>
      </Row>
    </Form>
  );
};

export default CreateVerifyInternal;
