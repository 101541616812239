import { FC } from "react";
import { Row, Col, Typography, Empty } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import ExtendedDescriptions from "components/extended-descriptions";
import SumSub from "components/sumsub";
import PreLoader from "components/pre-loader";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "consts";
import { useQuery } from "hooks";
import { getQueryKey } from "utils";
import { Verification } from "types";

const COL_PROPS = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
};

interface VerificationProps {
  parentID: number | string;
  parentRoute: "applications" | "informationRequests" | "verifyExternal";
}

const VerificationComponent: FC<VerificationProps> = ({
  parentID,
  parentRoute,
}) => {
  const { data: record, isLoading } = useQuery<Verification>({
    apiName: parentRoute,
    path: `/${parentID}/verification`,
    queryKey: getQueryKey("verification", { parentRoute, parentID }),
  });

  if (isLoading) {
    return <PreLoader />;
  }

  if (!record) {
    return <Empty />;
  }

  return (
    <>
      {record.sumSub && <SumSub record={record.sumSub} />}
      <Row gutter={[14, 14]} align="bottom">
        <Col {...COL_PROPS}>
          <ExtendedDescriptions
            title="verifications.manualUpload"
            items={[
              {
                label: "verifications.imgFront",
                value: record.frontImgURL && (
                  <Typography.Link href={record.frontImgURL} target="_blank">
                    <EyeOutlined />
                  </Typography.Link>
                ),
              },
              {
                label: "verifications.imgBack",
                value: record.backImgURL && (
                  <Typography.Link href={record.backImgURL} target="_blank">
                    <EyeOutlined />
                  </Typography.Link>
                ),
              },
            ]}
          />
        </Col>
        <Col {...COL_PROPS}>
          <ExtendedDescriptions
            items={[
              {
                label: "date.createdAt",
                value: dayjs(record.createdAt).format(DATE_TIME_FORMAT),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default VerificationComponent;
