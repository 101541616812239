import { FC, useEffect } from "react";
import { Tabs, TabsProps } from "antd";
import { useSearchParams, useAccount } from "hooks";

interface ExtendedTabsProps
  extends Omit<
    TabsProps,
    "items" | "defaultActiveKey" | "activeKey" | "onChange"
  > {
  items: NonNullable<TabsProps["items"]>;
}

const ExtendedTabs: FC<ExtendedTabsProps> = (props) => {
  const { account } = useAccount();
  const [params, setSearchParams] = useSearchParams();

  useEffect(() => {
    // some tabs are being displayed dynamically according to a specific account
    const tab = params.get("tab");
    const { items } = props;
    const itemsTab = items.find((item) => item.key === tab)?.key;

    setSearchParams((prev) => {
      const prevParams = Array.from(prev.entries()).reduce(
        (result, [paramKey, paramValue]) => {
          result[paramKey] = paramValue;

          return result;
        },
        {} as any
      );

      return {
        ...prevParams,
        tab: itemsTab || (items.at(0)?.key as string),
      };
    });

    // eslint-disable-next-line
  }, [account?.id]);

  return (
    <Tabs
      {...props}
      activeKey={params.get("tab") as string}
      onChange={(activeTab) => {
        setSearchParams({
          tab: activeTab,
        });
      }}
    />
  );
};

export default ExtendedTabs;
