import { SelectOption, Client } from "types";

export const getValue = ({
  data,
  newData,
  value,
}: {
  data: SelectOption[];
  newData: SelectOption[];
  value: string;
}) => {
  return [...data, ...newData].find((item) => item.value === value)?.children;
};

export const groupData = (arr: Client[]) =>
  arr.reduce(
    (prev, curr) => ({
      firstName: prev.firstName.concat({
        value: curr.id,
        children: curr.first_name,
      }),
      lastName: prev.lastName.concat({
        value: curr.id,
        children: curr.last_name,
      }),
      email: prev.email.concat({
        value: curr.id,
        children: curr.email,
      }),
    }),
    {
      firstName: [] as SelectOption[],
      lastName: [] as SelectOption[],
      email: [] as SelectOption[],
    }
  );
