import { useCallback } from "react";
import { notification } from "antd";
import { useIntl } from "react-intl";
import { ShowNotification } from "types";
import _ from "lodash";

const useNotification = () => {
  const intl = useIntl();

  return useCallback<ShowNotification>(
    ({
      type,
      message,
      translateMessage = true,
      messageValues,
      description,
      descriptionValues,
    }) => {
      notification[type]({
        message: !translateMessage
          ? message
          : intl.formatMessage(
              { id: message },
              _.mapValues(messageValues, ({ value, translate: translateKey }) =>
                translateKey ? intl.formatMessage({ id: value }) : value
              )
            ),
        description:
          description &&
          intl.formatMessage(
            { id: description },
            _.mapValues(descriptionValues, (str) =>
              intl.formatMessage({ id: str })
            )
          ),
      });
    },
    [intl]
  );
};

export default useNotification;
