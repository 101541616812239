import { FC, useEffect, useState } from "react";
import { Storage, isImage } from "utils";
import { Button, Spin, Image } from "antd";
import { createUseStyles } from "react-jss";
import { FileOutlined } from "@ant-design/icons";
import { XOR, Bucket } from "types";

interface FileIDProps {
  awsFileID?: string;
  bucket: Bucket;
}

interface FileProps {
  file?: File;
  bucket: Bucket;
}

type FileRendererProps = XOR<FileIDProps, FileProps>;

const useStyles = createUseStyles({
  button: {
    padding: 0,
  },
});

const FileRenderer: FC<FileRendererProps> = ({ awsFileID, bucket, file }) => {
  const [state, setState] = useState<{
    url: string | null;
    contentType?: string;
    loading: boolean;
  }>({
    url: null,
    contentType: "",
    loading: false,
  });
  const classes = useStyles();

  useEffect(() => {
    if (!awsFileID) {
      return;
    }

    const fn = async () => {
      try {
        setState((prevState) => ({
          ...prevState,
          loading: true,
        }));

        const [{ url }, properties] = await Promise.all([
          Storage.getURL({
            bucket,
            key: awsFileID,
          }),
          Storage.getProperties({
            bucket,
            key: awsFileID,
          }),
        ]);

        setState((prevState) => ({
          ...prevState,
          loading: false,
          contentType: properties.contentType,
          url: url.href,
        }));
      } catch (e) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    };

    fn();
  }, [awsFileID, bucket]);

  if (file && isImage(file.type)) {
    return <Image height={100} width={70} src={URL.createObjectURL(file)} />;
  }

  if (state.loading) {
    return <Spin size="small" />;
  }

  if (!state.url) {
    return null;
  }

  if (isImage(state.contentType)) {
    return <Image height={100} width={70} src={state.url} />;
  }

  return (
    <Button
      className={classes.button}
      href={state.url}
      icon={<FileOutlined />}
      type="link"
      target="_blank"
    />
  );
};

export default FileRenderer;
