export { default as showNotification } from "./showNotification";
export { default as getIntl } from "./getIntl";
export { default as ReconnectingWebSocket } from "./reconnectingWebSocket";
export { default as formatToCurrency } from "./formatToCurrency";
export { default as isColorDark } from "./isColorDark";
export { default as showItem } from "./showItem";

export * from "./tanstack";

export { default as qs } from "query-string";
export * as uuid from "uuid";
export * from "./typed-aws-amplify";

export { default as isImage } from "./isImage";

export const isImageURL = (fileType?: string) =>
  ["jpg", "jpeg", "png"].some((format) => fileType?.indexOf(format) !== -1);

export const getImgDimensions = (isMobile: boolean) =>
  isMobile ? { width: 115, height: 200 } : { width: 200, height: 115 };
