import * as React from "react";
import { TableColumnType as ColumnType, Breakpoint } from "antd";
import { ENDPOINTS } from "consts/endpoints";
import { Account } from "./account";
import {
  QueryKey,
  DefaultError as DefaultTanstackError,
  InfiniteData,
} from "@tanstack/react-query";

export type { IntlShape } from "react-intl";
export type { Params } from "react-router-dom";
export type BreakpointMap = Record<Breakpoint, string | boolean>;

export * from "./account";
export * from "./queryKeys";
export * from "./billing";

export type { QueryKey, DefaultTanstackError, InfiniteData };

export type Bucket =
  | "uploads"
  | "identificationDocuments"
  | "applicantImages"
  | "applicationDocuments";

export interface DashboardHOCState {
  title: string;
  subTitle?: string;
  subTitleValues?: Record<string, any>;
  extra?: any;
}

export interface DashboardHOCChildProps {
  setConfig(args: Partial<DashboardHOCState>): void;
}

export interface InitialData {
  user: User;
  staff: Staff;
  appVersion: string;
  truliooActive: boolean;
}

export type ApiNames = keyof typeof ENDPOINTS;

export type TableColumnType<TRecord> = Omit<
  ColumnType<TRecord>,
  "title" | "dataIndex"
> & { title: string; dataIndex: keyof TRecord };

export type ShowNotification = (args: {
  type: "success" | "info" | "error" | "warning";
  message: string;
  translateMessage?: boolean;
  messageValues?: Record<string, { value: string; translate?: boolean }>;
  description?: string;
  descriptionValues?: Record<string, string>;
}) => void;

export interface ApplicationsList extends Application {
  client: string;
  clientEmail: string;
  mode: string;
  type: string;
  status: string;
  accountName: string;
  accountColor: string;
  optionalAccountColor?: string;
  optionalAccountName?: string;
  optionalAccountID?: number;
}

export enum AccountStatusEnum {
  Incomplete = 1,
  Pending = 2,
  Active = 3,
  Inactive = 4,
}

export interface VerifyExternal
  extends Pick<Personal, "email" | "firstName" | "lastName">,
    Pick<Account, "account_name"> {
  accountID: number;
  createdAt: string;
  openedAt: string;
  id: number;
  uuid: string;
}

export interface VerifyInternal
  extends Pick<Personal, "email" | "firstName" | "lastName"> {
  accountID: number;
  applicantID: string;
  account_name: Account["account_name"];
  createdAt: string;
  id: number;
  submittedAt: string | null;
}

export interface SelectOption {
  children: any;
  value: any;
  created?: boolean;
  removable?: boolean;
}

export interface ViewHOCChildProps<TRecord> {
  record: TRecord;
  queryKey: QueryKey;
}

export enum ColumnFilterInputTypes {
  DATE_RANGE = "DATE_RANGE",
  INPUT = "INPUT",
}

export interface RcFile extends File {
  uid: string;
}

export interface ApiArgs {
  apiName: ApiNames;
  path?: string;
  options?: {
    headers?: Record<string, string>;
    queryParams?: Record<string, any>;
    body?: Record<string, any>;
  };
}

/**
 * Type representing an operation that can be canceled.
 *
 * @internal
 */
export interface Operation<Response> {
  response: Promise<Response>;
  cancel(abortMessage?: string): void;
}

interface ResponsePayload {
  blob(): Promise<Blob>;
  json(): Promise<DocumentType>;
  text(): Promise<string>;
}

/**
 * Basic response type of REST API.
 *
 * @internal
 */
export interface RestApiResponse {
  body: ResponsePayload;
  statusCode: number;
  headers: Record<string, string>;
}

export interface ApplicationMessage {
  id: number;
  content: string;
  applicationID: number;
  createdBy: "EAFP" | "EAFA";
  awsFileID?: string;
  createdAt: string;
  readAt?: string;
  queueID: string;
}

export interface TranslatedRecords {
  streetTypes: TranslatedRecord[];
  genders: TranslatedRecord[];
}

export interface AccountTransaction extends WidgetIDs {
  id: number;
  sourceOfFunds: TranslatedRecord[];
  sourceOfFundsOther?: string;
  purposeOfAccount: TranslatedRecord[];
  purposeOfAccountOther?: string;
  sourceOfWealth: TranslatedRecord[];
  sourceOfWealthOther?: string;
  expectedTransactionCountId: number;
  expectedTransactionCount: string;
  natureOfTransactionsId: number;
  natureOfTransactions: string;
  businessOperatedYears: number;
  counterpartiesGoods: number;
}

export interface StepButtonsProps<TValues = any> {
  loading?: boolean;
  nextDisabled?: boolean;
  nextVisible?: boolean;
  prevDisabled?: boolean;
  currentStep: number;
  steps: string[];
  wrapperClassName?: string;
  onPrevClick: () => void;
  onNextClick?: (values?: Partial<TValues>) => void;
}

export interface Taxation {
  id: number;
  countryJurisdiction: number | null;
  country: string;
  tin: string | null;
  noTinReasonID: number | null;
  noTinReason?: string;
  furtherDetails: string | null;
}

export interface ExtendedDescriptionItem {
  label: string;
  translate?: boolean;
  value: React.ReactNode;
}

export interface SumSubRequiredDocsStatus {
  reviewResult: {
    moderationComment?: string;
    reviewAnswer?: string;
    reviewRejectType?: string;
  };
  country: string; // iso 3
  idDocType: string;
  imageIds: number[];
  imageReviewResults: Record<
    string,
    {
      // imgId: {...}
      moderationComment?: string;
      clientComment?: string;
      reviewAnswer: string;
      rejectLabels?: string[];
      reviewRejectType?: string;
      buttonIds?: string[];
    }
  >;
  forbidden: boolean;
  partialCompletion: null;
  stepStatuses: null | string[];
  imageStatuses: string[];
}

export type SumSubReviewStatus = "completed" | "init" | "pending";

export interface SumSubReview {
  reviewId: string;
  attemptId: string;
  attemptCnt?: number;
  elapsedSincePendingMs?: number;
  elapsedSinceQueuedMs?: number;
  reprocessing?: boolean;
  levelName: string;
  createDate: string; // ISO string
  reviewDate?: string; // ISO string
  reviewResult?: {
    reviewAnswer: string;
    rejectLabels: string[];
    reviewRejectType: string;
    buttonIds: string[];
  };
  reviewStatus: SumSubReviewStatus;
  priority: number;
  moderatorNames: string[] | null;
}

export interface SumSubApplicant {
  id: string;
  createdAt: string;
  key: string;
  clientId: string;
  inspectionId: string;
  externalUserId: string;
  lang: string;
  type: string;
  requiredIdDocsStatus: {
    SELFIE?: SumSubRequiredDocsStatus;
    IDENTITY?: SumSubRequiredDocsStatus;
  };
  info?: {
    firstName: string;
    firstNameEn: string;
    lastName: string;
    lastNameEn: string;
    dob: string; // Date of birth (format YYYY-mm-dd, e.g. 2001-09-25).
    country: string; // Alpha-3 country code (e.g. DEU or GBR).
    idDocs?: {
      idDocType: string;
      country: string; // Alpha-3 country code (e.g. DEU or GBR).
      firstName: string;
      firstNameEn: string;
      lastName: string;
      lastNameEn: string;
      validUntil?: string; // Date of birth (format YYYY-mm-dd, e.g. 2001-09-25).
      number: string;
      dob: string; // Date of birth (format YYYY-mm-dd, e.g. 2001-09-25).
      ocrDocTypes: string[] | null; // the docs doesn't describe whether this is an array of strings or a string
      imageFieldsInfo: string[] | null; // the docs doesn't describe whether this is an array of strings or a string
    }[];
  };
  applicantPlatform: string;
  ipCountry: string; // Alpha-3 country code (e.g. DEU or GBR).
  agreement: {
    createdAt: string; // YYYY-MM-DD HH:mm:ss
    source: string;
    targets: string[];
  };
  requiredIdDocs: {
    docSets: {
      idDocSetType: string;
      types: string[];
      videoRequired: string;
    }[];
  };
  review?: SumSubReview;
}

export interface Verification {
  id: number;
  countryId: number;
  uuid: string;
  createdAt: string;
  truliooResultJson?: string;
  frontImgID?: string;
  frontImgURL?: string;
  backImgID?: string;
  backImgURL?: string;
  truliooSessionID: string;
  sumSub?: SumSubApplicant;
}

export interface Knowledge {
  id: number;
  applicationID?: number;
  optionalID?: number;
  qualification: boolean;
  tradedLeveraged: boolean;
  tradedOptions: boolean;
  cfd?: boolean;
  forex?: boolean;
  stocks?: boolean;
  futures?: boolean;
  options?: boolean;
}

export type Language = "en" | "zh";

export interface Country {
  id: number;
  label: string;
  full_name: string;
  iso_alpha_2: string;
  iso_alpha_3: string;
  un_code: string;
  deleted: boolean;
  priority: number;
  restrictedCountry: boolean;
  accountId: number;
  disabledLive: number;
  disabledDemo: number;
}

export interface User {
  id: number;
  aws_cognito_id: string;
  passwordExpired?: boolean;
  email: string;
  firstName: string;
  lastName: string;
  lastActiveStaffID: number;
  passwordExpiry: string;
}

export interface ExpiredUserPayload {
  email: string | null;
}

export interface StaffPermission {
  account_admin: boolean;
  id: number;
  staff_admin: boolean;
  staff_view: boolean;
}

export interface Staff {
  id: number;
  aws_cognito_id: string;
  accountName: string;
  accountColor: string;
  email: string;
  file_by: string;
  account_id: number;
  lastUsedAccountId: number;
  status_id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  tel_work: string;
  tel_home: string;
  tel_mobile: string;
  email_secondary: string;
  permissions: StaffPermission;
}

export interface Client {
  account_id: number;
  aws_cognito_id: string | null;
  clientTypeId: number | null;
  companyName: string | null;
  date_of_birth: string | null;
  email: string;
  email_secondary: string | null;
  file_by: string | null;
  first_name: string;
  id: number;
  last_name: string;
  lastUsedAccountId: number | null;
  middle_name: string | null;
  place_of_birth: string | null;
  placeOfBirthCity: string | null;
  placeOfBirthCountryId: number | null;
  postal_address_country: number | null;
  postal_address_line_1: string | null;
  postal_address_line_2: string | null;
  postal_address_postcode: string | null;
  postal_address_state: string | null;
  postal_address_suburb: string | null;
  preferredLanguage: string;
  status_id: number | null;
  street_address_country: number | null;
  street_address_line_1: string | null;
  street_address_line_2: string | null;
  street_address_postcode: string | null;
  street_address_state: string | null;
  street_address_suburb: string | null;
  streetCountryId: number | null;
  streetState: string | null;
  streetStreetName: string | null;
  streetStreetNumber: string | null;
  streetStreetTypeId: number | null;
  streetSuburb: string | null;
  streetUnitNumber: string | null;
  tel_home: string | null;
  tel_mobile: string | null;
  tel_work: string | null;
}

interface WidgetIDs {
  applicationID?: number;
  informationRequestID?: number;
}

export interface TranslatedRecord {
  id: number;
  label: string;
}

export interface StreetAddress extends WidgetIDs {
  id: number;
  streetTypeId: number;
  streetType: string;
  countryId: number;
  country: string;
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  suburb: string;
  state: string;
  postcode: string;
}

export interface Personal extends WidgetIDs {
  id: number;
  firstName?: string;
  gender?: string;
  lastName?: string;
  genderID?: number;
  email?: string;
  middleName?: string;
  placeOfBirthCity?: string;
  placeOfBirthCountryId?: number;
  placeOfBirthCountry?: string;
  dateOfBirth?: string;
}

export interface EnhancedDueDiligence extends WidgetIDs {
  id: number;
  description: string;
  awsFileID: string;
  contentType: string;
  url: string;
  createdAt: string;
}

export interface Financial extends WidgetIDs {
  id: number;
  employmentStatusID: number;
  employmentStatus: string;
  occupation?: string;
  employer?: string;
  employmentIndustry?: string;
  annualIncome: number;
  netWorth: number;
  intendedDeposit: number;
  sicDivision?: string;
  sicGroup?: string;
  usCitizen: boolean | number;
  usTaxResident: boolean | number;
}

export interface ProofOfAddress extends WidgetIDs {
  id: number;
  clientId: number;
  categoryId: number;
  category: string;
  countryId: number;
  country: string;
  url: string;
  documentNumber: string;
  awsFileID: string;
  contentType: string;
  other: string;
  createdAt: string;
}

export enum ApplicationMode {
  demo = 1,
  live = 2,
}

export interface Application {
  id: number;
  clientId: number;
  accountTypeId: number;
  accountTransactionsID: number | null;
  accountId: number;
  modeId: ApplicationMode.demo | ApplicationMode.live;
  statusId: number;
  typeId: 1 | 2 | 3;
  tradedLeveraged: boolean;
  tradedOptions: boolean;
  qualification: boolean;
  createdAt: string;
  submittedAt?: string;
}

export interface IndividualApplication extends Application {
  cfd: boolean;
  forex: boolean;
  stocks: boolean;
  futures: boolean;
  options: boolean;
  quizPassed: boolean;
}

export interface CompanyApplication extends Application {
  companyName: string;
  companyNumber: string;
  incorporationCountryID?: number;
  incorporationDate?: string;
}

export interface TrustApplication extends Application {
  trusteeTypeId: number;
  trusteeType: string;
  trustName: string;
  trusteeFirstName: string;
  trusteeLastName: string;
  trusteeCompanyName: string;
  trusteeCompanyNumber: string;
}

export interface Compliance {
  id: number;
  applicationID?: number;
  informationRequestID?: number;
  publicOfficeOrPep: boolean;
  familyPublicOfficeOrPep: boolean;
  amlOrCft: boolean;
  adverseInformation: boolean;
  refusedBanking: boolean;
}

export interface UserGeo {
  IPv4: string;
  city: string;
  country_code: string;
  country_name: string;
  latitude: number;
  longitude: number;
  postal: string;
  state: string;
}

export interface POACategory {
  id: number;
  file_by: string;
}

interface CommonHolderFields {
  id: number;
  applications_type2Id: number;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  dateOfBirth: string;
  streetUnitNumber?: string;
  streetStreetNumber?: string;
  streetStreetName?: string;
  streetStreetTypeId?: number;
  streetSuburb: string;
  streetPostcode: string;
  streetCountryId: number;
  streetState: string;
}

export interface Officeholder extends CommonHolderFields {
  officeHolderTypeId: number;
}

export interface Shareholder extends CommonHolderFields {
  shareHolderTypeId: number;
  beneficiallyHeld: boolean;
}

export interface Beneficiary extends CommonHolderFields {
  beneficiaryTypeId: number;
  beneficiallyHeld: boolean;
}

export interface AmplifyError {
  response: {
    data: null | {
      error: string;
    };
  };
}

export interface InformationRequest {
  id: number;
  uuid: string;
  active: boolean;
  pincode: string;
  email: string;
  firstName: string;
  lastName: string;
  s3FileId: string;
  dateOfBirth: string;
  placeOfBirthCity: string;
  placeofBirthCountryId: number;
}

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// eslint-disable-next-line
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;
