import { FC } from "react";
import { ExtendedDescriptions, Row, InputSelect } from "components";
import { useQuery } from "hooks";
import { AccountTransaction } from "types";
import { getQueryKey } from "utils";

interface AccountTransactionsProps {
  appID: number;
}

const AccountTransactions: FC<AccountTransactionsProps> = ({ appID }) => {
  const { data, isLoading } = useQuery<
    AccountTransaction & {
      sourceOfFundsIDs: number[];
      sourceOfWealthIDs: number[];
      purposeOfAccountIDs: number[];
    }
  >({
    apiName: "applications",
    path: `/${appID}/account-transactions`,
    queryKey: getQueryKey("accountTransactions", { application: appID }),
  });

  return (
    <>
      <Row gutter={[14, 14]} style={{ marginBottom: 14 }}>
        <ExtendedDescriptions
          loading={isLoading}
          items={[
            {
              label: "accountTransactions.sourceOfFunds",
              value: !!data?.sourceOfFundsIDs.length && (
                <InputSelect
                  isFormItem={false}
                  open={false}
                  mode="multiple"
                  value={data?.sourceOfFundsIDs}
                  options={data?.sourceOfFunds}
                  getOptionProps={(opt) => ({
                    value: opt.id,
                    children: opt.label,
                  })}
                />
              ),
            },
            {
              label: "accountTransactions.sourceOfWealth",
              value: !!data?.sourceOfWealthIDs.length && (
                <InputSelect
                  isFormItem={false}
                  open={false}
                  mode="multiple"
                  value={data?.sourceOfWealthIDs}
                  options={data?.sourceOfWealth}
                  getOptionProps={(opt) => ({
                    value: opt.id,
                    children: opt.label,
                  })}
                />
              ),
            },
            {
              label: "accountTransactions.purposeOfAccount",
              value: !!data?.purposeOfAccountIDs.length && (
                <InputSelect
                  isFormItem={false}
                  open={false}
                  mode="multiple"
                  value={data?.purposeOfAccountIDs}
                  options={data?.purposeOfAccount}
                  getOptionProps={(opt) => ({
                    value: opt.id,
                    children: opt.label,
                  })}
                />
              ),
            },
          ]}
        />
        <ExtendedDescriptions
          loading={isLoading}
          items={[
            {
              label: "accountTransactions.expectedAccountActivity",
              value: data?.expectedTransactionCount,
            },
            {
              label: "accountTransactions.natureOfAccountTransactions",
              value: data?.natureOfTransactions,
            },
            {
              label: "accountTransactions.businessOperatedYears",
              value: data?.businessOperatedYears,
            },
            {
              label: "accountTransactions.counterpartiesGoods",
              value: data?.counterpartiesGoods,
            },
          ]}
        />
      </Row>
      <Row gutter={[14, 14]}>
        <ExtendedDescriptions
          title="common.other"
          loading={isLoading}
          items={[
            {
              label: "accountTransactions.sourceOfFunds",
              value: data?.sourceOfFundsOther,
            },
            {
              label: "accountTransactions.sourceOfWealth",
              value: data?.sourceOfWealthOther,
            },
            {
              label: "accountTransactions.purposeOfAccount",
              value: data?.purposeOfAccountOther,
            },
          ]}
        />
      </Row>
    </>
  );
};

export default AccountTransactions;
