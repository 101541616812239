import { ListenerEffectAPI } from "@reduxjs/toolkit";
import { Auth, showNotification } from "utils";
import { authActions, authAsyncActions } from "../slices";
import { operations } from "./duck";

export const authEffect = async (listenerApi: ListenerEffectAPI<any, any>) => {
  let expiredPasswordError:
    | {
        email: string | null;
      }
    | undefined;

  try {
    const { userSub, tokens } = await Auth.fetchAuthSession();

    if (!userSub) {
      listenerApi.dispatch(authAsyncActions.authError());
      return;
    }

    expiredPasswordError = {
      email: tokens?.idToken?.payload.email as string | null,
    };

    const data = await operations.getInitialData(userSub);

    listenerApi.dispatch(authActions.authenticateSuccess(data));
  } catch (e: any) {
    if (e.message.indexOf("expired") !== -1) {
      // do not log out cognito user from the browser.
      // we need to retrieve userSub in frontend/src/layout/components/login/components/reset-password-modal/ResetPasswordModal.tsx

      const state = listenerApi.getState() as any;
      showNotification({
        type: "error",
        language: state.appState.language,
        message: "password.expired",
      });

      listenerApi.dispatch(authAsyncActions.authError(expiredPasswordError));
    } else {
      listenerApi.dispatch(authAsyncActions.authError());
    }
  }
};
