import * as React from "react";
import * as ANTD from "antd";
import { PlusOutlined } from "@ant-design/icons";
import InputText from "../../../../input-text";
import * as Types from "../../types";

const CreatableMenu: React.FC<Types.CreatableProps> = ({
  onCreateSuccess,
  inputRef,
  label,
  validate,
  placeholder,
}) => {
  const [form] = ANTD.Form.useForm();
  const [state, setState] = React.useState<Types.CreatableState>({
    isDuplicate: false,
    loading: false,
  });

  const onSubmit = () => {
    const newValue = form.getFieldValue(label);

    Promise.all([
      new Promise((resolve, reject) => {
        const invalid = validate?.({
          newValue,
          setState,
        });

        if (!invalid) {
          resolve(true);
        } else {
          reject();
        }
      }),
      form.validateFields(),
    ]).then(() => {
      onCreateSuccess?.(newValue, () => {
        form.resetFields();
      });
    });
  };

  return (
    <>
      <ANTD.Divider style={{ margin: "8px 0" }} />
      <ANTD.Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onKeyDown={(e) => {
          e.stopPropagation();

          if (e.code === "Enter") {
            form.submit();
          }
        }}
        onValuesChange={() => {
          if (state.isDuplicate) {
            setState((prev) => ({ ...prev, isDuplicate: false }));
          }
        }}
      >
        <ANTD.Space align="center" style={{ padding: "0 8px 4px" }}>
          <InputText
            autoFocus
            required
            label={label}
            name={label}
            innerRef={inputRef}
            isDuplicate={state.isDuplicate}
            placeholder={placeholder}
          />
          <ANTD.Button
            loading={state.loading}
            type="text"
            htmlType="submit"
            icon={<PlusOutlined />}
          />
        </ANTD.Space>
      </ANTD.Form>
    </>
  );
};

export default CreatableMenu;
