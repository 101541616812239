import { FC, useState } from "react";
import * as C from "components";
import { useMutation, useQuery } from "hooks";
import { Client, InformationRequest, SelectOption } from "types";
import { getQueryKey, uuid } from "utils";
import { selectors, Types } from "./duck";

const CreateNew: FC<Types.CreateNewProps> = ({
  drawerOpen,
  accountID,
  informationRequestQueryKey,
  onClose,
}) => {
  const [form] = C.Form.useForm();
  const [state, setState] = useState<Types.ResultData>({
    firstName: [],
    lastName: [],
    email: [],
  });
  const { data, isLoading } = useQuery<Client[], Types.ResultData>({
    apiName: "accounts",
    enabled: !!accountID,
    path: `/${accountID}/clients`,
    queryKey: getQueryKey("clients", accountID),
    select: selectors.groupData,
  });
  const { isPending, mutate } = useMutation<
    InformationRequest,
    {
      firstName: string;
      lastName: string;
      email: string;
      pincode: string;
      accountId: number;
    }
  >({
    apiName: "informationRequests",
    method: "post",
    invalidateQueries: [informationRequestQueryKey],
    onSuccess: () => {
      onClose();
    },
  });

  const getOptionProps = (option: SelectOption) => {
    return {
      value: option.value,
      children: option.children,
    };
  };

  const onCreate = (key: keyof Types.ResultData) => (newValue: string) => {
    const newItem = {
      value: uuid.v1(),
      children: newValue,
      created: true,
    };

    form.setFieldValue(key, newItem.value);

    setState((prev) => ({
      ...prev,
      [key]: [...prev[key], newItem],
    }));
  };
  return (
    <C.Drawer
      open={drawerOpen}
      onClose={onClose}
      destroyOnClose
      extra={
        <C.Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          <C.FormattedMessage id="button.submit" />
        </C.Button>
      }
    >
      <C.Form<Types.FormValues>
        form={form}
        preserve={false}
        layout="vertical"
        onFinish={(values) => {
          mutate({
            accountId: accountID as number,
            firstName: selectors.getValue({
              data: data?.firstName as SelectOption[],
              newData: state.firstName,
              value: values.firstName,
            }),
            lastName: selectors.getValue({
              data: data?.lastName as SelectOption[],
              newData: state.lastName,
              value: values.lastName,
            }),
            email: selectors.getValue({
              data: data?.email as SelectOption[],
              newData: state.email,
              value: values.email,
            }),
            pincode: values.pincode,
          });
        }}
      >
        <C.CreatableSelect
          required
          name="firstName"
          label="common.firstName"
          loading={isLoading}
          options={[...(data?.firstName || []), ...state.firstName]}
          getOptionProps={getOptionProps}
          onCreateSuccess={onCreate("firstName")}
        />

        <C.CreatableSelect
          required
          name="lastName"
          label="common.lastName"
          loading={isLoading}
          options={[...(data?.lastName || []), ...state.lastName]}
          getOptionProps={getOptionProps}
          onCreateSuccess={onCreate("lastName")}
        />

        <C.CreatableSelect
          required
          name="email"
          label="common.email"
          loading={isLoading}
          options={[...(data?.email || []), ...state.email]}
          getOptionProps={getOptionProps}
          onCreateSuccess={onCreate("email")}
        />

        <C.InputText
          required
          name="pincode"
          label="informationRequests.pin"
          rules={[
            {
              validator(rule, value) {
                return value.length > 6
                  ? Promise.reject('Can"t be more than 6 characters')
                  : Promise.resolve();
              },
            },
          ]}
        />
      </C.Form>
    </C.Drawer>
  );
};

export default CreateNew;
