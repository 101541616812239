import { FC } from "react";
import {
  Form,
  Row,
  Col,
  InputEmail,
  PhoneInput,
  SubmitButton,
  InputText,
  InputSelect,
} from "components";
import { TabProps } from "../../types";
import { useCountryList, useSelector } from "hooks";

const Contact: FC<TabProps> = ({ staff, submitting, submit }) => {
  const streetTypes = useSelector((state) => state.appState.streetTypes);

  const { countryList } = useCountryList();

  return (
    <Form
      layout="vertical"
      name="contact"
      initialValues={staff}
      onFinish={submit}
    >
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Row gutter={[12, 12]}>
            <Col>
              <PhoneInput name="tel_home" label="common.telHome" />
            </Col>
            <Col>
              <PhoneInput name="tel_work" label="common.telWork" />
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col>
              <PhoneInput name="tel_mobile" label="common.telMobile" />
            </Col>
            <Col>
              <InputEmail
                name="email_secondary"
                label="common.emailSecondary"
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <InputText name="addressSuburb" label="common.suburb" />
            </Col>
            <Col span={8}>
              <InputText name="addressState" label="common.state" />
            </Col>
            <Col span={8}>
              <InputText name="addressPostCode" label="common.postcode" />
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <InputSelect
                name="addressCountryId"
                label="common.country"
                options={countryList}
                getOptionProps={(option: any) => ({
                  children: option.full_name,
                  value: option.id,
                })}
              />
            </Col>
            <Col span={8}>
              <InputSelect
                name="addressStreetTypeId"
                label="streetAddress.streetType"
                options={streetTypes}
                getOptionProps={(option) => ({
                  children: option.label,
                  value: option.id,
                })}
              />
            </Col>
            <Col span={8}>
              <InputText
                name="addressStreetName"
                label="streetAddress.streetName"
              />
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <InputText
                name="addressStreetNumber"
                label="streetAddress.streetNumber"
              />
            </Col>
            <Col span={8}>
              <InputText
                name="addressUnitNumber"
                label="streetAddress.unitNumber"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <SubmitButton loading={submitting} />
    </Form>
  );
};

export default Contact;
