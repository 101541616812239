import { FC } from "react";
import { ExtendedTable, Image, Typography } from "components";
import { useQuery } from "hooks";
import { getQueryKey } from "utils";
import { ProofOfAddress } from "types";

interface TableProps {
  appID: number;
}

const Table: FC<TableProps> = ({ appID }) => {
  const { data = [], isLoading } = useQuery<ProofOfAddress[]>({
    apiName: "applications",
    path: `/${appID}/poa`,
    queryKey: getQueryKey("proofOfAddress", { application: appID }),
  });

  return (
    <ExtendedTable<ProofOfAddress>
      loading={isLoading}
      dataSource={data}
      columns={[
        {
          title: "common.country",
          dataIndex: "country",
        },
        {
          title: "poa.documentDescription",
          dataIndex: "category",
        },
        {
          title: "button.preview",
          dataIndex: "url",
          render: ({ value, record }) => {
            if (value) {
              const imgFormat = ["jpg", "jpeg", "png"];
              const isIMG = imgFormat.find((format) =>
                record.contentType.includes(format)
              );

              return isIMG ? (
                <Image src={value} width={50} height={50} />
              ) : (
                <Typography.Link href={value} target="_blank">
                  View
                </Typography.Link>
              );
            }
          },
        },
      ]}
    />
  );
};

export default Table;
