import { FC } from "react";
import { Descriptions, Row, Col, ExtendedTag } from "components";
import { ViewProps } from "../../types";
import { constants } from "../../duck";

const Summary: FC<ViewProps> = ({ record }) => {
  return (
    <Row gutter={[14, 14]}>
      <Col {...constants.COL_PROPS}>
        <Descriptions bordered layout="vertical">
          <Descriptions.Item label="Client">{record.client}</Descriptions.Item>
          <Descriptions.Item label="email">
            {record.clientEmail}
          </Descriptions.Item>
          <Descriptions.Item label="Account">
            <ExtendedTag backgroundColor={record.accountColor}>
              {record.accountName}
            </ExtendedTag>
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col {...constants.COL_PROPS}>
        <Descriptions bordered layout="vertical">
          <Descriptions.Item label="Mode">{record.mode}</Descriptions.Item>
          <Descriptions.Item label="Type">{record.type}</Descriptions.Item>
          <Descriptions.Item label="Status">{record?.status}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default Summary;
