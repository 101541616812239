import { ReactNode } from "react";
import { Empty, Skeleton as AntSkeleton } from "components";
import { useParams, useQueryParams, useSelector, useQuery } from "hooks";
import { ApiNames, Params, QueryKey } from "types";

type Param = "id" | "uuid";

type Fn<ReturnType, TParam extends Param> = (args: {
  params: Params<TParam>;
  queryParams: Record<string, string>;
  accountID: number;
}) => ReturnType;

const viewHOC =
  <TParam extends Param, TRecord = any>({
    Component,
    pathFn,
    queryKeyFn,
    apiName,
    skeleton = <AntSkeleton active loading />,
  }: {
    Component: any;
    queryKeyFn: Fn<QueryKey, TParam>;
    pathFn?: Fn<string, TParam>;
    apiName: ApiNames;
    skeleton?: ReactNode;
  }) =>
  (props: any) => {
    const params = useParams<Param>();
    const accountID = useSelector(
      (root) => root.auth.staff?.account_id as number
    );
    const [queryParams] = useQueryParams();
    const queryKey = queryKeyFn({ params, queryParams, accountID });
    const { data, isLoading } = useQuery<TRecord>({
      apiName,
      queryKey,
      path: pathFn?.({ params, accountID, queryParams }),
      options: {
        queryParams,
      },
    });

    if (isLoading) {
      return skeleton as any;
    }

    if (!data && !isLoading) {
      return <Empty />;
    }

    return <Component {...props} record={data} queryKey={queryKey} />;
  };

export default viewHOC;
