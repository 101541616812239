import { FC } from "react";
import { Skeleton, Flex } from "antd";

interface SkeletonStepsProps {
  steps: string[];
}

// TODO replace skeleton styles with className
const SkeletonSteps: FC<SkeletonStepsProps> = ({ steps }) => {
  return (
    <Flex justify="space-between">
      {steps.map((step) => (
        <Flex key={step} vertical align="center">
          <Skeleton.Avatar
            active
            size="small"
            style={{ height: 20, marginBottom: 24 }}
          />
          <Skeleton.Input active size="small" style={{ height: 14 }} />
        </Flex>
      ))}
    </Flex>
  );
};

export default SkeletonSteps;
