import { FC } from "react";
import { Spin, SpinProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const PreLoader: FC<SpinProps> = ({ size = "large", ...props }) => {
  return (
    <Spin
      indicator={<LoadingOutlined />}
      size={size}
      spinning
      {...props}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  );
};

export default PreLoader;
