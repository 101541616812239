import {
  TypedUseSelectorHook,
  useDispatch as useNativeDispatch,
  useSelector as useNativeSelector,
} from "react-redux";
import type { RootState, AppDispatch } from "configs";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => useNativeDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useNativeSelector;
