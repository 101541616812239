import {
  IDENTIFICATION_DOCS_BUCKET,
  UPLOADS_BUCKET,
  APPLICATIONS_DOCS_BUCKET,
  APPLICANT_IMAGES_BUCKET,
} from "consts";
import { Amplify } from "aws-amplify";
import * as Storage from "aws-amplify/storage";
import { Bucket } from "types";
import { IStorage } from "./types";

const BUCKET_NAMES: Record<Bucket, string> = {
  identificationDocuments: IDENTIFICATION_DOCS_BUCKET,
  uploads: UPLOADS_BUCKET,
  applicationDocuments: APPLICATIONS_DOCS_BUCKET,
  applicantImages: APPLICANT_IMAGES_BUCKET,
} as const;

const typedStorage: IStorage = {
  configure: (bucket) => {
    const currentConfig = Amplify.getConfig();

    Amplify.configure({
      ...currentConfig,
      Storage: {
        ...currentConfig.Storage,
        S3: {
          region: currentConfig.Storage?.S3.region,
          bucket: BUCKET_NAMES[bucket],
        },
      },
    });
  },
  getURL({ bucket, ...config }) {
    this.configure(bucket);

    return Storage.getUrl(config);
  },
  getProperties({ bucket, ...config }) {
    this.configure(bucket);

    return Storage.getProperties(config);
  },
  downloadData({ bucket, ...config }) {
    this.configure(bucket);

    return Storage.downloadData(config);
  },
  uploadData({ bucket, ...config }) {
    this.configure(bucket);

    return Storage.uploadData({
      ...config,
      options: {
        ...config.options,
        contentType: config.data.type,
      },
    });
  },
  del({ bucket, ...config }) {
    this.configure(bucket);

    return Storage.remove(config);
  },
};

export default typedStorage;
