import { FC } from "react";
import { ExtendedTable } from "components";
import { useQuery } from "hooks";
import { Taxation } from "types";
import { getQueryKey } from "utils";

interface TaxationProps {
  appID: number;
}

const REASON_SUFFIX = {
  1: "A",
  2: "B",
  3: "C",
};

export const getReasonSuffix = (id?: number) => {
  return id && REASON_SUFFIX[id as 1 | 2 | 3];
};

const TaxationComponent: FC<TaxationProps> = ({ appID }) => {
  const { data = [], isLoading } = useQuery<Taxation[]>({
    apiName: "applications",
    path: `/${appID}/taxation`,
    queryKey: getQueryKey("taxation", { application: appID }),
  });

  return (
    <ExtendedTable<Taxation>
      size="small"
      dataSource={data}
      loading={isLoading}
      columns={[
        {
          dataIndex: "country",
          title: "taxation.country",
        },
        {
          dataIndex: "tin",
          title: "taxation.tin",
          sorter: (a, b) => ((a.tin || "") > (b.tin || "") ? -1 : 1),
        },
        {
          dataIndex: "noTinReasonID",
          title: "taxation.noTinReason",
          render: ({ value }) => getReasonSuffix(value),
        },
        {
          dataIndex: "furtherDetails",
          title: "taxation.furtherDetails",
        },
      ]}
    />
  );
};

export default TaxationComponent;
