import { FC } from "react";
import * as LC from "./components";
import { Alert } from "components";
import { Subscription, AccountStatusEnum } from "types";
import { Types } from "../../duck";

interface CurrentSubscriptionProps extends Types.AccountSettingsProps {
  id: number;
  accountID: number;
}

const CurrentSubscription: FC<CurrentSubscriptionProps> = ({
  id,
  accountID,
  subscriptionActive,
  subscription,
  subscriptionQueryKey,
  subscriptionLoading,
}) => {
  // client secret is being extracted from payment intent or setup intent (payg)
  // and won't be extracted after a user provide payment data
  if (subscription && subscriptionActive && !subscription.clientSecret) {
    return (
      <LC.ActiveSubscription
        subscription={subscription as Subscription}
        subscriptionQueryKey={subscriptionQueryKey}
      />
    );
  }

  return (
    <LC.CreateSubscription
      billingID={id}
      accountID={accountID}
      subscriptionLoading={subscriptionLoading}
      subscription={subscription as Subscription}
      subscriptionQueryKey={subscriptionQueryKey}
    />
  );
};

const CurrentSubscriptionWrapper: FC<
  CurrentSubscriptionProps & { statusID: number }
> = ({ statusID, ...props }) => {
  if (statusID !== AccountStatusEnum.Active) {
    return (
      <Alert
        type="info"
        message="Subscriptions disabled until your account is activated."
      />
    );
  }

  return <CurrentSubscription {...props} />;
};

export default CurrentSubscriptionWrapper;
