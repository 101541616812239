import { FC, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Form, SubmitButton, notification, Row, Tag } from "components";
import { routes } from "configs";
import { BILLING_PLAN_COLOR, ACCOUNT_SETTINGS_TABS } from "consts";
import { BillingPlan } from "types";
import { formatToCurrency } from "utils";

interface ConfirmSubscriptionProps {
  billingPlan: BillingPlan;
  type: "setup" | "intent";
}

const ConfirmSubscription: FC<ConfirmSubscriptionProps> = ({
  type,
  billingPlan,
}) => {
  const [loading, toggleLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  return (
    <Form
      layout="vertical"
      onFinish={async () => {
        if (!stripe || !elements) {
          notification.error({
            message: "Payment system error",
          });
          return;
        }

        toggleLoading(true);

        try {
          // Trigger form validation and wallet collection
          const { error: submitError } = await elements.submit();

          if (submitError) {
            notification.error({
              message: submitError.message,
            });
          }
          const confirmIntent =
            type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

          const { error } = await confirmIntent({
            elements,
            confirmParams: {
              return_url: `${window.location.origin}${routes.settings.account(
                ACCOUNT_SETTINGS_TABS.CURRENT_SUBSCRIPTION
              )}`,
            },
          });

          if (error) {
            notification.error({
              message: error.message,
            });
          }
        } catch (e) {
          notification.error({
            message: e.message,
          });
        }

        toggleLoading(false);
      }}
    >
      <Row>
        Billing plan:&nbsp;
        <Tag color={BILLING_PLAN_COLOR[billingPlan.id]}>
          {billingPlan.name} {formatToCurrency(billingPlan.defaultPrice)}
        </Tag>
      </Row>
      <br />
      <PaymentElement />

      <SubmitButton loading={loading} disabled={!stripe || !elements} />
    </Form>
  );
};

export default ConfirmSubscription;
