import { FC, useState } from "react";
import { ExtendedSteps } from "components";
import { Types, Step2, Step3 } from "../../../shared";
import { useQueryClient } from "hooks";
import { QueryKey } from "types";

interface IncompleteFlowProps {
  record: Types.VerifyInternalRecord;
  viewRecordQueryKey: QueryKey;
}

const IncompleteFlow: FC<IncompleteFlowProps> = ({
  record,
  viewRecordQueryKey,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const queryClient = useQueryClient();

  const steps = ["verifyInternal.documentUpload", "button.submit"];

  const stepComponents = [
    <Step2
      record={record}
      queryKey={viewRecordQueryKey}
      onSuccess={(updatedRecord) => {
        queryClient.setQueryData<Types.VerifyInternalRecord>(
          viewRecordQueryKey,
          updatedRecord
        );

        setCurrentStep(currentStep + 1);
      }}
    />,
    <Step3
      onPrevClick={() => setCurrentStep(currentStep - 1)}
      record={record}
    />,
  ];

  return (
    <>
      <ExtendedSteps steps={steps} currentStep={currentStep} />
      {stepComponents[currentStep]}
    </>
  );
};

export default IncompleteFlow;
