export const getQueryParams = (params: Record<string, string>) => {
  const { periodStart, periodEnd, id, status, canceledAt } = params;

  const queryParams: any = {};

  if (id) {
    queryParams.id = id;
  }

  if (periodStart) {
    queryParams.periodStart = periodStart;
  }

  if (periodEnd) {
    queryParams.periodEnd = periodEnd;
  }

  if (status) {
    queryParams.status = status;
  }

  if (canceledAt) {
    queryParams.canceledAt = canceledAt;
  }

  return queryParams;
};
