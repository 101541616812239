import { FC } from "react";
import { Country } from "types";
import InputSelect from "../basic";
import { InputSelectProps } from "../types";
import { useCountryList } from "hooks";

const CountriesSelect: FC<InputSelectProps<Country>> = ({
  label = "common.country",
  name,
  ...props
}) => {
  const { countryListLoading, countryList } = useCountryList();

  return (
    <InputSelect
      {...props}
      label={label}
      placeholder="placeholders.select"
      name={name}
      options={countryList}
      loading={countryListLoading}
    />
  );
};

export default CountriesSelect;
