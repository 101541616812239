import {
  Dashboard,
  clients,
  applications,
  staff,
  settings,
  verifyExternal,
  verifyInternal,
  informationRequests,
} from "features";
import { dashboardHOC } from "hocs";
import { routes } from "configs";
import { StaffPermission } from "types";

export const getRoutes = ({ permissions }: { permissions?: StaffPermission }) =>
  [
    {
      path: routes.dashboard,
      Component: dashboardHOC({
        title: "dashboard_page.page_description",
        Component: Dashboard,
      }),
    },
    {
      path: routes.clients.list(),
      Component: dashboardHOC({
        title: "clients.list",
        Component: clients.List,
      }),
    },
    {
      path: routes.clients.details(),
      Component: dashboardHOC<any>({
        title: "clients.details",
        Component: clients.View,
      }),
    },
    {
      path: routes.staff.list(),
      visible: permissions?.staff_view,
      Component: dashboardHOC({
        title: "common.list",
        Component: staff.List,
      }),
    },
    {
      path: routes.staff.view(),
      visible: permissions?.staff_view,
      Component: dashboardHOC<any>({
        title: "common.details",
        Component: staff.View,
      }),
    },
    {
      path: routes.applications.list(),
      Component: dashboardHOC<any>({
        title: "applications.title",
        Component: applications.List,
      }),
    },
    {
      path: "/applications/:id",
      Component: dashboardHOC<any>({
        title: "applications.view.title",
        subTitle: "applications.id",
        Component: applications.View,
      }),
    },
    {
      path: "/information-requests/list",
      Component: dashboardHOC<any>({
        title: "informationRequests.title",
        Component: informationRequests.List,
      }),
    },
    {
      path: "/information-requests/view/:id",
      Component: dashboardHOC<any>({
        title: "informationRequests.details",
        Component: informationRequests.View,
      }),
    },
    {
      path: routes.settings.countrySetup(),
      Component: dashboardHOC<any>({
        title: "settings.countrySetup",
        Component: settings.CountrySetup,
      }),
    },
    {
      path: routes.settings.userProfile(),
      Component: dashboardHOC<any>({
        title: "settings.userProfile",
        Component: settings.UserProfile,
      }),
    },
    {
      path: routes.verifyExternal.list(),
      Component: dashboardHOC<any>({
        title: "verifyExternal.title",
        Component: verifyExternal.List,
      }),
    },
    {
      path: routes.verifyExternal.view(),
      Component: dashboardHOC<any>({
        title: "verifyExternal.details",
        Component: verifyExternal.View,
      }),
    },
    {
      path: routes.verifyInternal.list(),
      Component: dashboardHOC<any>({
        title: "verifyInternal.title",
        Component: verifyInternal.List,
      }),
    },
    {
      path: routes.verifyInternal.view(),
      Component: dashboardHOC<any>({
        title: "verifyInternal.details",
        Component: verifyInternal.View,
      }),
    },
    {
      path: routes.verifyInternal.create(),
      Component: dashboardHOC<any>({
        title: "button.create",
        Component: verifyInternal.Create,
      }),
    },
  ].map(({ visible, ...item }) => ({
    ...item,
    visible: typeof visible !== "undefined" ? visible : true,
  }));
