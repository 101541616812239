import * as React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Upload, notification, UploadProps, UploadFile } from "antd";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import { ACCEPTED_FILES } from "consts";
import { RcFile } from "types";
import { isImageURL } from "utils";

const { Dragger } = Upload;

interface FileUploadProps extends UploadProps {
  onRemove?: UploadProps["onRemove"];
  onUploadSuccess?: (file: RcFile) => void;
  required?: boolean;
  multiple?: boolean;
  preserve?: boolean;
  name?: string;
  label?: string | React.ReactNode;
  getValueProps?: (v: any) => { value: any };
  maxCount?: number;
  formItemClassName?: string;
}

const useStyles = createUseStyles({
  dragger: {
    "& .ant-upload-drag .ant-upload-btn": {
      padding: 0,
    },
  },
});

const addThumbURL = (file: UploadFile) => {
  // new Chrome update prevents opening files via base64
  file.thumbUrl = URL.createObjectURL(file.originFileObj as File);
  return file;
};

const FileUpload: React.FC<FileUploadProps> = ({
  onRemove,
  required = false,
  multiple = false,
  preserve,
  name,
  label,
  getValueProps,
  maxCount = 1,
  children,
  formItemClassName,
  className,
  onUploadSuccess,
  ...rest
}) => {
  const classes = useStyles();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e.map(addThumbURL);
    }
    return e && e.fileList.map(addThumbURL);
  };

  const dragger = (
    <Dragger
      {...rest}
      className={classes.dragger}
      multiple={multiple}
      accept={ACCEPTED_FILES}
      onRemove={onRemove}
      maxCount={maxCount}
      customRequest={({ onSuccess, file }) => {
        onSuccess?.(file);

        onUploadSuccess?.(file as RcFile);
      }}
      isImageUrl={(file) => isImageURL(file.type)}
      beforeUpload={(file) => {
        const format = file.type.split("/")[1];

        if (file.size / 1024 / 1024 > 5) {
          notification.error({
            message: <FormattedMessage id="messages.bigFile" />,
          });

          return Upload.LIST_IGNORE;
        }

        if (!/(pdf|doc|docx|jpg|jpeg|png|msword)$/g.test(format)) {
          notification.error({
            type: "error",
            message: (
              <FormattedMessage
                id="messages.invalidFileFormat"
                values={{
                  format,
                  allowedFormats: ACCEPTED_FILES,
                }}
              />
            ),
          });

          return Upload.LIST_IGNORE;
        }
      }}
    >
      <UploadOutlined className="ant-upload-drag-icon" />
    </Dragger>
  );

  if (!name) {
    return dragger;
  }

  return (
    <Form.Item
      name={name}
      label={label}
      preserve={preserve}
      className={formItemClassName}
      getValueProps={getValueProps}
      getValueFromEvent={normFile}
      valuePropName="fileList"
      rules={[
        {
          required,
          message: <FormattedMessage id="validation.required" />,
        },
      ]}
    >
      {dragger}
    </Form.Item>
  );
};

export default FileUpload;
