import { FC, useEffect } from "react";
import { ExtendedTable, Tag } from "components";
import {
  useInfiniteQuery,
  useQuery,
  useQueryParams,
  useQueryClient,
} from "hooks";
import { getQueryKey, API } from "utils";
import { DATE_TIME_FORMAT } from "consts";
import dayjs from "dayjs";
import _ from "lodash";
import { selectors } from "./duck";
import {
  DefaultTanstackError,
  QueryKey,
  InfiniteData,
  ColumnFilterInputTypes,
  Subscription,
} from "types";

interface SubscriptionsProps {
  accountID: number;
}

const LIMIT = 20;

const Subscriptions: FC<SubscriptionsProps> = ({ accountID }) => {
  const [params] = useQueryParams();
  const queryClient = useQueryClient();

  const queryParams = selectors.getQueryParams(params);

  const searchedQueryKey = getQueryKey("subscriptionsList", {
    accountID,
    ...queryParams,
  });

  const queryEnabled = !_.isEmpty(queryParams);

  const { data: searchedData, isLoading: searching } = useQuery<Subscription[]>(
    {
      apiName: "accounts",
      path: `/${accountID}/subscription/search`,
      options: {
        queryParams: queryEnabled ? queryParams : undefined,
      },
      enabled: queryEnabled,
      queryKey: searchedQueryKey,
    }
  );

  const paginationEnabled = !queryEnabled;
  const {
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    data: paginatedData,
  } = useInfiniteQuery<
    Subscription[],
    DefaultTanstackError,
    InfiniteData<Subscription[]>,
    QueryKey,
    number
  >({
    initialPageParam: 1,
    enabled: paginationEnabled,
    queryKey: getQueryKey("subscriptionsList", accountID),
    queryFn: ({ pageParam }) => {
      return API.get({
        apiName: "accounts",
        path: `/${accountID}/subscription/search`,
        options: {
          queryParams: {
            offset: pageParam,
          },
        },
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length >= LIMIT) {
        return allPages.reduce((prev, curr) => prev + curr.length, 1);
      }

      return null;
    },
    // currently we don't need this. We don't support URL query params to preserve previously selected state
    // getPreviousPageParam: (firstPage) => firstPage.data.at(0)?.id,
  });

  useEffect(() => {
    if (!queryEnabled) {
      queryClient.setQueryData(searchedQueryKey, null);
    }

    // eslint-disable-next-line
  }, [queryEnabled]);

  const dataSource =
    searchedData ||
    paginatedData?.pages?.reduce(
      (prev, curr) => prev.concat(curr),
      [] as Subscription[]
    );

  return (
    <ExtendedTable<Subscription>
      pagination={{
        pageSize: hasNextPage ? LIMIT - 1 : LIMIT,
        onChange: () => {
          if (paginationEnabled) {
            fetchNextPage();
          }
        },
      }}
      loading={
        isFetchingNextPage || isFetchingPreviousPage || isLoading || searching
      }
      dataSource={dataSource}
      columns={[
        {
          title: "common.id",
          dataIndex: "id",
          filterAsync: true,
        },
        {
          title: "date.startedAt",
          dataIndex: "periodStart",
          filterAsync: true,
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
        },
        {
          title: "date.endedAt",
          dataIndex: "periodEnd",
          filterAsync: true,
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
        },
        {
          title: "date.canceledAt",
          dataIndex: "canceledAt",
          filterAsync: true,
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => value && dayjs(value).format(DATE_TIME_FORMAT),
        },
        {
          title: "common.status",
          dataIndex: "status",
          filterAsync: true,
          render: ({ value }) => (
            <Tag color={value === "active" ? "green" : "grey"}>{value}</Tag>
          ),
        },
      ]}
    />
  );
};

export default Subscriptions;
