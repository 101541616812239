import { FC } from "react";
import { ExtendedDescriptions } from "components";
import { DATE_FORMAT } from "consts";
import { PersonalData } from "../../types";
import dayjs from "dayjs";

const PersonalDetails: FC<PersonalData> = (props) => {
  return (
    <ExtendedDescriptions
      items={[
        {
          label: "common.first_name",
          value: props.firstName,
        },
        {
          label: "common.last_name",
          value: props.lastName,
        },
        {
          label: "common.email",
          value: props.email,
        },
        {
          label: "common.dateOfBirth",
          value:
            props.dateOfBirth && dayjs(props.dateOfBirth).format(DATE_FORMAT),
        },
        {
          label: "common.placeOfBirthCity",
          value: props.placeOfBirthCity,
        },
        {
          label: "common.placeOfBirthCountry",
          value: props.placeOfBirthCountry,
        },
        {
          label: "gender.title",
          value: props.gender,
        },
      ]}
    />
  );
};

export default PersonalDetails;
