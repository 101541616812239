import { Amplify } from "aws-amplify";
import { ENDPOINTS } from "consts";
import { Language } from "types";

const {
  REACT_APP_REGION,
  REACT_APP_BUCKET,
  REACT_APP_API_URL,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_CLIENT_ID,
  REACT_APP_IDENTITY_POOL_ID,
} = process.env as Record<string, string>;

export const configureAmplify = (language: Language) => {
  Amplify.configure(
    {
      Auth: {
        Cognito: {
          identityPoolId: REACT_APP_IDENTITY_POOL_ID,
          userPoolId: REACT_APP_USER_POOL_ID,
          userPoolClientId: REACT_APP_USER_POOL_CLIENT_ID,
        },
      },
      Storage: {
        S3: {
          region: REACT_APP_REGION,
          bucket: REACT_APP_BUCKET,
        },
      },
      API: {
        REST: Object.entries(ENDPOINTS).reduce((prev, [name, route]) => {
          prev[name] = {
            region: REACT_APP_REGION,
            endpoint: `${REACT_APP_API_URL}/${route}`,
          };

          return prev;
        }, {} as Record<string, any>),
      },
    },
    {
      API: {
        REST: {
          headers: () =>
            Promise.resolve({
              "app-language": language,
            }),
        },
      },
    }
  );
};
