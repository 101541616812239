import { QueryKeys, QueryKey } from "types";
import _ from "lodash";

export const getQueryKey = (key: keyof QueryKeys, ...ids: QueryKey) => {
  return [
    key,
    ...ids.map((item) =>
      _.isObject(item) || _.isArray(item) ? item : item?.toString()
    ),
  ];
};
