import { FC } from "react";
import { useCountryList } from "hooks";
import { ExtendedDescriptions } from "components";
import { Personal } from "types";

const Summary: FC<{ record: Personal | null }> = ({ record }) => {
  const { countryList } = useCountryList();

  return (
    <>
      <ExtendedDescriptions
        items={[
          {
            label: "common.first_name",
            value: record?.firstName,
          },
          {
            label: "common.last_name",
            value: record?.lastName,
          },
          {
            label: "common.email",
            value: record?.email,
          },
        ]}
      />
      <ExtendedDescriptions
        items={[
          {
            label: "common.dateOfBirth",
            value: record?.dateOfBirth,
          },
          {
            label: "common.placeOfBirthCity",
            value: record?.placeOfBirthCity,
          },
          {
            label: "common.placeOfBirthCountry",
            value: countryList.find(
              (country) => country.id === record?.placeOfBirthCountryId
            )?.label,
          },
        ]}
      />
    </>
  );
};

export default Summary;
