import { FC } from "react";
import { ExtendedDescriptions, CheckOutlined, CloseOutlined } from "components";
import { useQuery } from "hooks";
import { Financial } from "types";
import { getQueryKey } from "utils";

interface FinancialProps {
  appID: number;
}

const FinancialComponent: FC<FinancialProps> = ({ appID }) => {
  const { data, isLoading } = useQuery<Financial>({
    apiName: "applications",
    path: `/${appID}/financial`,
    queryKey: getQueryKey("financial", { application: appID }),
  });

  return (
    <ExtendedDescriptions
      loading={isLoading}
      items={[
        {
          label: "financial.employmentStatus",
          value: data?.employmentStatus,
        },
        {
          label: "financial.employmentIndustry",
          value: data?.employmentIndustry,
        },
        {
          label: "financial.sicDivision",
          value: data?.sicDivision,
        },
        {
          label: "financial.sicGroup",
          value: data?.sicGroup,
        },
        {
          label: "financial.occupation",
          value: data?.occupation,
        },
        {
          label: "financial.annualIncome",
          value: data?.annualIncome,
        },
        {
          label: "financial.netWorth",
          value: data?.netWorth,
        },
        {
          label: "financial.intendedDeposit",
          value: data?.intendedDeposit,
        },
        {
          label: "financial.employer",
          value: data?.employer,
        },
        {
          label: "financial.usCitizen",
          value: data?.usCitizen ? <CheckOutlined /> : <CloseOutlined />,
        },
        {
          label: "financial.usTaxResident",
          value: data?.usTaxResident ? <CheckOutlined /> : <CloseOutlined />,
        },
      ]}
    />
  );
};

export default FinancialComponent;
