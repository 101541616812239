import { FC } from "react";
import { ExtendedDescriptions } from "components";
import { CompanyApplication } from "types";
import { useCountryList } from "hooks";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "consts";

const Company: FC<{ app: CompanyApplication }> = ({ app }) => {
  const { countryList } = useCountryList();

  return (
    <ExtendedDescriptions
      items={[
        {
          label: "common.companyName",
          value: app.companyName,
        },
        {
          label: "common.companyNumber",
          value: app.companyNumber,
        },
        {
          label: "applications.incorporationCountryID",
          value: countryList.find(
            (country) => country.id === app.incorporationCountryID
          )?.label,
        },
        {
          label: "applications.incorporationDate",
          value:
            app.incorporationDate &&
            dayjs(app.incorporationDate).format(DATE_TIME_FORMAT),
        },
      ]}
    />
  );
};

export default Company;
