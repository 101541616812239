import { FC } from "react";
import { ExtendedTable } from "components";
import { CountrySwitch } from "./components";
import { useSelector, useCountryList } from "hooks";
import { Country } from "types";

const CountrySetup: FC = () => {
  const { accountID } = useSelector((state) => ({
    accountID: state.auth.staff?.account_id as number,
  }));
  const { countryList, queryKey, countryListLoading } = useCountryList();

  return (
    <ExtendedTable<Country>
      dataSource={countryList}
      loading={countryListLoading}
      columns={[
        {
          title: "common.id",
          dataIndex: "id",
          sorter: (a, b) => (a.id < b.id ? -1 : 1),
        },
        {
          translate: false,
          title: "ISO3",
          dataIndex: "iso_alpha_3",
        },
        {
          title: "common.country",
          dataIndex: "full_name",
        },
        {
          title: "settings.restrictedCountry",
          dataIndex: "restrictedCountry",
          render: ({ value }) => (value ? "Yes" : "No"),
        },
        {
          title: "settings.disabledLive",
          dataIndex: "disabledLive",
          render: ({ value, record }) => (
            <CountrySwitch
              accountID={accountID}
              checked={value}
              queryKey={queryKey}
              record={record}
              updatedProp="disabledLive"
            />
          ),
        },
        {
          title: "settings.disabledDemo",
          dataIndex: "disabledDemo",
          render: ({ value, record }) => (
            <CountrySwitch
              accountID={accountID}
              checked={value}
              queryKey={queryKey}
              record={record}
              updatedProp="disabledDemo"
            />
          ),
        },
      ]}
    />
  );
};

export default CountrySetup;
