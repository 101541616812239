import { FC } from "react";
import { Form, FormItemProps, Checkbox } from "antd";
import { FormattedMessage } from "react-intl";

interface InputCheckboxProps extends FormItemProps {
  label?: string;
  labelPlacement?: "formItem" | "children";
}

const InputCheckbox: FC<InputCheckboxProps> = ({
  name,
  required,
  label,
  labelPlacement = "children",
  ...props
}) => {
  const translatedLabel = label && <FormattedMessage id={label} />;

  return (
    <Form.Item
      {...props}
      name={name}
      label={labelPlacement === "formItem" ? translatedLabel : undefined}
      valuePropName="checked"
      rules={[
        {
          required,
          validator: (rule, value) => {
            // for some reason antd doesn't see this for checkbox
            if (!required) {
              return Promise.resolve();
            }

            if (value === undefined || value === null) {
              return Promise.reject(
                <FormattedMessage id="validation.required" />
              );
            }

            return Promise.resolve();
          },
        },
      ]}
    >
      <Checkbox>{labelPlacement === "children" && translatedLabel}</Checkbox>
    </Form.Item>
  );
};

export default InputCheckbox;
