import { useEffect, useLayoutEffect } from "react";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector, useAccount } from "hooks";
import {
  configureAmplify,
  appStateAsyncActions,
  appStateActions,
  authActions,
} from "configs";
import flatten from "flat";
import Layout from "./layout";
import translations from "./translations";
import { BrowserRouter, PreLoader, Grid, ConfigProvider } from "components";

const language = "en";

const App = () => {
  const breakpoint = Grid.useBreakpoint();
  const dispatch = useDispatch();

  const { authenticating, user } = useSelector((state) => ({
    authenticating: state.auth.authenticating,
    user: state.auth.user,
  }));

  const authenticated = !!user;

  useLayoutEffect(() => {
    configureAmplify(language);
  }, []);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    dispatch(appStateAsyncActions.getTranslatedData(language));
  }, [dispatch, authenticated]);

  useEffect(() => {
    dispatch(authActions.authenticateStart());
    dispatch(appStateAsyncActions.getUserGeo());
  }, [dispatch]);

  useEffect(() => {
    dispatch(appStateActions.setBreakpoints(breakpoint));
  }, [breakpoint, dispatch]);

  const { account, accountLoading } = useAccount();

  return (
    <IntlProvider locale={language} messages={flatten(translations[language])}>
      <ConfigProvider
        theme={
          !account?.color
            ? undefined
            : {
                token: {
                  colorPrimary: account.color,
                },
              }
        }
      >
        <BrowserRouter>
          {authenticating || accountLoading ? (
            <PreLoader />
          ) : (
            <Layout account={account} />
          )}
        </BrowserRouter>
      </ConfigProvider>
    </IntlProvider>
  );
};

export default App;
