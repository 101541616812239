import { StripeAddressElementValue } from "./billing";
import { ACCOUNT_SETTINGS_TABS } from "consts";

export enum AccountModeEnum {
  live = 1,
  test = 2,
}

export type AccountSettingsTab = keyof typeof ACCOUNT_SETTINGS_TABS;

export interface AccountSettings {
  accountID: number;
  knowledge: boolean;
  edd: boolean;
  compliance: boolean;
  taxation: boolean;
  financial: boolean;
}

export interface Account {
  id: number;
  billingID?: number;
  modeID: 1 | 2;
  address?: StripeAddressElementValue["value"]["address"];
  stripeName?: string;
  stripeEmail?: string;
  stripePhone?: string;
  status: string;
  statusID: number;
  disabled: boolean;
  file_by: string;
  color: string;
  optionalAccountID?: number;
  admin_user_id: number;
  account_name: string;
  favicon: string;
  page_header_logo: string;
  product_name: string;
  website: string;
  desktop_app_name: string;
  mobile_app_name: string;
  desktop_windows_app_name: string;
  desktop_windows_app_link: string;
  desktop_mac_app_name: string;
  desktop_mac_app_link: string;
  ios_app_name: string;
  ios_app_link: string;
  android_app_name: string;
  android_app_link: string;
  web_app_name: string;
  web_app_link: string;
  sender_email: string;
  customer_service_email: string;
  telephone: string;
  afsl: string;
  badge_color: string;
  applicationSettings: AccountSettings;
}
