import { ExtendedTable, NavLink, ExtendedTag } from "components";
import { useApplicationsList } from "hooks";
import {
  ApplicationsList,
  ApplicationMode,
  ColumnFilterInputTypes,
} from "types";
import { routes } from "configs";
import { DATE_TIME_FORMAT } from "consts";
import dayjs from "dayjs";
import _ from "lodash";

const List = () => {
  const { applicationList, isLoading, isRefetching } = useApplicationsList();

  const statusFilters = _.uniqBy<ApplicationsList>(
    applicationList,
    "statusId"
  ).map((app) => {
    return {
      text: app.status,
      value: app.statusId,
    };
  });

  return (
    <ExtendedTable<ApplicationsList>
      loading={isLoading || isRefetching}
      dataSource={applicationList}
      columns={[
        {
          title: "common.id",
          dataIndex: "id",
          sorter: (a, b) => a.id - b.id,
          defaultSortOrder: "descend",
          filterInputType: ColumnFilterInputTypes.INPUT,
          render: ({ record }) =>
            record.modeId === ApplicationMode.live ? (
              <NavLink to={routes.applications.view(record.id)}>
                {record.id}
              </NavLink>
            ) : (
              record.id
            ),
        },
        {
          title: "common.client",
          dataIndex: "client",
        },
        {
          title: "common.email",
          dataIndex: "clientEmail",
        },
        {
          title: "common.account",
          dataIndex: "accountId",
          onFilter: (value, record) => record.accountId.toString() === value,
          filters: _.uniqBy(applicationList, "accountId").map((item) => ({
            value: item.accountId,
            text: item.accountName,
          })),
          render: ({ record }) => (
            <ExtendedTag backgroundColor={record.accountColor}>
              {record.accountName}
            </ExtendedTag>
          ),
        },
        {
          title: "common.optional",
          dataIndex: "optionalAccountID",
          onFilter: (value, record) =>
            record.optionalAccountID?.toString() === value.toString(),
          filters: _.uniqBy(
            applicationList?.filter((item) => item.optionalAccountName),
            "optionalAccountName"
          ).map((item) => ({
            value: item.optionalAccountID as number,
            text: item.optionalAccountName,
          })),
          render: ({ record }) =>
            record.optionalAccountName &&
            record.optionalAccountColor && (
              <ExtendedTag backgroundColor={record.optionalAccountColor}>
                {record.optionalAccountName}
              </ExtendedTag>
            ),
        },
        {
          title: "common.mode",
          dataIndex: "mode",
          onFilter: (value, record) => record.modeId === value,
          filters: [
            {
              text: "Demo Account",
              value: 1,
            },
            {
              text: "Live Account",
              value: 2,
            },
          ],
        },
        {
          title: "common.type",
          dataIndex: "type",
          onFilter: (value, record) => record.typeId === value,
          filters: [
            {
              text: "Individual",
              value: 1,
            },
            {
              text: "Company (AU)",
              value: 2,
            },
          ],
        },
        {
          title: "common.status",
          dataIndex: "status",
          filters: statusFilters,
          defaultFilteredValue: ["1", "2"],
          onFilter: (value, record) => {
            if (typeof value === "string") {
              return record.statusId.toString() === value;
            }

            return record.statusId === value;
          },
        },
        {
          title: "common.created",
          dataIndex: "createdAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
        },
        {
          title: "common.submitted",
          dataIndex: "submittedAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => value && dayjs(value).format(DATE_TIME_FORMAT),
        },
      ]}
    />
  );
};

export default List;
