import { FC } from "react";
import {
  Row,
  Layout,
  Button,
  LogoutOutlined,
  Typography,
  Dropdown,
  UserOutlined,
  DashboardOutlined,
  UserSwitchOutlined,
  TeamOutlined,
  ExtendedTag,
  NavLink,
} from "components";
import { createUseStyles } from "react-jss";
import { authAsyncActions, routes } from "configs";
import { useSelector, useDispatch, useStaffList, useAccount } from "hooks";
import { Staff } from "types";

const useStyles = createUseStyles({
  staffSelect: {
    width: "auto",
  },
  header: {
    padding: "12px 24px",
    height: "auto",
    margin: 0,
    background: "#fff",
    borderBottom: "1px solid #f0f0f0",
  },
  logo: { width: 200 },
  account: { margin: "0 10px" },
});

const Header: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userID, loading, loggingOut, staff } = useSelector((state) => ({
    userID: state.auth.user?.id as number,
    staff: state.auth.staff as Staff,
    loading: state.auth.loading,
    loggingOut: state.auth.loggingOut,
  }));
  const { account } = useAccount();
  const { staffList, staffListLoading } = useStaffList();

  const handleLogout = () => {
    dispatch(authAsyncActions.logOut());
  };

  return (
    <Layout.Header title="Admin Portal" className={classes.header}>
      <Row justify="end" align="middle">
        {account && (
          <Typography.Text className={classes.account}>
            <ExtendedTag backgroundColor={account.color}>
              {account.account_name}
            </ExtendedTag>
          </Typography.Text>
        )}
        <Dropdown.Button
          loading={loading || staffListLoading}
          placement="bottomRight"
          icon={<UserOutlined />}
          className={classes.staffSelect}
          menu={{
            items: [
              {
                key: "dashboard",
                icon: <DashboardOutlined />,
                label: <NavLink to={routes.dashboard}>Dashboard</NavLink>,
              },
              {
                onClick: ({ key }) => {
                  dispatch(
                    authAsyncActions.switchStaff({
                      id: userID,
                      staffID: +key,
                    })
                  );
                },
                key: "subMenu",
                label: "Switch Staff Member",
                icon: <UserSwitchOutlined />,
                disabled: !staffList.length,
                children: staffList.map(
                  ({
                    first_name,
                    last_name,
                    accountName,
                    accountColor,
                    id,
                  }) => ({
                    key: id,
                    label: (
                      <>
                        <ExtendedTag backgroundColor={accountColor}>
                          {accountName}
                        </ExtendedTag>
                        {first_name} {last_name}
                      </>
                    ),
                  })
                ),
              },
              {
                key: "currentStaff",
                icon: <TeamOutlined />,
                label: (
                  <NavLink to={routes.staff.view(staff.id)}>
                    Current Staff
                  </NavLink>
                ),
              },
              {
                key: "userProfile",
                icon: <UserOutlined />,
                label: (
                  <NavLink to={routes.settings.userProfile()}>
                    Go to profile
                  </NavLink>
                ),
              },
            ],
          }}
        >
          {staff.first_name} {staff.last_name}
        </Dropdown.Button>
        <Button
          type="primary"
          loading={loggingOut}
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        />
      </Row>
    </Layout.Header>
  );
};

export default Header;
