import { FC, useState } from "react";
import { Button } from "antd";
import { EyeOutlined, ExportOutlined } from "@ant-design/icons";
import { useQuery } from "hooks";
import { getQueryKey } from "utils";

const ShowImage: FC<{ inspectionId: string; imgId: number }> = ({
  inspectionId,
  imgId,
}) => {
  const [enabled, enableQuery] = useState(false);

  const { data: signedURL, isFetching } = useQuery<string>({
    apiName: "verification",
    enabled,
    path: `/${inspectionId}/images/${imgId}`,
    queryKey: getQueryKey("applicantImage", inspectionId, imgId),
  });

  if (signedURL) {
    return (
      <Button
        href={signedURL}
        type="link"
        target="_blank"
        icon={<ExportOutlined />}
      />
    );
  }

  return (
    <Button
      type="link"
      icon={<EyeOutlined />}
      loading={isFetching}
      onClick={async () => enableQuery(true)}
    />
  );
};

export default ShowImage;
