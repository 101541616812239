import { CommonArgs } from "./types";
import { ACCOUNT_SETTINGS_TABS } from "consts";

export default {
  dashboard: "/dashboard",
  login: "/login",

  viewInformationRequest: (id: number) => `/information-requests/view/${id}`,

  applications: {
    main: "/applications",
    list() {
      return `${this.main}/list`;
    },
    view(id?: number, tab?: "messaging") {
      if (id) {
        return `${this.main}/${id}${tab ? `?tab=${tab}` : ""}`;
      }

      return `${this.main}/:id`;
    },
  },

  clients: {
    main: "/clients",
    details(id?: number) {
      return `${this.main}/${id || ":id"}`;
    },
    list() {
      return `${this.main}/list`;
    },
  },

  // staff
  staff: {
    main: "/staff",
    view(id?: number | string) {
      return `${this.main}/${id || ":id"}`;
    },
    list() {
      return `${this.main}/list`;
    },
  },
  verifyExternal: {
    main: "/verify-external",
    list() {
      return `${this.main}/list`;
    },
    view(uuid?: string) {
      return `${this.main}/${uuid || ":uuid"}`;
    },
  },
  // {accountID}/${route}/{id}/applicant`
  verifyInternal: {
    main: "/verify-internal",
    list() {
      return `${this.main}/list`;
    },
    view(arg?: CommonArgs & { applicantID: string; accountID: number }) {
      let queryParam = "";

      if (arg) {
        queryParam = `?applicantID=${arg.applicantID}&accountID=${arg.accountID}`;
      }

      return `${this.main}/${arg?.id || ":id"}${queryParam}`;
    },
    create() {
      return `${this.main}/create`;
    },
  },

  // settings
  settings: {
    main: "/settings",
    account(tab?: keyof typeof ACCOUNT_SETTINGS_TABS) {
      const searchParam = tab ? `?tab=${tab}` : "";

      return `${this.main}/account${searchParam}`;
    },
    countrySetup() {
      return `${this.main}/country-setup`;
    },
    userProfile() {
      return `${this.main}/user-profile`;
    },
  },
};
