import { FC } from "react";
import { viewHOC } from "hocs";
import { getQueryKey } from "utils";
import { ViewProps } from "./types";
import { Step3 } from "../shared";
import { IncompleteFlow } from "./components";

const View: FC<ViewProps> = ({ record, queryKey }) => {
  if (!record.applicant.requiredIdDocsStatus?.IDENTITY?.imageIds?.length) {
    return <IncompleteFlow record={record} viewRecordQueryKey={queryKey} />;
  }

  return <Step3 record={record} />;
};

export default viewHOC<"id">({
  Component: View,
  apiName: "verifyInternal",
  queryKeyFn: ({ queryParams }) =>
    getQueryKey("applicantData", queryParams.applicantID),
  pathFn: ({ params }) => `/${params.id}`,
});
