import { InitialData } from "types";
import { API } from "utils";

export const getInitialData = async (
  cognitoID: string
): Promise<InitialData> => {
  const user = await API.get({
    apiName: "user",
    path: `/${cognitoID}`,
  });

  const staff = await API.get({
    apiName: "staff",
    path: `/${user.lastActiveStaffID}`,
  });

  const { appVersion, truliooActive } = await API.get({ apiName: "system" });

  return {
    appVersion,
    truliooActive,
    staff,
    user,
  };
};
