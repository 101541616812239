import { useState, FC, useCallback } from "react";
import { Divider, Card, Typography } from "antd";
import { DashboardHOCState } from "types";
import { FormattedMessage } from "react-intl";

interface DashboardHOC<P> {
  Component: FC<P>;
  title: string;
  subTitle?: string;
}

export const dashboardHOC =
  <P,>({ Component, title, subTitle }: DashboardHOC<P>) =>
  (props: P) => {
    const [state, setState] = useState<DashboardHOCState>({
      title,
      subTitle,
      subTitleValues: undefined,
      extra: [],
    });

    const setConfig = useCallback((newValues: Partial<DashboardHOCState>) => {
      setState((prevState) => ({
        ...prevState,
        ...newValues,
      }));
    }, []);

    return (
      <Card title={<FormattedMessage id={state.title} />} extra={state.extra}>
        {state.subTitle && (
          <>
            <Typography.Title level={5}>
              <FormattedMessage
                id={state.subTitle}
                values={state.subTitleValues}
              />
            </Typography.Title>
            <Divider />
          </>
        )}
        <Component {...props} setConfig={setConfig} />
      </Card>
    );
  };
