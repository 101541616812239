import { FC } from "react";
import { ExtendedDescriptions } from "components";
import { useQuery } from "hooks";
import { getQueryKey } from "utils";
import { StreetAddress } from "types";

interface ContactProps {
  appID: number;
  telHome?: string;
  telWork?: string;
  telMobile?: string;
}

const Contact: FC<ContactProps> = ({ appID, telWork, telHome, telMobile }) => {
  const { data: streetAddress, isLoading } = useQuery<StreetAddress>({
    apiName: "applications",
    path: `/${appID}/street-address`,
    queryKey: getQueryKey("streetAddress", { application: appID }),
  });

  return (
    <>
      <ExtendedDescriptions
        title="telephone.title"
        items={[
          {
            label: "telephone.home",
            value: telHome,
          },
          {
            label: "telephone.work",
            value: telWork,
          },
          {
            label: "telephone.mobile",
            value: telMobile,
          },
        ]}
      />

      <ExtendedDescriptions
        title="streetAddress.title"
        loading={isLoading}
        items={[
          {
            label: "streetAddress.streetName",
            value: streetAddress?.streetName,
          },
          {
            label: "streetAddress.streetNumber",
            value: streetAddress?.streetNumber,
          },
          {
            label: "streetAddress.suburb",
            value: streetAddress?.suburb,
          },
          {
            label: "streetAddress.state",
            value: streetAddress?.state,
          },
          {
            label: "streetAddress.postcode",
            value: streetAddress?.postcode,
          },
          {
            label: "common.country",
            value: streetAddress?.country,
          },
          {
            label: "streetAddress.streetType",
            value: streetAddress?.streetType,
          },
        ]}
      />
    </>
  );
};

export default Contact;
