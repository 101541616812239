import { FC } from "react";
import { createUseStyles } from "react-jss";
import { BillingPlan } from "types";
import { BILLING_PLAN_COLOR } from "consts";
import { Card, Image } from "components";
import classNames from "classnames";
import { formatToCurrency } from "utils";

interface BillingPlanCardProps
  extends Pick<BillingPlan, "name" | "id" | "defaultPrice"> {
  selectedID?: number;
  hoverable: boolean;
  onClick?: () => void;
}

const useStyles = createUseStyles<
  any,
  Pick<BillingPlanCardProps, "selectedID" | "id">
>({
  card: (props) => ({
    width: 240,
    transition: ".3s",
    "&.ant-card-hoverable:hover": {
      boxShadow: `0 5px 12px 4px ${
        props.selectedID ? BILLING_PLAN_COLOR[props.id] : "transparent"
      }`,
    },
  }),
  selected: (props) => ({
    boxShadow: `0 5px 12px 4px ${
      props.selectedID ? BILLING_PLAN_COLOR[props.selectedID] : "transparent"
    }`,
  }),
});

const BillingPlanCard: FC<BillingPlanCardProps> = ({
  selectedID,
  id,
  hoverable,
  name,
  onClick,
  defaultPrice,
}) => {
  const classes = useStyles({ selectedID, id });

  const subscriptionImages = [
    "https://eaf-app-assets.s3.amazonaws.com/subscription-usage-only.png",
    "https://eaf-app-assets.s3.amazonaws.com/subscription-card-bronze.png",
    "https://eaf-app-assets.s3.amazonaws.com/subscription-card-silver.png",
    "https://eaf-app-assets.s3.amazonaws.com/subscription-card-gold.png",
  ];

  return (
    <Card
      className={classNames(classes.card, {
        [classes.selected]: id === selectedID,
      })}
      hoverable={hoverable}
      onClick={onClick}
      cover={
        <Image
          alt="Subscription plan image"
          style={{ padding: "48px" }}
          src={`${subscriptionImages[id - 1]}`}
          preview={false}
        />
      }
    >
      <Card.Meta
        title={name}
        description={`${formatToCurrency(defaultPrice)}/month`}
      />
    </Card>
  );
};

export default BillingPlanCard;
