import { FC } from "react";
import { Flex, Skeleton as ANTSkeleton } from "components";

const Skeleton: FC = () => {
  return (
    <>
      <Flex vertical gap={14}>
        <ANTSkeleton.Image active style={{ width: "100%", height: "150px" }} />
        <ANTSkeleton.Input active style={{ width: "auto" }} />
      </Flex>
      <Flex vertical gap={14}>
        <ANTSkeleton.Image active style={{ width: "100%", height: "150px" }} />
        <ANTSkeleton.Input active style={{ width: "auto" }} />
      </Flex>
      <Flex vertical gap={14}>
        <ANTSkeleton.Image active style={{ width: "100%", height: "150px" }} />
        <ANTSkeleton.Input active style={{ width: "auto" }} />
      </Flex>
    </>
  );
};

export default Skeleton;
