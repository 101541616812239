import { FC } from "react";
import { Row, Form, Button } from "antd";
import { FormattedMessage } from "react-intl";

interface SubmitButtonProps {
  loading?: boolean;
  disabled?: boolean;
  rowClassName?: string;
}

const SubmitButton: FC<SubmitButtonProps> = ({
  loading,
  disabled,
  rowClassName,
}) => {
  return (
    <Row justify="center" className={rowClassName}>
      <Form.Item shouldUpdate>
        {({ isFieldsTouched }) => (
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={!isFieldsTouched || disabled}
          >
            <FormattedMessage id="button.submit" />
          </Button>
        )}
      </Form.Item>
    </Row>
  );
};

export default SubmitButton;
