import { FC } from "react";
import * as C from "components";
import { useCountryList } from "hooks";
import { Client } from "types";

const Address: FC<{ record: Client }> = ({ record }) => {
  const { countryList } = useCountryList();

  return (
    <C.Form layout="vertical" initialValues={record}>
      <C.Row gutter={[12, 12]}>
        <C.Col span={12}>
          <C.Typography.Title level={3}>Street Address</C.Typography.Title>
          <C.Row gutter={[12, 12]}>
            <C.Col span={24}>
              <C.InputText
                name="street_address_line_1"
                label="common.streetAddress1"
              />
            </C.Col>
            <C.Col span={24}>
              <C.InputText
                name="street_address_line_2"
                label="common.streetAddress2"
              />
            </C.Col>
            <C.Col span={24}>
              <C.Row gutter={[12, 12]} justify="space-between">
                <C.Col span={12}>
                  <C.InputText
                    name="street_address_suburb"
                    label="common.suburb"
                  />
                </C.Col>
                <C.Col span={12}>
                  <C.InputText
                    name="street_address_state"
                    label="common.state"
                  />
                </C.Col>
              </C.Row>
            </C.Col>
            <C.Col span={24}>
              <C.Row gutter={[12, 12]} justify="space-between">
                <C.Col span={12}>
                  <C.InputText name="common.postcode" label="Postcode" />
                </C.Col>
                <C.Col span={12}>
                  <C.InputSelect
                    name="streetCountryId"
                    label="common.country"
                    options={countryList}
                    getOptionProps={(option) => ({
                      value: option.id,
                      children: option.full_name,
                    })}
                  />
                </C.Col>
              </C.Row>
            </C.Col>
          </C.Row>
        </C.Col>
        <C.Col span={12}>
          <C.Typography.Title level={3}>Postal Address</C.Typography.Title>
          <C.Row gutter={[12, 12]}>
            <C.Col span={24}>
              <C.InputText
                name="postal_address_line_1"
                label="common.postalAddress1"
              />
            </C.Col>
            <C.Col span={24}>
              <C.InputText
                name="postal_address_line_2"
                label="common.postalAddress2"
              />
            </C.Col>
            <C.Col span={24}>
              <C.Row gutter={[12, 12]} justify="space-between">
                <C.Col span={12}>
                  <C.InputText name="postal_address_suburb" label="Suburb" />
                </C.Col>
                <C.Col span={12}>
                  <C.InputText name="postal_address_state" label="State" />
                </C.Col>
              </C.Row>
            </C.Col>
            <C.Col span={24}>
              <C.Row gutter={[12, 12]} justify="space-between">
                <C.Col span={12}>
                  <C.InputText
                    name="postal_address_postcode"
                    label="Postcode"
                  />
                </C.Col>
                <C.Col span={12}>
                  <C.InputSelect
                    name="postal_address_country"
                    label="common.country"
                    options={countryList}
                    getOptionProps={(option) => ({
                      value: option.id,
                      children: option.full_name,
                    })}
                  />
                </C.Col>
              </C.Row>
            </C.Col>
          </C.Row>
        </C.Col>
      </C.Row>
      <C.Row justify="end">
        <C.Button type="primary" htmlType="submit">
          Save
        </C.Button>
      </C.Row>
    </C.Form>
  );
};

export default Address;
