import { FC } from "react";
import { ExtendedDescriptions } from "components";
import { useQuery } from "hooks";
import dayjs from "dayjs";
import { DATE_FORMAT } from "consts";
import { Personal } from "types";
import { getQueryKey } from "utils";

const PersonalComponent: FC<{ appID: number }> = ({ appID }) => {
  const { data, isLoading } = useQuery<Personal>({
    apiName: "applications",
    path: `/${appID}/personal`,
    queryKey: getQueryKey("personal", { application: appID }),
  });

  return (
    <ExtendedDescriptions
      loading={isLoading}
      items={[
        {
          label: "common.placeOfBirthCountry",
          value: data?.placeOfBirthCountry,
        },
        {
          label: "common.placeOfBirthCity",
          value: data?.placeOfBirthCity,
        },
        {
          label: "common.dateOfBirth",
          value:
            data?.dateOfBirth && dayjs(data.dateOfBirth).format(DATE_FORMAT),
        },
        {
          label: "gender.title",
          value: data?.gender,
        },
      ]}
    />
  );
};

export default PersonalComponent;
