import { ExtendedTable, NavLink, SumSubStatusTag } from "components";
import { routes } from "configs";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "consts";
import { useSelector, useQuery } from "hooks";
import { getQueryKey } from "utils";
import { VerifyInternal, SumSubReview, ColumnFilterInputTypes } from "types";

interface Record extends VerifyInternal {
  applicantStatus: SumSubReview;
}

const List = () => {
  const accountID = useSelector(
    (state) => state.auth.staff?.account_id as number
  );
  const { data = [], isLoading } = useQuery<Record[]>({
    apiName: "verifyInternal",
    path: "/list",
    queryKey: getQueryKey("verifyInternalList", accountID),
    options: {
      queryParams: { accountID },
    },
  });

  return (
    <ExtendedTable<Record>
      dataSource={data}
      loading={isLoading}
      columns={[
        {
          title: "common.id",
          dataIndex: "id",
          defaultSortOrder: "descend",
          render: ({ record, highlighter }) => (
            <NavLink
              to={routes.verifyInternal.view({
                id: record.id,
                accountID: record.accountID,
                applicantID: record.applicantID,
              })}
            >
              {highlighter}
            </NavLink>
          ),
        },
        {
          title: "common.email",
          dataIndex: "email",
        },
        {
          title: "common.firstName",
          dataIndex: "firstName",
        },
        {
          title: "common.lastName",
          dataIndex: "lastName",
        },
        {
          title: "common.status",
          dataIndex: "applicantStatus",
          render: ({ record }) => (
            <SumSubStatusTag status={record.applicantStatus.reviewStatus} />
          ),
        },
        {
          title: "date.createdAt",
          dataIndex: "createdAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
        },
      ]}
    />
  );
};

export default List;
