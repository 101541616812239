import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Auth, API } from "utils";
import { User, Staff, ExpiredUserPayload } from "types";
import { notification } from "antd";

export const loginStart = createAction(
  "auth/loginStart",
  (payload: { email: string; password: string }) => ({
    payload,
  })
);

export const loginError = createAction<{ confirmSignup: boolean } | undefined>(
  "auth/loginError"
);

export const authError = createAction<ExpiredUserPayload | undefined>(
  "auth/authError"
);

export const logOut = createAsyncThunk("auth/logout", () => Auth.signOut());

export const switchStaff = createAsyncThunk<
  { user: User; staff: Staff },
  {
    id: number;
    staffID: number;
  }
>("auth/switch-staff", async ({ id, staffID }) => {
  try {
    const user = await API.patch({
      apiName: "user",
      path: `/${id}/switch-staff`,
      options: {
        body: { staffID },
      },
    });

    const staff = await API.get({
      apiName: "staff",
      path: `/${staffID}`,
    });

    return {
      user,
      staff,
    };
  } catch (e) {
    notification.error({
      message: e?.message,
    });

    return Promise.reject();
  }
});
