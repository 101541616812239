import { FC } from "react";
import { ExtendedDescriptions } from "components";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "consts";
import { CommonProps } from "../../types";

const Summary: FC<CommonProps> = ({ record }) => {
  return (
    <ExtendedDescriptions
      items={[
        {
          label: "date.createdAt",
          value: dayjs(record.createdAt).format(DATE_TIME_FORMAT),
        },
        {
          label: "date.openedAt",
          value:
            record.openedAt && dayjs(record.openedAt).format(DATE_TIME_FORMAT),
        },
        {
          label: "UUID",
          translate: false,
          value: record.uuid,
        },
      ]}
    />
  );
};

export default Summary;
