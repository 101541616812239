import { FC } from "react";
import * as C from "components";
import { Client } from "types";

const Contact: FC<{ record: Client }> = ({ record }) => {
  return (
    <C.Form layout="vertical" name="contact" initialValues={record}>
      <C.Row gutter={[12, 12]}>
        <C.Col span={12}>
          <C.Row gutter={[12, 12]}>
            <C.Col>
              <C.InputText name="tel_home" label="common.telHome" />
            </C.Col>
            <C.Col>
              <C.InputText name="tel_work" label="common.telWork" />
            </C.Col>
          </C.Row>
          <C.InputText name="tel_mobile" label="common.telMobile" />
        </C.Col>
        <C.Col span={12}>
          <C.InputEmail name="email_secondary" label="common.emailSecondary" />
        </C.Col>
      </C.Row>
      <C.Row justify="end">
        <C.Button type="primary" htmlType="submit">
          Save
        </C.Button>
      </C.Row>
    </C.Form>
  );
};

export default Contact;
