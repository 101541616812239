import { useQuery } from "./tanstack";
import { Country } from "types";
import { getQueryKey } from "utils";
import { useSelector } from "./useReactRedux";

const useCountryList = () => {
  const { accountID, language } = useSelector((state) => ({
    accountID: state.auth.staff?.account_id,
    language: state.appState.language,
  }));

  const queryKey = getQueryKey("countryList", accountID, language);

  const { data = [], isLoading } = useQuery<Country[]>({
    apiName: "countries",
    enabled: !!accountID,
    options: { queryParams: { restricted: true, accountId: accountID } },
    queryKey,
  });

  return {
    countryList: data,
    queryKey,
    countryListLoading: isLoading,
  };
};

export default useCountryList;
