import { useState, useEffect } from "react";
import {
  Col,
  Divider,
  Image,
  Row,
  Card,
  Form,
  InputText,
  InputEmail,
  Button,
  Typography,
  FormattedMessage,
  notification,
} from "components";
import * as LC from "./components";
import { useDispatch, useSelector, useForm } from "hooks";
import { authAsyncActions } from "configs";
import { createUseStyles } from "react-jss";

const { Paragraph } = Typography;

const useStyles = createUseStyles({
  card: { width: "50%", maxWidth: 400 },
  form: { marginTop: 20 },
  fullWidth: { width: "100%" },
  btnRow: { marginBottom: 24 },
  row: {
    height: "100%",
  },
  col: {
    height: "100%",
  },
  outerCard: { height: "100%", backgroundColor: "#1677ff22" },
  wrapper: {
    minHeight: 360,
    backgroundColor: "#fff",
    padding: 24,
  },
});

const COL_PROPS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
};

const Login = () => {
  const [form] = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, confirmSignup, expiredUser } = useSelector((rootState) => ({
    loading: rootState.auth.loading,
    confirmSignup: rootState.auth.confirmSignup,
    expiredUser: rootState.auth.expiredUser,
  }));
  const [state, setState] = useState<{
    email: string | null | undefined;
    forgotLoading: boolean;
    signUp: boolean;
  }>({
    email: expiredUser?.email,
    forgotLoading: false,
    signUp: false,
  });

  useEffect(() => {
    if (expiredUser) {
      setState((prevState) => ({
        ...prevState,
        email: expiredUser.email,
      }));
    }
  }, [expiredUser]);

  if (state.signUp) {
    return (
      <LC.SignUp
        goBack={() => {
          setState((prevState) => ({
            ...prevState,
            signUp: false,
          }));
        }}
      />
    );
  }

  if (confirmSignup) {
    const { email, password } = form.getFieldsValue(["email", "password"]);

    return <LC.VerifyPassword email={email} password={password} />;
  }

  return (
    <>
      <LC.ResetPasswordModal
        email={state.email}
        onCancel={() => {
          setState((prevState) => ({
            ...prevState,
            email: null,
          }));
        }}
      />
      <Row className={classes.row}>
        <Col {...COL_PROPS} className={classes.col}>
          <Card className={classes.outerCard}>
            <Typography.Title level={2}>
              Welcome to eApplicationForm
            </Typography.Title>
            <Divider />
            <Typography.Title level={5}>
              Your premier portal for the online management of your Onboard and
              KYC Verification.
            </Typography.Title>
            <Divider />
            <Paragraph>
              This platform allows you to manage your customer Onboarding Flow,
              by by providing a customisable application form system for trading
              and digital currency entities.
            </Paragraph>
            <Paragraph>
              Containing Biometric verification systems with easy-to-use, high
              resolution, facial and document scanning and verify cabilities,{" "}
              <strong>eApplicationForm </strong>
              allows you to be at the forefront of compliance requirements.
            </Paragraph>
            <Divider />
            <Paragraph>
              Login now or{" "}
              <Button
                type="link"
                size="small"
                style={{ padding: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  setState((prevState) => ({
                    ...prevState,
                    signUp: true,
                  }));
                }}
              >
                Register
              </Button>{" "}
              for a new account.
            </Paragraph>
            <Row justify="center" align="middle" className={classes.row}>
              <Image
                src="https://eaf-app-assets.s3.amazonaws.com/mainPageHeroPageImg.avif"
                height={400}
                alt="eApplicationForm"
                preview={false}
              />
            </Row>
          </Card>
        </Col>
        <Col {...COL_PROPS}>
          <Row justify="center" align="middle" className={classes.row}>
            <Card
              title={<FormattedMessage id="login.title" />}
              className={classes.card}
            >
              <Form
                form={form}
                onFinish={(values) =>
                  dispatch(authAsyncActions.loginStart(values))
                }
                layout="vertical"
                className={classes.form}
                initialValues={{ email: expiredUser?.email }}
              >
                <InputEmail required name="email" label="common.email" />
                <InputText
                  required
                  type="Password"
                  name="password"
                  label="common.password"
                />
                <Row className={classes.btnRow} justify="space-between">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "45%" }}
                    loading={loading}
                    disabled={!!expiredUser}
                  >
                    Login
                  </Button>
                  <Button
                    style={{ width: "45%" }}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        signUp: true,
                      }));
                    }}
                  >
                    Sign up
                  </Button>
                </Row>
                <Button
                  loading={state.forgotLoading}
                  className={classes.fullWidth}
                  onClick={async () => {
                    try {
                      form.setFields([
                        {
                          name: "email",
                          errors: [],
                          validated: false,
                        },
                      ]);

                      await new Promise((resolve) => {
                        setTimeout(resolve, 100);
                      });

                      await form.validateFields(["email"]);

                      setState((prevState) => ({
                        ...prevState,
                        forgotLoading: true,
                      }));

                      const username = form.getFieldValue("email").trim();

                      setState((prevState) => ({
                        ...prevState,
                        forgotLoading: false,
                        email: username,
                      }));
                    } catch (e) {
                      setState((prevState) => ({
                        ...prevState,
                        forgotLoading: false,
                      }));

                      if (e.values) {
                        return;
                      }

                      notification.error(e.message);
                    }
                  }}
                >
                  Update Password
                </Button>
              </Form>
            </Card>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Login;
