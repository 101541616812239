import { FC, ReactNode } from "react";
import { ExtendedTabs } from "components";
import * as LC from "./components";
import { useSelector, useMutation } from "hooks";
import { viewHOC, dashboardHOC } from "hocs";
import { getQueryKey } from "utils";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_PROMISE, ACCOUNT_SETTINGS_TABS } from "consts";
import { Account, ViewHOCChildProps, AccountSettingsTab } from "types";
import { Types } from "./duck";

const AccountSettings: FC<
  ViewHOCChildProps<Account> & Types.AccountSettingsProps
> = ({
  record: account,
  queryKey,
  subscription,
  subscriptionActive,
  subscriptionQueryKey,
  subscriptionLoading,
}) => {
  const cognitoID = useSelector((state) => state.auth.user?.aws_cognito_id);

  const { mutate, isPending } = useMutation<Account, Partial<Account>>({
    method: "patch",
    apiName: "accounts",
    path: `/${account.id}`,
    invalidateQueries: [queryKey, getQueryKey("staffList", cognitoID)],
  });

  const COMMON_PROPS: Types.TabProps = {
    account,
    updating: isPending,
    onFinish: mutate,
  };

  const items: {
    key: AccountSettingsTab;
    label: string;
    children: ReactNode;
  }[] = [
    {
      key: ACCOUNT_SETTINGS_TABS.SUMMARY,
      label: "Summary",
      children: <LC.Summary {...COMMON_PROPS} />,
    },
    {
      key: ACCOUNT_SETTINGS_TABS.CONTACT,
      label: "Contact Details",
      children: (
        <Elements stripe={STRIPE_PROMISE}>
          <LC.Contact
            account={account}
            billingTabKey={ACCOUNT_SETTINGS_TABS.CURRENT_SUBSCRIPTION}
            accountQueryKey={queryKey}
          />
        </Elements>
      ),
    },
    {
      key: ACCOUNT_SETTINGS_TABS.BRANDING,
      label: "Account Branding",
      children: <LC.Branding {...COMMON_PROPS} />,
    },
    {
      key: ACCOUNT_SETTINGS_TABS.SOFTWARE,
      label: "Software & Links",
      children: <LC.Software {...COMMON_PROPS} />,
    },
  ];

  if (account.billingID) {
    items.push(
      {
        key: ACCOUNT_SETTINGS_TABS.CURRENT_SUBSCRIPTION,
        label: "Current Subscription",
        children: (
          <LC.CurrentSubscription
            id={account.billingID}
            accountID={account.id}
            statusID={account.statusID}
            subscriptionLoading={subscriptionLoading}
            subscriptionActive={subscriptionActive}
            subscription={subscription}
            subscriptionQueryKey={subscriptionQueryKey}
          />
        ),
      },
      {
        key: ACCOUNT_SETTINGS_TABS.SUBSCRIPTIONS,
        label: "Subscription History",
        children: <LC.Subscriptions accountID={account.id} />,
      }
    );
  }

  if (subscription && subscriptionActive) {
    items.push({
      key: ACCOUNT_SETTINGS_TABS.USAGE,
      label: "Usage",
      children: <LC.Usage accountID={account.id} />,
    });
  }

  return <ExtendedTabs items={items} />;
};

const withViewHOC = viewHOC({
  Component: AccountSettings,
  queryKeyFn: ({ accountID }) => getQueryKey("account", accountID),
  apiName: "accounts",
  pathFn: ({ accountID }) => `/${accountID}`,
});

export default dashboardHOC<Types.AccountSettingsProps>({
  title: "account.details",
  Component: withViewHOC,
});
