import { useState } from "react";
import { useSelector, useDispatch, useNotification } from "hooks";
import {
  InputText,
  InputEmail,
  Form,
  Row,
  Col,
  Button,
  ApiOutlined,
  FormattedMessage,
  SubmitButton,
} from "components";
import { ChangePasswordModal } from "./components";
import { DATE_TIME_FORMAT, ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { User } from "types";
import dayjs from "dayjs";
import { authActions } from "configs";
import { API } from "utils";

const Summary = () => {
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const [{ modalVisible, updating }, setState] = useState({
    modalVisible: false,
    updating: false,
  });
  const { user } = useSelector((state) => ({
    user: state.auth.user as User,
  }));
  const [form] = Form.useForm();

  return (
    <>
      <ChangePasswordModal
        open={modalVisible}
        onCancel={() => {
          setState((prevState) => ({
            ...prevState,
            modalVisible: false,
          }));
        }}
      />
      <Form
        form={form}
        layout="vertical"
        name="summary"
        initialValues={user}
        onFinish={async (values) => {
          try {
            setState((prevState) => ({
              ...prevState,
              updating: true,
            }));
            const updatedUserStaff: User = await API.patch({
              apiName: "user",
              path: `/${user.aws_cognito_id}`,
              options: { body: values },
            });
            dispatch(authActions.setUserStaff(updatedUserStaff));
            setState((prevState) => ({
              ...prevState,
              updating: false,
            }));
            showNotification({
              type: "success",
              message: "messages.success",
            });
          } catch (e: any) {
            showNotification({
              type: "error",
              translateMessage: false,
              message: e.message,
            });

            setState((prevState) => ({
              ...prevState,
              updating: false,
            }));
          }
        }}
      >
        <Row gutter={ROW_GUTTER}>
          <Col {...COMMON_COL_PROPS}>
            <InputText required name="firstName" label="common.firstName" />
            <InputText required name="lastName" label="common.lastName" />
          </Col>
          <Col {...COMMON_COL_PROPS}>
            <InputEmail required name="email" />
            <Form.Item label={<FormattedMessage id="password.change" />}>
              <Row align="middle">
                <Col>
                  <Button
                    icon={<ApiOutlined />}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        modalVisible: true,
                      }));
                    }}
                  />
                </Col>
                <Col>
                  &nbsp;Expires at:{" "}
                  {dayjs(user.passwordExpiry).format(DATE_TIME_FORMAT)}
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <SubmitButton loading={updating} />
      </Form>
    </>
  );
};

export default Summary;
