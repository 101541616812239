import {
  Button,
  Card,
  Col,
  Icons,
  Image,
  List,
  Row,
  Typography,
} from "components";
import { routes } from "configs";
import { ROW_GUTTER } from "consts";
import { useNavigate } from "hooks";

const { Paragraph, Text } = Typography;

const data = [
  {
    title: "Internal Verifications",
    url: routes.verifyInternal.list(),
    description: "Verify internal documents",
  },
  {
    title: "External Verifications",
    url: routes.verifyExternal.list(),
    description: "Verify external documents",
  },
  {
    title: "My Clients",
    url: routes.clients.list(),
    description: "Manage your clients",
  },
  {
    title: "My Applications",
    url: routes.applications.list(),
    description: "Manage your applications",
  },
];

const COL_PROPS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
};

const DashboardPage = () => {
  const navigate = useNavigate();

  return (
    <Row gutter={ROW_GUTTER}>
      <Col {...COL_PROPS}>
        <Card title="My Shortcuts">
          <List
            pagination={false}
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Icons.AimOutlined />}
                  title={item.title}
                  description={
                    <Row justify="space-between">
                      <Paragraph type="secondary">{item.description}</Paragraph>
                      <Button
                        type="primary"
                        ghost
                        icon={<Icons.ArrowRightOutlined />}
                        onClick={() => {
                          navigate(item.url, { replace: true });
                        }}
                      />
                    </Row>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      </Col>
      <Col {...COL_PROPS}>
        <Card title="eApplicationForm">
          <Paragraph>
            Welcome to <Text strong>eApplicationForm.com</Text>.
          </Paragraph>
          <Paragraph>
            <Text strong>eApplicationForm</Text> is your total solution for
            managing onboarding and verification of your customers.
          </Paragraph>
          <Paragraph>
            This platform allows you to manage applications which have been
            submitted by your customers and also conduct verification of the
            submitted documents against an extensive range of compliance
            databases.
          </Paragraph>
          <Image
            src="https://eaf-app-assets.s3.amazonaws.com/mainPageHeroPageImg.avif"
            height={400}
            alt="eApplicationForm"
            preview={false}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardPage;
