import * as React from "react";
import { Form, Select, Row, InputRef, Button, Skeleton } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useIntl } from "hooks";
import { CreatableMenu } from "./components";
import { selectors } from "./duck";
import * as Types from "./types";

const { Option } = Select;

// Move `createble` into a separate select
const Dropdown: React.FC<Types.InnerSelectProps> = ({
  options = [],
  placeholder,
  allowClear = false,
  getOptionProps,
  style = { width: "100%" },
  innerRef,
  // creatable props
  onCreateSuccess,
  label,
  validate,
  //
  ...props
}) => {
  const inputRef = React.useRef<InputRef>(null);

  return (
    // eslint-disable-next-line
    // @ts-ignore
    <Select<any, Types.OptionChildren>
      {...props}
      ref={innerRef}
      style={style}
      placeholder={placeholder}
      allowClear={allowClear}
      showSearch
      optionFilterProp="children"
      filterOption={selectors.filterOption}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            <CreatableMenu
              label={label}
              validate={validate}
              onCreateSuccess={onCreateSuccess}
              inputRef={inputRef}
            />
          </>
        );
      }}
    >
      {options.map((option, index) => {
        const { value, children, removable, ...rest } = getOptionProps(
          option,
          index
        );

        return (
          <Option key={value} value={value} {...rest}>
            <Row justify="space-between" align="middle">
              {children}
              {removable && <Button type="text" icon={<DeleteOutlined />} />}
            </Row>
          </Option>
        );
      })}
    </Select>
  );
};

const CreatableSelect: React.FC<Types.WrapperProps> = ({
  label,
  name,
  isFormItem = true,
  preserve = true,
  required,
  fetching,
  rules = [],
  placeholder = "placeholders.select",
  innerRef,
  noStyle,
  className,
  normalize,
  getValueFromEvent,
  ...props
}) => {
  const intl = useIntl();

  const S = fetching ? (
    <Skeleton.Input size="small" active />
  ) : (
    <Dropdown
      {...{
        ...props,
        label,
        placeholder: selectors.getPlaceholder(intl, placeholder),
      }}
      innerRef={innerRef}
    />
  );

  if (!isFormItem) {
    return S;
  }

  return (
    <Form.Item
      label={
        label ? intl.formatMessage({ id: label, defaultMessage: "" }) : null
      }
      name={name}
      preserve={preserve}
      normalize={normalize}
      getValueFromEvent={getValueFromEvent}
      noStyle={noStyle}
      className={className}
      rules={[
        {
          required,
          message: intl.formatMessage({ id: "validation.required" }),
        },
        ...rules,
      ]}
    >
      {S}
    </Form.Item>
  );
};

export default CreatableSelect;
