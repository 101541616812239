import { FC } from "react";
import { ExtendedDescriptions, CheckOutlined, CloseOutlined } from "components";
import { useQuery } from "hooks";
import { Compliance } from "types";
import { getQueryKey } from "utils";

interface ComplianceProps {
  appID: number;
}

const ComplianceComponent: FC<ComplianceProps> = ({ appID }) => {
  const { data, isLoading } = useQuery<Compliance>({
    apiName: "applications",
    path: `/${appID}/compliance`,
    queryKey: getQueryKey("compliance", { application: appID }),
  });

  return (
    <ExtendedDescriptions
      loading={isLoading}
      items={[
        {
          label: "compliance.publicOfficeOrPep",
          value: data?.publicOfficeOrPep ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          ),
        },
        {
          label: "compliance.publicOfficeOrPep",
          value: data?.familyPublicOfficeOrPep ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          ),
        },
        {
          label: "compliance.amlOrCft",
          value: data?.amlOrCft ? <CheckOutlined /> : <CloseOutlined />,
        },
        {
          label: "compliance.adverseInformation",
          value: data?.adverseInformation ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          ),
        },
        {
          label: "compliance.refusedBanking",
          value: data?.refusedBanking ? <CheckOutlined /> : <CloseOutlined />,
        },
      ]}
    />
  );
};

export default ComplianceComponent;
