import { useEffect } from "react";
import { ReconnectingWebSocket, getIntl } from "utils";
import { notification, Button, ArrowRightOutlined } from "components";
import { SOCKET_URL, STAGE } from "consts";
import { useSelector, useApplicationsList } from "hooks";
import { routes } from "configs";

export const useSubscribeApplicationMessages = () => {
  const language = useSelector((state) => state.appState.language);
  const { applicationList } = useApplicationsList();

  useEffect(() => {
    if (!applicationList.length) {
      return;
    }

    // eslint-disable-next-line
    // @ts-ignore
    const ws = new ReconnectingWebSocket(SOCKET_URL, null, {
      debug: STAGE !== "prod",
      reconnectInterval: 2000,
      reconnectAttempts: 10,
    });

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          action: "subscribe",
        })
      );
    };
    ws.onmessage = (event) => {
      // {applicationID, createdBy: EAFP | EAFA
      const message = JSON.parse(event.data);
      const application = applicationList.find(
        (app) => app.id === message.applicationID
      );

      const currentApp =
        application &&
        window.location.pathname === routes.applications.view(application.id);

      if (
        !currentApp &&
        message.createdBy === "EAFP" &&
        application &&
        application.statusId === 2
      ) {
        const intl = getIntl(language);

        notification.info({
          message: (
            <Button
              type="link"
              target="_blank"
              href={`${window.location.origin}${routes.applications.view(
                application.id,
                "messaging"
              )}`}
            >
              {intl.formatMessage({ id: "applications.newMessage" })}
              <ArrowRightOutlined />
            </Button>
          ),
        });
      }
    };

    return () => {
      ws.close();
    };
  }, [applicationList, language]);
};
