import * as React from "react";
import {
  CheckCircleFilled,
  MinusCircleOutlined,
  ExtendedDescriptions,
} from "components";
import { InformationRequest } from "types";

const Summary: React.FC<{ record: InformationRequest }> = ({ record }) => {
  return (
    <>
      <ExtendedDescriptions
        items={[
          {
            label: "common.id",
            value: record.id,
          },
          {
            label: "UUID",
            translate: false,
            value: record.uuid,
          },
        ]}
      />
      <ExtendedDescriptions
        items={[
          {
            label: "common.active",
            value: record.active ? (
              <CheckCircleFilled />
            ) : (
              <MinusCircleOutlined />
            ),
          },
          {
            label: "common.pincode",
            value: record.pincode,
          },
          {
            label: "common.email",
            value: record.email,
          },
        ]}
      />
    </>
  );
};

export default Summary;
