import { FC, useState, useEffect } from "react";
import { API, Auth } from "utils";
import { authAsyncActions } from "configs";
import { useDispatch, useNotification } from "hooks";
import {
  InputPassword,
  InputText,
  Divider,
  Form,
  message,
  Modal,
  notification,
} from "components";

interface ComponentProps {
  email?: string | null;
  onCancel: () => void;
}

const ResetPasswordModal: FC<ComponentProps> = ({ email, onCancel }) => {
  const showNotification = useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!email) {
      return;
    }

    Auth.resetPassword({
      username: email,
    })
      .then(({ nextStep }) => {
        if (nextStep.resetPasswordStep) {
          showNotification({
            type: "info",
            message: "login.checkEmail",
            messageValues: {
              email: {
                translate: false,
                value: nextStep.codeDeliveryDetails.deliveryMedium as string,
              },
            },
          });
        }
      })
      .catch((e) => {
        notification.error(e.message);
      });

    // eslint-disable-next-line
  }, [email]);

  const onOk = async () => {
    const { confirmationCode, newPassword } = form.getFieldsValue();

    setLoading(true);

    try {
      await Auth.confirmResetPassword({
        username: email as string,
        confirmationCode: confirmationCode.trim(),
        newPassword,
      });

      const { userSub } = await Auth.fetchAuthSession();

      if (userSub) {
        await API.patch({
          apiName: "user",
          path: "/password",
          options: {
            body: {
              cognitoID: userSub,
            },
          },
        });
      }
      dispatch(authAsyncActions.logOut());

      message.info(
        "Password has been successfully set.  You may now login using the new password.",
        10
      );

      onCancel();
    } catch (e) {
      notification.error({
        message: e.message,
      });
    }

    setLoading(false);
  };

  return (
    <Modal
      open={!!email}
      title="Password Reset"
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={loading}
      okText="Submit"
      destroyOnClose
    >
      <p>
        Please check your email (including spam folder) for a verification code.
        <br />
        Enter it below, with your chosen new password.
        <br />
        <br />
        <strong>
          New password must be 8 characters including uppercase, lowercase one
          number and one symbol.
        </strong>
      </p>
      <Divider />
      <Form form={form} preserve={false} layout="vertical">
        <InputText
          required
          name="confirmationCode"
          label="password.confirmCode"
        />
        <InputPassword
          required
          validate
          label="password.new"
          name="newPassword"
        />
      </Form>
    </Modal>
  );
};

export default ResetPasswordModal;
