import { FC } from "react";
import {
  Col,
  Row,
  InputSelect,
  InputText,
  Typography,
  FormattedMessage,
} from "components";
import { constants } from "../../duck";
import { Country } from "types";

const PREFIX = "addresses";

const Address: FC<{ countryList: Country[] }> = ({ countryList }) => {
  return (
    <>
      <Row justify="center">
        <Typography.Title level={5}>
          <FormattedMessage id="common.address" />
        </Typography.Title>
      </Row>
      <Row gutter={14} justify="space-around">
        <Col {...constants.COL_PROPS}>
          <InputSelect
            name={[PREFIX, "country"]}
            label="common.country"
            options={countryList}
            getOptionProps={(opt) => ({
              value: opt.iso_alpha_3,
              children: opt.full_name,
            })}
          />
          <InputText name={[PREFIX, "postCode"]} label="common.postcode" />
        </Col>
        <Col {...constants.COL_PROPS}>
          <InputText name={[PREFIX, "town"]} label="verifyInternal.town" />
          <InputText name={[PREFIX, "street"]} label="verifyInternal.street" />
        </Col>
        <Col {...constants.COL_PROPS}>
          <InputText name={[PREFIX, "state"]} label="common.state" />
          <InputText
            name={[PREFIX, "buildingName"]}
            label="verifyInternal.buildingName"
          />
        </Col>
        <Col {...constants.COL_PROPS}>
          <InputText
            name={[PREFIX, "buildingNumber"]}
            label="verifyInternal.buildingNumber"
          />
          <InputText
            name={[PREFIX, "flatNumber"]}
            label="verifyInternal.flatNumber"
          />
        </Col>
      </Row>
    </>
  );
};

export default Address;
