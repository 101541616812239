import { FC, useState } from "react";
import { Button, Flex } from "components";
import * as LC from "./components";
import { useMutation, useQuery } from "hooks";
import { getQueryKey } from "utils";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_PROMISE } from "consts";
import { BillingPlan, Subscription, QueryKey } from "types";

interface CreateSubscriptionProps {
  billingID: number;
  accountID: number;
  subscriptionLoading: boolean;
  subscription: Partial<Subscription>;
  subscriptionQueryKey: QueryKey;
}

const CreateSubscription: FC<CreateSubscriptionProps> = ({
  billingID,
  subscription,
  accountID,
  subscriptionLoading,
  subscriptionQueryKey,
}) => {
  const { mutate, isPending } = useMutation<
    {
      type: "setup" | "intent";
      clientSecret: string;
    },
    {
      billingPlanID: number;
      billingID: number;
    }
  >({
    apiName: "accounts",
    path: `/${accountID}/subscription`,
    invalidateQueries: [subscriptionQueryKey],
    method: "post",
  });

  const [selectedBillingID, selectBillingID] = useState<number | undefined>();
  const { data = [], isLoading } = useQuery<BillingPlan[]>({
    apiName: "subscription",
    path: "/billing-plans",
    queryKey: getQueryKey("billingPlans"),
  });

  const type = subscription?.type;
  const clientSecret = subscription?.clientSecret;
  const billingPlanID = subscription?.billingPlanID || selectedBillingID;

  const billingPlan = data.find((item) => item.id === billingPlanID);

  if (type && clientSecret && billingPlan) {
    return (
      <Elements
        stripe={STRIPE_PROMISE}
        options={{
          clientSecret,
        }}
      >
        <LC.ConfirmSubscription type={type} billingPlan={billingPlan} />
      </Elements>
    );
  }

  return (
    <>
      <Flex gap={14} justify="space-between" wrap="wrap">
        {isLoading || subscriptionLoading ? (
          <LC.Skeleton />
        ) : (
          data.map((item) => (
            <LC.BillingPlanCard
              {...item}
              key={item.id}
              selectedID={selectedBillingID}
              hoverable={selectedBillingID !== item.id}
              onClick={() => {
                selectBillingID(item.id);
              }}
            />
          ))
        )}
      </Flex>
      <Flex style={{ marginTop: 14 }} justify="center">
        <Button
          type="primary"
          loading={isPending}
          disabled={!selectedBillingID}
          onClick={() => {
            if (!selectedBillingID) {
              return;
            }

            mutate({
              billingID,
              billingPlanID: selectedBillingID,
            });
          }}
        >
          Submit
        </Button>
      </Flex>
    </>
  );
};

export default CreateSubscription;
