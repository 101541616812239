import { FC } from "react";
import { Tag } from "antd";
import { SumSubReviewStatus } from "types";
import { COLORS } from "consts";

interface SumSubStatusTagProps {
  status: SumSubReviewStatus;
}

const STATUS_COLORS = {
  init: COLORS.grey[4],
  pending: COLORS.purple[4],
  completed: COLORS.green[4],
} as const;

const SumSubStatusTag: FC<SumSubStatusTagProps> = ({ status }) => {
  return (
    <Tag color={STATUS_COLORS[status] || STATUS_COLORS.init}>{status}</Tag>
  );
};

export default SumSubStatusTag;
