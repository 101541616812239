import { FC } from "react";
import { useAccountList } from "hooks";
import {
  Form,
  Row,
  Col,
  ExtendedTag,
  InputText,
  InputSelect,
  SubmitButton,
} from "components";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { TabProps } from "../../types";

const Summary: FC<TabProps> = ({ staff, submitting, submit }) => {
  const { accountList } = useAccountList();
  const [form] = Form.useForm();

  return (
    <Form form={form} layout="vertical" initialValues={staff} onFinish={submit}>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputText required name="first_name" label="common.firstName" />
          <InputText required name="last_name" label="common.lastName" />

          <InputText name="middle_name" label="common.middleName" />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputSelect
            required
            name="account_id"
            label="common.account"
            options={accountList}
            getOptionProps={(option) => ({
              value: option.id,
              children: (
                <ExtendedTag backgroundColor={option.color}>
                  {option.account_name}
                </ExtendedTag>
              ),
            })}
          />
        </Col>
      </Row>
      <SubmitButton loading={submitting} />
    </Form>
  );
};

export default Summary;
