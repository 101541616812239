import { FC } from "react";
import { Typography, Divider, Table, Tag } from "antd";
import { FormattedMessage } from "react-intl";
import ExtendedDescriptions from "components/extended-descriptions";
import SumSubStatusTag from "components/sumsub-status-tag";
import { RequiredIdDocs } from "./components";
import moment from "moment";
import { useCountryList } from "hooks";
import { DATE_TIME_FORMAT } from "consts";
import { SumSubApplicant, ExtendedDescriptionItem } from "types";

const SumSubMarkup: FC<{ record: SumSubApplicant }> = ({ record }) => {
  const { countryList } = useCountryList();

  let applicantInfo: ExtendedDescriptionItem[] = [];
  let applicantReview: ExtendedDescriptionItem[] = [];
  let applicantReviewResult: ExtendedDescriptionItem[] | undefined;
  let applicantIdDocs: ExtendedDescriptionItem[] | undefined;

  const {
    info,
    review,
    requiredIdDocsStatus: { SELFIE, IDENTITY },
  } = record;

  if (info) {
    applicantInfo = [
      {
        label: "common.firstName",
        value: info.firstName,
      },
      {
        label: "common.lastName",
        value: info.lastName,
      },
      {
        label: "common.firstNameEN",
        value: info.firstNameEn,
      },
      {
        label: "common.lastNameEN",
        value: info.lastNameEn,
      },
      {
        label: "common.dateOfBirth",
        value: info.dob,
      },
      {
        label: "common.country",
        value: countryList.find((c) => c.iso_alpha_3 === info.country)
          ?.full_name,
      },
    ];

    const { idDocs } = info;

    if (idDocs) {
      applicantIdDocs = idDocs.reduce((prev, item) => {
        const keys = Object.keys(item) as Array<
          keyof NonNullable<
            NonNullable<SumSubApplicant["info"]>["idDocs"]
          >[number]
        >;

        const items: ExtendedDescriptionItem[] = keys.map((label) => {
          const value = item[label];

          return {
            label,
            value: Array.isArray(value) ? JSON.stringify(value) : value,
            translate: false,
          };
        });

        return prev.concat(items);
      }, [] as ExtendedDescriptionItem[]);
    }
  }

  if (review) {
    const reviewKeys = Object.keys(review) as Array<
      keyof SumSubApplicant["review"]
    >;

    applicantReview = reviewKeys.reduce((prev, key) => {
      const value = review[key];

      const item = {
        label: key,
        value:
          key === "reviewStatus" ? <SumSubStatusTag status={value} /> : value,
        translate: false,
      };

      if (key === "reviewResult") {
        return prev;
      }

      return prev.concat(item);
    }, [] as ExtendedDescriptionItem[]);

    const { reviewResult } = review;

    if (reviewResult) {
      const keys = Object.keys(reviewResult) as Array<
        keyof NonNullable<
          NonNullable<SumSubApplicant["review"]>["reviewResult"]
        >
      >;

      applicantReviewResult = keys.reduce((prev, key) => {
        const v = reviewResult[key];
        const value = Array.isArray(v) ? v.join(", ") : v;

        const item = {
          label: key,
          value:
            key === "reviewAnswer" ? (
              <Tag color={value.toLowerCase()}>{value}</Tag>
            ) : (
              value
            ),
          translate: false,
        };

        return prev.concat(item);
      }, [] as ExtendedDescriptionItem[]);
    }
  }

  return (
    <>
      <ExtendedDescriptions
        title="verifications.liveCheck"
        items={[
          {
            label: "common.id",
            value: record.id,
          },
          {
            label: "date.createdAt",
            value: moment(record.createdAt).format(DATE_TIME_FORMAT),
          },
          {
            label: "verifications.externalUserId",
            value: record.externalUserId,
          },
          ...applicantInfo,
        ]}
      />
      <Divider />
      {applicantIdDocs && (
        <>
          <ExtendedDescriptions
            title="verifications.idDocs"
            items={applicantIdDocs}
          />
          <Divider />
        </>
      )}
      <ExtendedDescriptions
        title="verifications.review"
        items={applicantReview}
      />
      <Divider />
      {applicantReviewResult && (
        <>
          <ExtendedDescriptions
            title="verifications.reviewResult"
            items={applicantReviewResult}
          />
          <Divider />
        </>
      )}
      <Typography.Title level={5}>
        <FormattedMessage id="verifications.requiredIdDocs" />
      </Typography.Title>
      <Table
        rowKey="idDocSetType"
        dataSource={record.requiredIdDocs.docSets}
        columns={[
          {
            title: "idDocSetType",
            dataIndex: "idDocSetType",
          },
          {
            title: "types",
            dataIndex: "types",
            render: (types) => types.join(", "),
          },
          {
            title: "videoRequired",
            dataIndex: "videoRequired",
          },
        ]}
      />
      <Divider />
      <Typography.Title level={5}>
        <FormattedMessage id="verifications.requiredIdDocsStatus" />
      </Typography.Title>
      <RequiredIdDocs
        countryList={countryList}
        inspectionId={record.inspectionId}
        SELFIE={SELFIE}
        IDENTITY={IDENTITY}
      />
    </>
  );
};

export default SumSubMarkup;
