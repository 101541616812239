import { Form, Tabs } from "components";

import * as LC from "./components";

const UserProfile = () => {
  return (
    <Form.Provider onFormFinish={() => {}}>
      <Tabs
        defaultActiveKey="summary"
        items={[
          {
            key: "summary",
            label: "Summary",
            children: <LC.Summary />,
          },
        ]}
      />
    </Form.Provider>
  );
};

export default UserProfile;
