import { FC, useEffect, useState, useRef } from "react";
import { API } from "utils";
import { useNotification } from "hooks";
import { ApplicationMessage } from "types";
import { DATE_TIME_FORMAT } from "consts";
import dayjs from "dayjs";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import InfiniteScroll from "react-infinite-scroll-component";
import { operations, Types } from "./duck";
import {
  List,
  SendOutlined,
  Typography,
  Space,
  Button,
  Form,
  InputText,
  Row,
  Col,
  Skeleton,
  Divider,
  InputRef,
  ExclamationCircleTwoTone,
  CheckOutlined,
  EyeOutlined,
  FileUpload,
  PaperClipOutlined,
  FileRenderer,
  FormattedMessage,
} from "components";
import { PreviewModal, DroppableLayer } from "./components";

interface MessagingProps {
  appID: number;
}

const useStyles = createUseStyles({
  fullWidth: {
    width: "100%",
  },
  textRight: {
    textAlign: "right",
  },
  textLeft: {
    textAlign: "left",
  },
  messageInfo: {
    fontSize: 10,
    fontWeight: "bold",
    "& p": {
      margin: 0,
    },
  },
  flex: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  scrollableDiv: {
    height: 300,
    overflow: "auto",
  },
  newMessage: {
    backgroundColor: "#d3d3d347",
  },
  uploadFormItem: {
    margin: 0,
  },
  uploadButton: {
    "& .ant-upload": {
      "&.ant-upload-drag": {
        border: "none",
      },
      "&.ant-upload-btn": {
        padding: 0,
      },
    },
  },
  uploadWrapper: {
    "& .ant-upload.ant-upload-drag": {
      cursor: "auto",
      background: "none",
      border: "none",
    },
  },
});

const LIMIT = 10;

const Messaging: FC<MessagingProps> = ({ appID }) => {
  const [form] = Form.useForm();
  const classes = useStyles();
  const [state, setState] = useState<{
    fetching: boolean;
    hasMore: boolean;
    socketError: boolean;
    newMessagesQueue: Map<string, Types.NewMessage>;
    messages: Map<number, ApplicationMessage>;
    lazyLoadKey: number;
    messageFiles: {
      [queueID: string]: File | null;
    } | null;
    currentFile: File | null;
  }>({
    lazyLoadKey: 0,
    messages: new Map(),
    newMessagesQueue: new Map(),
    hasMore: true,
    fetching: false,
    socketError: false,
    currentFile: null,
    messageFiles: null,
  });
  const showNotification = useNotification();
  const socketRef = useRef<WebSocket>();
  const emitRef = useRef<Types.Emit>();
  const queuedMessagesRef = useRef<Map<string, Types.NewMessage>>(new Map());
  const inputRef = useRef<InputRef>(null);

  const loadMoreData = async (offset: number) => {
    setState((prev) => ({
      ...prev,
      fetching: true,
    }));

    try {
      const messages = await API.get({
        apiName: "applications",
        path: `/${appID}/messages`,
        options: {
          queryParams: { offset },
        },
      });

      operations.readMessages({
        messages,
        cb: (messageIDs) => {
          emitRef.current?.(
            {
              applicationID: appID,
              messageIDs,
            },
            "readmessage"
          );
        },
      });

      setState((prevState) => {
        const newMessages = new Map([
          ...operations.arrByID(messages),
          ...operations.arrByID(Array.from(prevState.messages.values())),
        ]);
        return {
          ...prevState,
          fetching: false,
          hasMore: messages.length >= LIMIT,
          lazyLoadKey:
            offset > 0 ? prevState.lazyLoadKey + 1 : prevState.lazyLoadKey,
          messages: newMessages,
        };
      });
    } catch (e) {
      showNotification({
        type: "error",
        message: "messages.error.fetchData",
      });
      setState((prev) => ({
        ...prev,
        fetching: false,
      }));
    }
  };

  const { messages, newMessagesQueue, lazyLoadKey } = state;

  useEffect(() => {
    const result = operations.connectSocket({
      onError: () => {
        setState((prev) => ({
          ...prev,
          socketError: true,
        }));
      },
      onOpen: () => {
        setState((prev) => ({
          ...prev,
          socketError: false,
        }));

        const queue = Array.from(queuedMessagesRef.current.values());

        return {
          applicationID: appID,
          offset: queue.length,
          messages: queue,
        };
      },

      onMessage: async (newData) => {
        if (
          !Array.isArray(newData) &&
          // System error from API Gateway
          // eslint-disable-next-line
          // @ts-ignore
          newData?.message?.toLowerCase().includes("error")
        ) {
          return;
        }

        operations.readMessages({
          messages: Array.isArray(newData) ? newData : [newData],
          cb: (messageIDs) => {
            emitRef.current?.(
              {
                applicationID: appID,
                messageIDs,
              },
              "readmessage"
            );
          },
        });

        setState((prev) => {
          if (Array.isArray(newData)) {
            const newMessages = new Map([
              ...operations.arrByID(Array.from(prev.messages.values())),
              ...operations.arrByID(newData),
            ]);

            const queueMap = new Map(operations.arrByQueueID(newData));
            const queue = new Map();

            prev.newMessagesQueue.forEach((message, queueID) => {
              if (!queueMap.has(queueID)) {
                queue.set(queueID, message);
              }
            });

            queuedMessagesRef.current = queue;

            return {
              ...prev,
              hasMore: newMessages.size >= LIMIT,
              socketError: false,
              newMessagesQueue: queue,
              messages: newMessages,
            };
          }

          queuedMessagesRef.current.delete(newData.queueID);

          return {
            ...prev,
            newMessagesQueue: new Map(queuedMessagesRef.current),
            messages: new Map(prev.messages).set(newData.id, newData),
          };
        });
      },
    });

    // eslint-disable-next-line
    // @ts-ignore
    socketRef.current = result?.ws;
    emitRef.current = result?.emit;

    return () => {
      socketRef.current?.close();
    };
    // eslint-disable-next-line
  }, [appID]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [newMessagesQueue.size]);

  const sendMessage = ({
    content = "",
    awsFileID,
  }: {
    content?: string;
    awsFileID?: string;
  }) => {
    const queueID = uuid();

    const newMessage: Types.NewMessage = {
      id: null,
      applicationID: appID,
      content,
      awsFileID,
      createdBy: "EAFA",
      queueID,
      createdAt: dayjs.utc().toISOString(),
    };

    queuedMessagesRef.current.set(queueID, newMessage);

    emitRef.current?.(newMessage, "sendmessage");

    // do not clear input value if a user types smth but dosn't send
    if (!awsFileID) {
      form.resetFields();
    }

    setState((prevState) => {
      const messageFiles = {
        [queueID]: state.currentFile,
      };

      return {
        ...prevState,
        currentFile: null,
        newMessagesQueue: new Map(queuedMessagesRef.current),
        messageFiles: prevState.messageFiles
          ? {
              ...prevState.messageFiles,
              ...messageFiles,
            }
          : messageFiles,
      };
    });
  };

  return (
    <>
      <div
        id="scrollableDiv"
        className={classNames(classes.scrollableDiv, classes.flex)}
      >
        <DroppableLayer
          onDrop={(file) => {
            setState((prevState) => ({
              ...prevState,
              currentFile: file,
            }));
          }}
        >
          <InfiniteScroll
            inverse
            // re-mount on lazy load
            key={lazyLoadKey}
            dataLength={messages.size}
            next={() => {
              loadMoreData(messages.size);
            }}
            hasMore={state.hasMore}
            className={classes.flex}
            loader={
              state.hasMore ? (
                <Skeleton avatar paragraph={{ rows: 1 }} active />
              ) : null
            }
            endMessage={
              <Divider plain>
                <FormattedMessage id="applications.endMessage" />
              </Divider>
            }
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={[
                ...Array.from(messages.values()),
                ...Array.from(newMessagesQueue.values()),
              ]}
              renderItem={(item) => {
                const myMessage = item.createdBy === "EAFA";
                const file = state.messageFiles?.[item.queueID];
                const fileRendererProps = file
                  ? { file }
                  : { awsFileID: item.awsFileID };

                return (
                  <List.Item
                    key={item.createdAt}
                    className={classNames({
                      [classes.newMessage]: !item.id,
                    })}
                  >
                    <Row
                      justify={myMessage ? "end" : "start"}
                      className={classes.fullWidth}
                    >
                      <Col
                        span={12}
                        className={classNames({
                          [classes.textRight]: myMessage,
                          [classes.textLeft]: !myMessage,
                        })}
                      >
                        <Typography.Paragraph>
                          {item.content || (
                            <FileRenderer
                              {...fileRendererProps}
                              bucket="applicationDocuments"
                            />
                          )}
                        </Typography.Paragraph>
                        <div className={classes.messageInfo}>
                          <Space>
                            {dayjs(item.createdAt).format(DATE_TIME_FORMAT)}
                            <span>
                              {item.id && myMessage && !item.readAt && (
                                <CheckOutlined />
                              )}
                              {myMessage && item.readAt && <EyeOutlined />}
                              {!item.id && state.socketError && (
                                <ExclamationCircleTwoTone twoToneColor="#eb2f96" />
                              )}
                            </span>
                          </Space>
                        </div>
                      </Col>
                    </Row>
                  </List.Item>
                );
              }}
            />
          </InfiniteScroll>
        </DroppableLayer>
      </div>
      <Form
        form={form}
        className={classes.fullWidth}
        initialValues={{
          content: "",
        }}
        onFinish={({ content }) => {
          if (!content.trim() || !content.replace(/\s/g, "")) {
            return;
          }

          sendMessage({ content });
        }}
      >
        <Row justify="space-between">
          <Col flex={2}>
            <InputText
              name="content"
              placeholder="placeholders.typeHere"
              innerRef={inputRef}
            />
          </Col>
          <Col>
            <Row>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SendOutlined />}
                />
                <FileUpload
                  maxCount={1}
                  label={null}
                  formItemClassName={classes.uploadFormItem}
                  className={classes.uploadButton}
                  showUploadList={false}
                  onUploadSuccess={(file) => {
                    setState((prevState) => ({
                      ...prevState,
                      currentFile: file,
                    }));
                  }}
                >
                  <Button htmlType="button" icon={<PaperClipOutlined />} />
                </FileUpload>
              </Space>
            </Row>
          </Col>
        </Row>
      </Form>
      <PreviewModal
        appID={appID}
        currentFile={state.currentFile}
        onOk={(awsFileID) => {
          sendMessage({ awsFileID });
        }}
        onCancel={() => {
          setState((prevState) => ({
            ...prevState,
            currentFile: null,
          }));
        }}
      />
    </>
  );
};

export default Messaging;
