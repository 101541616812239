export enum BillingPlanEnum {
  UsageOnly = 1,
  Bronze = 2,
  Silver = 3,
  Gold = 4,
}

export interface Billing {
  accountID: number;
  email: string;
  id: number;
  name: string;
  stripeCustomerID: string;
}

export interface BillingPlan {
  active: number;
  clientMonitoringID: string;
  defaultPrice: number;
  demoAppID: string;
  id: number;
  liveAppID: string;
  monthlyPriceID: string;
  name: string;
  productID: string;
  verifyExternalID: string;
  verifyInternalID: string;
}

export interface SetupIntent {
  id: string;
  status:
    | "requires_payment_method"
    | "requires_confirmation"
    | "requires_action"
    | "processing"
    | "canceled"
    | "succeeded";
  subscriptionID: number;
}

export interface Subscription {
  accountID: number;
  billingPlanID: number;
  billingPlan: string;
  defaultPrice: number;
  canceledAt: Date | null;
  createdAt: Date;
  id: number;
  paymentIntentID: string;
  stripeID: string;
  periodStart: string;
  periodEnd: string;
  type?: "setup" | "intent";
  clientSecret?: string;
  setupIntentStatus?: SetupIntent["status"];
  status:
    | "incomplete"
    | "incomplete_expired"
    | "trialing"
    | "active"
    | "past_due"
    | "canceled"
    | "unpaid"
    | "paused";
}

export interface SubscriptionUsage {
  createdAt: Date;
  description: string;
  billingPlanID: number;
  id: number;
  price: number;
  stripeID: string;
}

export interface StripeAddressElementValue {
  complete: boolean;
  isNewAddress: boolean;
  value: {
    name: string;
    phone?: string;
    address: {
      city: string;
      country: string;
      line1: string;
      line2?: string;
      postal_code: string;
      state: string;
    };
  };
}
