import { FC } from "react";
import { Types } from "../../duck";
import * as C from "components";

const Software: FC<Types.TabProps> = ({ account, updating, onFinish }) => {
  return (
    <C.Form layout="vertical" initialValues={account} onFinish={onFinish}>
      <C.Row gutter={[12, 12]}>
        <C.Col span={12}>
          <C.Row gutter={[12, 12]}>
            <C.Col>
              <C.InputText
                name="desktop_app_name"
                label="account.desktop_app_name"
              />
            </C.Col>
            <C.Col>
              <C.InputText
                name="mobile_app_name"
                label="account.mobile_app_name"
              />
            </C.Col>
            <C.Col>
              <C.InputText
                name="desktop_windows_app_name"
                label="account.desktop_windows_app_name"
              />
            </C.Col>
            <C.Col>
              <C.InputText
                name="desktop_windows_app_link"
                label="account.desktop_windows_app_link"
              />
            </C.Col>
            <C.Col>
              <C.InputText
                name="desktop_mac_app_name"
                label="account.desktop_mac_app_name"
              />
            </C.Col>
            <C.Col>
              <C.InputText
                name="desktop_mac_app_link"
                label="account.desktop_mac_app_link"
              />
            </C.Col>
            <C.Col>
              <C.InputText name="ios_app_name" label="account.ios_app_name" />
            </C.Col>
            <C.Col>
              <C.InputText name="ios_app_link" label="account.ios_app_link" />
            </C.Col>
            <C.Col>
              <C.InputText
                name="android_app_name"
                label="account.android_app_name"
              />
            </C.Col>
            <C.Col>
              <C.InputText
                name="android_app_link"
                label="account.android_app_link"
              />
            </C.Col>
            <C.Col>
              <C.InputText name="web_app_name" label="account.web_app_name" />
            </C.Col>
            <C.Col>
              <C.InputText name="web_app_link" label="account.web_app_link" />
            </C.Col>
          </C.Row>
        </C.Col>
        <C.Col span={12}>
          <C.Row gutter={[12, 12]}>
            <C.Col>
              <C.InputText name="afsl" label="account.afsl" />
            </C.Col>
            <C.Col>
              <C.InputText name="tePrefixDemo" label="account.tePrefixDemo" />
            </C.Col>
            <C.Col>
              <C.InputText name="tePrefixLive" label="account.tePrefixLive" />
            </C.Col>
            <C.Col>
              <C.InputText name="teGroupDemo" label="account.teGroupDemo" />
            </C.Col>
            <C.Col>
              <C.InputText name="teGroupLive" label="account.teGroupLive" />
            </C.Col>
          </C.Row>
        </C.Col>
      </C.Row>

      <C.SubmitButton loading={updating} />
    </C.Form>
  );
};

export default Software;
