import { FC } from "react";
import { ExtendedTable, Typography, Image, FilePdfOutlined } from "components";
import { useQuery } from "hooks";
import { EnhancedDueDiligence } from "types";
import { getQueryKey } from "utils";

const EddDocuments: FC<{ appID: number }> = ({ appID }) => {
  const { data = [], isLoading } = useQuery<EnhancedDueDiligence[]>({
    apiName: "applications",
    path: `/${appID}/edd`,
    queryKey: getQueryKey("eddDocuments", { application: appID }),
  });

  return (
    <ExtendedTable<EnhancedDueDiligence>
      dataSource={data}
      loading={isLoading}
      columns={[
        {
          title: "common.description",
          dataIndex: "description",
        },
        {
          title: "common.document",
          dataIndex: "awsFileID",
          render: ({ record }) => {
            const isPDF = record.contentType.includes("pdf");

            return isPDF ? (
              <Typography.Link href={record.url} target="_blank">
                <FilePdfOutlined />
              </Typography.Link>
            ) : (
              <Image src={record.url} width={50} height={50} />
            );
          },
        },
      ]}
    />
  );
};

export default EddDocuments;
