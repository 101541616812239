import { FC } from "react";
import { NavLink, Alert, Tag, Typography } from "components";
import { routes } from "configs";
import { AccountStatusEnum, Subscription } from "types";

interface AccountInfoMessageProps {
  accountStatusID?: number;
  subscriptionActive: boolean;
  subscriptionLoading: boolean;
  subscription?: Subscription;
}

const AccountInfoMessage: FC<AccountInfoMessageProps> = ({
  accountStatusID,
  subscriptionActive,
  subscriptionLoading,
  subscription,
}) => {
  if (subscriptionLoading) {
    return null;
  }

  if (accountStatusID === AccountStatusEnum.Incomplete) {
    return (
      <Alert
        showIcon
        type="error"
        message="Please complete signup process"
        description={
          <>
            In order to complete your application for an account, please{" "}
            <NavLink to={routes.settings.account("CONTACT")}>
              click here
            </NavLink>{" "}
            and provide your email address, contact telephone and address.
          </>
        }
      />
    );
  }

  if (accountStatusID === AccountStatusEnum.Pending) {
    return (
      <Alert
        showIcon
        type="info"
        message="Account Pending Activation"
        description={
          <>
            Your account is being activated. Please check back shortly or you
            can email us at{" "}
            <Typography.Paragraph copyable>
              admin@eapplicationform.com
            </Typography.Paragraph>
          </>
        }
      />
    );
  }

  if (!subscriptionActive) {
    const status = subscription?.setupIntentStatus || subscription?.status;
    return (
      <Alert
        showIcon
        type="error"
        message="No active subscription"
        description={
          <>
            Some features are temporarily disabled.&nbsp;
            {status && <Tag>Status: {status}</Tag>}
          </>
        }
      />
    );
  }

  return null;
};

export default AccountInfoMessage;
