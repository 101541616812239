const {
  REACT_APP_STAGE: STAGE,
  REACT_APP_SOCKET_URL: SOCKET_URL,
  REACT_APP_UPLOADS_BUCKET: UPLOADS_BUCKET,
  REACT_APP_IDENTIFICATION_DOCS_BUCKET: IDENTIFICATION_DOCS_BUCKET,
  REACT_APP_APPLICATIONS_DOCS_BUCKET: APPLICATIONS_DOCS_BUCKET,
  REACT_APP_APPLICANT_IMAGES_BUCKET: APPLICANT_IMAGES_BUCKET,
  REACT_APP_STRIPE_PUBLIC_KEY: STRIPE_PUBLIC_KEY,
  REACT_APP_GOOGLE_API_KEY: GOOGLE_API_KEY,
  REACT_APP_USER_GEO_API_KEY: USER_GEO_API_KEY,
} = process.env as Record<string, string>;

export {
  STAGE,
  SOCKET_URL,
  UPLOADS_BUCKET,
  IDENTIFICATION_DOCS_BUCKET,
  APPLICATIONS_DOCS_BUCKET,
  APPLICANT_IMAGES_BUCKET,
  STRIPE_PUBLIC_KEY,
  GOOGLE_API_KEY,
  USER_GEO_API_KEY,
};
