import { FC, ReactNode } from "react";
import { useDropzone, useNotification } from "hooks";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { ACCEPTED_FILES } from "consts";
import { blue, magenta } from "@ant-design/colors";

const useStyles = createUseStyles({
  mask: {
    fontSize: 50,
    color: "#fff",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(45deg, ${blue[4]}, ${magenta[4]})`,
    opacity: 0.7,
    transition: "opacity .5s",
  },
  inactive: {
    zIndex: 0,
  },
  transparent: {
    opacity: 0,
  },
});

const DroppableLater: FC<{
  onDrop: (file: File) => void;
  children: ReactNode;
}> = ({ onDrop, children }) => {
  const showNotification = useNotification();
  const classes = useStyles();
  const { isDragActive, getRootProps } = useDropzone({
    noDragEventsBubbling: true,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    onDropRejected: ([fileRejections]) => {
      if (
        fileRejections.errors.some((err) => err.code === "file-invalid-type")
      ) {
        showNotification({
          type: "error",
          message: "messages.invalidFileFormat",
          messageValues: {
            format: {
              value: fileRejections.file.type,
            },
            allowedFormats: {
              value: ACCEPTED_FILES,
            },
          },
        });
      }

      showNotification({
        type: "error",
        message: "verifications.errors.uploadError",
        messageValues: {
          kind: {
            value: fileRejections.file.type,
          },
        },
      });
    },
    onDrop: (acceptedFiles) => {
      onDrop(acceptedFiles[0]);
    },
  });

  return (
    <div {...getRootProps()}>
      <div
        className={classNames({
          [classes.mask]: true,
          [classes.transparent]: !isDragActive,
          [classes.inactive]: !isDragActive,
        })}
      >
        Drop Files Here
      </div>
      {children}
    </div>
  );
};

export default DroppableLater;
