import { logo } from "assets";
import { createUseStyles } from "react-jss";
import { useAccount } from "hooks";

const useStyles = createUseStyles({
  logo: {
    padding: "20px 0",
    "& img": {
      width: "100%",
    },
  },
});

const Logo = () => {
  const classes = useStyles();
  const { account } = useAccount();

  return (
    <div className={classes.logo}>
      <img src={account?.page_header_logo || logo} alt="logo" />
    </div>
  );
};

export default Logo;
