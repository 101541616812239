import { useQuery } from "./tanstack";
import { Account } from "types";
import { getQueryKey } from "utils";
import { useSelector } from "./useReactRedux";

const useAccount = () => {
  const accountID = useSelector((state) => state.auth.staff?.account_id);
  const queryKey = getQueryKey("account", accountID);
  const { data, isLoading } = useQuery<Account>({
    apiName: "accounts",
    path: `/${accountID}`,
    enabled: !!accountID,
    queryKey,
  });

  return {
    account: data,
    accountLoading: isLoading,
    queryKey,
  };
};

export default useAccount;
