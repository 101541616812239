import { FC, useState } from "react";
import { ExtendedSteps } from "components";
import * as LC from "./components";
import { useNavigate } from "hooks";
import { Step2, Step3, Types } from "../shared";
import { routes } from "configs";

const CreateVerifyInternal: FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<{
    currentStep: number;
    record: Types.VerifyInternalRecord | null;
  }>({
    currentStep: 0,
    record: null,
  });

  const steps = [
    "button.create",
    "verifyInternal.documentUpload",
    "button.submit",
  ];

  const stepComponents = [
    <LC.Step1
      onSuccess={(record) => {
        navigate(
          routes.verifyInternal.view({
            id: record.id,
            accountID: record.accountID,
            applicantID: record.applicantID,
          })
        );
      }}
    />,
    <Step2
      record={state.record}
      onSuccess={(record) => {
        setState({
          record,
          currentStep: state.currentStep + 1,
        });
      }}
    />,
    <Step3
      record={state.record as Types.VerifyInternalRecord}
      onPrevClick={() => {
        setState((prevState) => ({
          ...prevState,
          currentStep: prevState.currentStep - 1,
        }));
      }}
    />,
  ];

  return (
    <>
      <ExtendedSteps steps={steps} currentStep={state.currentStep} />
      {stepComponents[state.currentStep]}
    </>
  );
};

export default CreateVerifyInternal;
