import { useQuery } from "./tanstack";
import { Staff } from "types";
import { getQueryKey } from "utils";
import { useSelector } from "./useReactRedux";

const useStaffList = () => {
  const cognitoID = useSelector((state) => state.auth.user?.aws_cognito_id);

  const { data = [], isLoading } = useQuery<Staff[]>({
    apiName: "staff",
    enabled: !!cognitoID,
    path: `/${cognitoID}/list`,
    queryKey: getQueryKey("staffList", cognitoID),
  });

  return {
    staffList: data,
    staffListLoading: isLoading,
  };
};

export default useStaffList;
