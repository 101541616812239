import { FC, useState } from "react";
import { Storage, getQueryKey } from "utils";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import {
  notification,
  Form,
  Row,
  Col,
  Flex,
  Button,
  FormattedMessage,
  FileUpload,
  InputCheckbox,
  InputSelect,
  CountriesSelect,
  InputDatePicker,
  InputText,
} from "components";
import { useMutation } from "hooks";
import { DynamicVariables, UploadDocumentsProps } from "./types";
import { VerifyInternalRecord } from "../types";

const UploadDocuments: FC<UploadDocumentsProps> = ({
  onSuccess,
  record,
  queryKey,
}) => {
  const [form] = Form.useForm();
  const [uploading, toggleUploading] = useState(false);

  const { isPending, mutate } = useMutation<
    VerifyInternalRecord,
    DynamicVariables
  >({
    method: "post",
    apiName: "verifyInternal",
    invalidateQueries: [
      getQueryKey("verifyInternalList", record?.accountID),
      ...(queryKey ? [queryKey] : []),
    ],
    path: `/${record?.id as number}/upload-documents`,
    onSuccess,
  });

  const isDocTypes =
    record?.applicant.requiredIdDocs.docSets.find(
      (item) => item?.idDocSetType === "IDENTITY"
    )?.types || [];

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={record?.applicant.info}
      onFinish={async ({
        idDocType,
        twoSided,
        file,
        frontFile,
        backFile,
        country,
        ...rest
      }) => {
        try {
          toggleUploading(true);

          let fileID: string | undefined;
          let frontFileID: string | undefined;
          let backFileID: string | undefined;

          const values = {
            country,
            idDocType,
            applicantID: record?.applicant.id,
            ...rest,
          };

          if (twoSided && frontFile?.length && backFile?.length) {
            const [{ originFileObj: frontFileObj }] = frontFile;
            const [{ originFileObj: backFileObj }] = backFile;
            const now = Date.now();

            [{ key: frontFileID }, { key: backFileID }] = await Promise.all([
              Storage.uploadData({
                bucket: "applicantImages",
                data: frontFileObj,
                key: `Front Side: ${now}-${frontFileObj.name}`,
              }).result,
              Storage.uploadData({
                bucket: "applicantImages",
                data: backFileObj,
                key: `Back side: ${now}-${backFileObj.name}`,
              }).result,
            ]);
          } else if (file?.length) {
            const [{ originFileObj }] = file;
            ({ key: fileID } = await Storage.uploadData({
              bucket: "applicantImages",
              data: originFileObj,
              key: `${Date.now()}-${originFileObj.name}`,
            }).result);
          }

          mutate({
            fileID,
            frontFileID,
            backFileID,
            accountID: record?.accountID,
            ...values,
          });
          toggleUploading(false);
        } catch (e) {
          toggleUploading(false);
          notification.error({
            message: e.message,
          });
        }
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Flex gap="middle">
            <InputSelect
              required
              allowClear
              name="idDocType"
              label="common.documentType"
              options={isDocTypes}
              getOptionProps={(opt) => ({
                value: opt,
                children: opt,
              })}
            />

            <InputCheckbox
              labelPlacement="formItem"
              name="twoSided"
              label="verifyInternal.twoSided"
            />
          </Flex>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Flex gap="middle" wrap="wrap">
            <Form.Item dependencies={["twoSided", "idDocType"]} noStyle>
              {({ getFieldsValue }) => {
                const { twoSided, idDocType } = getFieldsValue([
                  "twoSided",
                  "idDocType",
                ]);

                if (!idDocType) {
                  return null;
                }

                const FILE_UPLOAD_PROPS = {
                  required: true,
                  preserve: false,
                  listType: "picture",
                } as const;

                if (twoSided) {
                  return (
                    <>
                      <FileUpload
                        {...FILE_UPLOAD_PROPS}
                        label="Front side"
                        name="frontFile"
                      />
                      <FileUpload
                        {...FILE_UPLOAD_PROPS}
                        label="Back side"
                        name="backFile"
                      />
                    </>
                  );
                }

                return (
                  <FileUpload {...FILE_UPLOAD_PROPS} label="File" name="file" />
                );
              }}
            </Form.Item>
          </Flex>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <CountriesSelect
            required
            name="country"
            label="common.country"
            getOptionProps={(opt) => ({
              value: opt.iso_alpha_3,
              children: opt.full_name,
            })}
          />
          <InputDatePicker
            name="issuedDate"
            label="verifyInternal.issuedDate"
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputDatePicker
            name="validUntil"
            label="verifyInternal.validUntil"
          />
          <InputText name="number" label="verifyInternal.number" />
        </Col>
      </Row>
      <Row justify="end">
        <Button
          loading={isPending || uploading}
          type="primary"
          htmlType="submit"
        >
          <FormattedMessage id="button.next" />
        </Button>
      </Row>
    </Form>
  );
};

export default UploadDocuments;
