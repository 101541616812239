import { FC } from "react";
import { SumSub, Flex, Button, FormattedMessage, Space } from "components";
import { useMutation, useNavigate, useAccountSubscription } from "hooks";
import { routes } from "configs";
import { VerifyInternalRecord } from "../types";
import { getQueryKey } from "utils";

interface Step3Props {
  record: VerifyInternalRecord;
  onPrevClick?: () => void;
}

const Step3: FC<Step3Props> = ({ record, onPrevClick }) => {
  const { subscriptionActive } = useAccountSubscription();
  const navigate = useNavigate();
  const { applicant } = record;

  const { isPending, mutate } = useMutation<
    VerifyInternalRecord,
    { applicantID: string; accountID: number }
  >({
    method: "post",
    apiName: "verifyInternal",
    invalidateQueries: [
      getQueryKey("verifyInternalList", record.accountID),
      getQueryKey("verifyInternalRecord", record.id),
      getQueryKey("applicantData", record.applicantID),
      getQueryKey("subscriptionUsage", record.accountID),
    ],
    path: `/${record.id}/applicant-check`,
    onSuccess: () => {
      navigate(routes.verifyInternal.list());
    },
  });

  return (
    <>
      {applicant.review?.reviewStatus === "init" && (
        <Flex justify="end">
          <Space>
            {!applicant.requiredIdDocsStatus?.IDENTITY?.imageIds?.length && (
              <Button onClick={() => onPrevClick?.()} htmlType="button">
                <FormattedMessage id="button.previous" />
              </Button>
            )}
            {subscriptionActive && (
              <Button
                type="primary"
                loading={isPending}
                onClick={() => {
                  mutate({
                    applicantID: applicant.id,
                    accountID: record.accountID,
                  });
                }}
              >
                <FormattedMessage id="button.submit" />
              </Button>
            )}
          </Space>
        </Flex>
      )}
      <SumSub record={record?.applicant} />
    </>
  );
};

export default Step3;
