import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserGeo, TranslatedRecords, Language } from "types";
import { USER_GEO_API_KEY } from "consts";
import { showNotification, API } from "utils";

export const getTranslatedData = createAsyncThunk<TranslatedRecords, Language>(
  "GET_TRANSLATED_DATA",
  (language, { getState }) => {
    const options = {
      headers: {
        "app-language": language,
      },
    };

    return API.get({
      apiName: "system",
      path: "/localized-data",
      options,
    }).catch(() => {
      const state = getState() as any;

      showNotification({
        type: "error",
        language: state.appState.language,
        message: "messages.error.fetchSystemData",
      });
      return Promise.reject();
    });
  }
);

export const getUserGeo = createAsyncThunk<UserGeo>("GET_USER_GEO", () => {
  return fetch(`https://geolocation-db.com/json/${USER_GEO_API_KEY}`, {
    mode: "cors",
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      return Promise.reject(res);
    })
    .then((res) => {
      if (res.country_code) {
        return res;
      }
    })
    .catch(() => {
      // do nothing
    });
});
