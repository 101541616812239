import { FC } from "react";
import moment from "moment";
import { Layout, Typography } from "components";

const Footer: FC<{ appVersion: string | null }> = ({ appVersion }) => {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      <Typography.Text strong>eApplication Form Admin Portal</Typography.Text>
      &nbsp;
      <Typography.Text>©{moment().format("yyyy")}</Typography.Text>
      &nbsp; - &nbsp;
      <small>Created by</small>
      &nbsp;
      <Typography.Link href="https://deliostech.com" target="_blank">
        Delios Technology Inc
      </Typography.Link>
      <br />
      {appVersion && (
        <Typography.Text strong>
          <small>Version {appVersion}</small>
        </Typography.Text>
      )}
    </Layout.Footer>
  );
};

export default Footer;
