import { FC, useState, useEffect } from "react";
import { ExtendedTable, NavLink, Button, Icons } from "components";
import { CreateNew } from "./components";
import { routes } from "configs";
import { useAccountSubscription, useQuery, useSelector } from "hooks";
import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "consts";
import {
  VerifyExternal,
  ColumnFilterInputTypes,
  DashboardHOCChildProps,
} from "types";
import { getQueryKey } from "utils";

const List: FC<DashboardHOCChildProps> = ({ setConfig }) => {
  const accountID = useSelector(
    (state) => state.auth.staff?.account_id as number
  );
  const queryKey = getQueryKey("verifyExternalList", accountID);

  const { data, isLoading } = useQuery<VerifyExternal[]>({
    apiName: "accounts",
    path: `/${accountID}/verify-external`,
    queryKey,
  });

  const [drawerOpen, toggleDrawer] = useState(false);

  const { subscriptionActive } = useAccountSubscription();

  useEffect(() => {
    if (subscriptionActive) {
      setConfig({
        extra: (
          <Button
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => toggleDrawer(true)}
          />
        ),
      });
    }
  }, [setConfig, subscriptionActive]);

  return (
    <>
      <CreateNew
        invalidateQuery={queryKey}
        drawerOpen={drawerOpen}
        onClose={() => toggleDrawer(false)}
      />
      <ExtendedTable<VerifyExternal>
        loading={isLoading}
        dataSource={data}
        columns={[
          {
            title: "common.id",
            dataIndex: "uuid",
            defaultSortOrder: "ascend",
            sorter: (a, b) => b.id - a.id,
            render: ({ record }) => (
              <NavLink to={routes.verifyExternal.view(record.uuid)}>
                {record.id}
              </NavLink>
            ),
          },
          {
            title: "common.email",
            dataIndex: "email",
          },
          {
            title: "common.firstName",
            dataIndex: "firstName",
          },
          {
            title: "common.lastName",
            dataIndex: "lastName",
          },
          {
            title: "date.createdAt",
            dataIndex: "createdAt",
            filterInputType: ColumnFilterInputTypes.DATE_RANGE,
            render: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
          },
          {
            title: "date.openedAt",
            dataIndex: "openedAt",
            filterInputType: ColumnFilterInputTypes.DATE_RANGE,
            render: ({ value }) =>
              value && dayjs(value).format(DATE_TIME_FORMAT),
          },
        ]}
      />
    </>
  );
};

export default List;
