import { FC, useRef } from "react";
import { Form, Row, Col, InputEmail, SubmitButton } from "components";
import { GOOGLE_API_KEY, ACCOUNT_SETTINGS_TABS } from "consts";
import { AddressElement, useElements } from "@stripe/react-stripe-js";
import { createUseStyles } from "react-jss";
import { StripeAddressElementValue, Account, QueryKey } from "types";
import { useMutation, useQueryParams } from "hooks";

const useStyles = createUseStyles({
  button: {
    marginTop: "1rem",
  },
});

interface ContactProps {
  account: Account;
  accountQueryKey: QueryKey;
  billingTabKey: typeof ACCOUNT_SETTINGS_TABS.CURRENT_SUBSCRIPTION;
}

interface MutationVars
  extends Omit<StripeAddressElementValue["value"], "name">,
    Pick<Account, "customer_service_email"> {
  organizationName: string;
}

const Contact: FC<ContactProps> = ({
  accountQueryKey,
  account,
  billingTabKey,
}) => {
  const classes = useStyles();
  const elements = useElements();
  const [, setQueryParams] = useQueryParams();
  const billingExists = useRef(!!account.billingID);
  const { mutate, isPending } = useMutation<Account, MutationVars>({
    method: account.billingID ? "patch" : "post",
    apiName: "accounts",
    path: `/${account.id}/contact`,
    invalidateQueries: [accountQueryKey],
    onSuccess: () => {
      if (!billingExists.current) {
        billingExists.current = true;

        setQueryParams({
          tab: billingTabKey,
        });
      }
    },
  });

  return (
    <Form
      layout="vertical"
      initialValues={account}
      onFinish={async (values) => {
        const {
          complete,
          value: { name: organizationName, phone, address },
        } = await (elements
          ?.getElement("address")
          ?.getValue() as any as Promise<StripeAddressElementValue>);

        if (complete) {
          mutate({
            ...values,
            phone,
            organizationName,
            address,
          });
        }
      }}
    >
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <InputEmail
            required
            name="customer_service_email"
            label="account.customer_service_email"
          />
        </Col>
        <Col span={12}>
          <AddressElement
            options={{
              display: {
                name: "organization",
              },
              mode: "billing",
              fields: {
                phone: "always",
              },
              autocomplete: {
                mode: "google_maps_api",
                apiKey: GOOGLE_API_KEY,
              },
              defaultValues: {
                phone: account.stripePhone,
                name: account.stripeName,
                address: account.address || undefined,
              },
            }}
          />
        </Col>
      </Row>

      <SubmitButton rowClassName={classes.button} loading={isPending} />
    </Form>
  );
};

export default Contact;
