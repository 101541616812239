import { FC } from "react";
import {
  Col,
  Form,
  InputCheckbox,
  Row,
  SubmitButton,
  ExtendedDescriptions,
} from "components";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { useMutation } from "hooks";
import { Staff } from "types";
import { getQueryKey } from "utils";

const StaffProfile: FC<{ staff: Staff }> = ({ staff }) => {
  const { isPending, mutate } = useMutation({
    apiName: "staff",
    path: `/${staff.id}/permissions`,
    method: "patch",
    invalidateQueries: [getQueryKey("staffRecord", staff.id)],
  });

  return (
    <Form layout="vertical" initialValues={staff.permissions} onFinish={mutate}>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <ExtendedDescriptions
            items={[
              {
                label: "common.first_name",
                value: staff.first_name,
              },
              {
                label: "common.last_name",
                value: staff.last_name,
              },
              {
                label: "common.email",
                value: staff.email,
              },
            ]}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputCheckbox name="staff_view" label="staffPermissions.staffView" />
          <InputCheckbox
            name="staff_admin"
            label="staffPermissions.staffAdmin"
          />
          <InputCheckbox
            name="account_admin"
            label="staffPermissions.accountAdmin"
          />
        </Col>
      </Row>
      <SubmitButton loading={isPending} />
    </Form>
  );
};

export default StaffProfile;
