import { useState, FC } from "react";
import {
  Modal,
  Form,
  NewPassword,
  Row,
  Space,
  Button,
  FormattedMessage,
  notification,
} from "components";
import { authActions } from "configs";
import { API, Auth } from "utils";
import { useNotification, useDispatch } from "hooks";

interface ModalProps {
  open: boolean;
  onCancel(): void;
}

const ChangePasswordModal: FC<ModalProps> = ({ open, onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, toggleLoading] = useState(false);
  const showNotification = useNotification();

  const onSubmit = async (values: {
    oldPassword: string;
    newPassword: string;
  }) => {
    toggleLoading(true);

    try {
      await Auth.updatePassword(values);

      const { userSub } = await Auth.fetchAuthSession();

      if (userSub) {
        const user = await API.patch({
          apiName: "user",
          path: "/password",
          options: {
            body: {
              cognitoID: userSub,
            },
          },
        });

        dispatch(authActions.setUserStaff(user));
      }

      showNotification({
        type: "success",
        message: "messages.success",
      });

      onCancel();
    } catch (e) {
      notification.error({
        message: e.message,
      });
    }

    toggleLoading(false);
  };

  return (
    <Modal
      title="Change Password"
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <p>
        Please enter your current password.
        <br />
        <br />
        You should also enter a new password (and confirm it) in the fields
        below.
        <br />
      </p>
      <Form form={form} layout="vertical" onFinish={onSubmit} preserve={false}>
        <NewPassword />
        <Row align="bottom" justify="end">
          <Space>
            <Button type="default" onClick={onCancel}>
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              <FormattedMessage id="button.ok" />
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
