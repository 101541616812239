const showItem = ({
  item,
  flag,
  defaultValue = [],
}: {
  item: any;
  flag: boolean;
  defaultValue?: any;
}) => (flag ? item : defaultValue);

export default showItem;
