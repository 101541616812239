import { FC, useEffect } from "react";
import { Tabs, FormattedMessage, Verification } from "components";
import * as LC from "./components";
import { useQueryParams, useNavigate, useAccount } from "hooks";
import { viewHOC } from "hocs";
import { ViewProps } from "./types";
import { ApplicationMode, DashboardHOCChildProps } from "types";
import { routes } from "configs";
import { getQueryKey, showItem } from "utils";

const View: FC<ViewProps & DashboardHOCChildProps> = ({
  record,
  setConfig,
}) => {
  const { account } = useAccount();
  const navigate = useNavigate();
  const [params] = useQueryParams();

  useEffect(() => {
    if (!record) {
      setConfig({ subTitleValues: { id: null } });

      return;
    }

    if (record.modeId !== ApplicationMode.live) {
      navigate(routes.applications.main, { replace: true });
      return;
    }

    setConfig({ subTitleValues: { id: record.id } });
  }, [setConfig, record, navigate]);

  const APP_TYPE = {
    1: [],
    2: [
      {
        key: "company",
        label: "Company",
        children: <LC.Company app={record} />,
      },
    ],
    3: [
      {
        key: "Trust",
        label: "Trust",
        children: <LC.Trust app={record} />,
      },
    ],
  };

  return (
    <Tabs
      defaultActiveKey={params.tab || "summary"}
      items={[
        {
          key: "summary",
          label: "Summary",
          children: <LC.Summary record={record} />,
        },
        ...(APP_TYPE[record.typeId] || []),
        ...(record.statusId === 2
          ? [
              {
                key: "messaging",
                label: "Messaging",
                children: <LC.Messaging appID={record.id} />,
              },
            ]
          : []),
        {
          key: "Verification",
          label: "Verification",
          children: (
            <Verification parentID={record.id} parentRoute="applications" />
          ),
        },
        {
          key: "contact",
          label: "Contact",
          children: (
            <LC.Contact
              appID={record.id}
              telWork={record.telWork}
              telHome={record.telHome}
              telMobile={record.telMobile}
            />
          ),
        },
        {
          key: "poa",
          label: "Proof of Address",
          children: <LC.PoaTable appID={record.id} />,
        },
        {
          key: "personal",
          label: "Personal",
          children: <LC.Personal appID={record.id} />,
        },
        ...showItem({
          flag: !!account?.applicationSettings.financial,
          defaultValue: [],
          item: [
            {
              key: "financial",
              label: "Financial",
              children: <LC.Financial appID={record.id} />,
            },
          ],
        }),
        ...showItem({
          flag: !!account?.applicationSettings.taxation,
          defaultValue: [],
          item: [
            {
              key: "Taxation",
              label: "Taxation",
              children: <LC.Taxation appID={record.id} />,
            },
          ],
        }),
        ...showItem({
          flag: !!account?.applicationSettings.knowledge,
          defaultValue: [
            {
              key: "accountTransactions",
              label: "Account Transactions",
              children: <LC.AccountTransactions appID={record.id} />,
            },
          ],
          item: [
            {
              key: "knowledge",
              label: "Knowledge",
              children: <LC.Knowledge app={record} />,
            },
          ],
        }),
        ...showItem({
          flag: !!account?.applicationSettings.compliance,
          defaultValue: [],
          item: [
            {
              key: "compliance",
              label: "Compliance",
              children: <LC.Compliance appID={record.id} />,
            },
          ],
        }),
        ...showItem({
          flag: !!account?.applicationSettings.edd,
          defaultValue: [],
          item: [
            {
              key: "edd",
              label: <FormattedMessage id="enhancedDueDiligence.title" />,
              children: <LC.EDD appID={record.id} />,
            },
          ],
        }),
      ]}
    />
  );
};

export default viewHOC({
  Component: View,
  apiName: "applications",
  pathFn: ({ params }) => `/${params.id}`,
  queryKeyFn: ({ params, accountID }) =>
    getQueryKey("applicationRecord", params.id, accountID),
});
