import { notification } from "antd";
import getIntl from "./getIntl";
import { Language } from "types";

type IconType = "success" | "info" | "error" | "warning";

interface Params {
  type: IconType;
  language: Language;
  message: string;
  messageValues?: Record<any, string | number>;
  description?: string;
}

const showNotification = ({
  language = "en",
  type,
  message,
  messageValues,
  description,
}: Params) => {
  const intl = getIntl(language);

  notification[type]({
    message: intl.formatMessage({ id: message }, messageValues),
    description: description && intl.formatMessage({ id: description }),
  });
};

export default showNotification;
