import { FC } from "react";
import { BillingPlanCard } from "../create-subscription";
import { Typography, Tag, Row, Col, Button, Popconfirm } from "components";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import dayjs from "dayjs";
import { useDeleteMutation } from "hooks";
import { Subscription, QueryKey } from "types";
import { createUseStyles } from "react-jss";

interface ActiveSubscriptionProps {
  subscription: Subscription;
  subscriptionQueryKey: QueryKey;
}

const useStyles = createUseStyles({
  title: {
    marginTop: 0,
  },
});

const ActiveSubscription: FC<ActiveSubscriptionProps> = ({
  subscription,
  subscriptionQueryKey,
}) => {
  const classes = useStyles();
  const { billingPlan, billingPlanID, periodStart, periodEnd } = subscription;

  const { mutateAsync: cancel, isPending } = useDeleteMutation({
    apiName: "accounts",
    path: `/${subscription.accountID}/subscription`,
    invalidateQueries: [subscriptionQueryKey],
  });

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Typography.Title level={5} className={classes.title}>
            Subscription Details:&nbsp;<Tag color="green">Active</Tag>
          </Typography.Title>
          <Typography.Paragraph>
            Current period: {dayjs(periodStart).format("D MMM")}
            &nbsp;-&nbsp;{dayjs(periodEnd).format("D MMM")}
          </Typography.Paragraph>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Typography.Title level={5} className={classes.title}>
            Billing plan
          </Typography.Title>
          <BillingPlanCard
            name={billingPlan}
            id={billingPlanID}
            selectedID={billingPlanID}
            defaultPrice={subscription.defaultPrice}
            hoverable={false}
          />
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 32 }}>
        <Popconfirm
          title="Are you sure to cancel this subscription?"
          description="Access to some features will be limited"
          okText="Yes"
          cancelText="No"
          okButtonProps={{ loading: isPending }}
          onConfirm={async () => {
            await cancel();
          }}
        >
          <Button danger>Cancel</Button>
        </Popconfirm>
      </Row>
    </>
  );
};

export default ActiveSubscription;
