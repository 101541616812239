import { FC } from "react";
import {
  Form,
  Row,
  Col,
  InputText,
  InputCheckbox,
  SubmitButton,
  FormColorPicker,
} from "components";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { Types } from "../../duck";

const Summary: FC<Types.TabProps> = ({ account, updating, onFinish }) => {
  return (
    <Form
      layout="vertical"
      name="summary"
      initialValues={account}
      onFinish={(values) => {
        onFinish({
          ...values,
          ...(typeof values.color !== "string"
            ? { color: values.color?.toHexString() }
            : {}),
        });
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputText required name="account_name" label="common.accountName" />
          <FormColorPicker label="common.color" name="color" />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Row gutter={[24, 24]}>
            <InputCheckbox
              labelPlacement="formItem"
              name="disabledDemo"
              label="settings.disabledDemo"
            />
            <InputCheckbox
              labelPlacement="formItem"
              name="disabledLive"
              label="settings.disabledLive"
            />
          </Row>
        </Col>
      </Row>
      <SubmitButton loading={updating} />
    </Form>
  );
};

export default Summary;
