import { FC } from "react";
import {
  Col,
  Row,
  FormattedMessage,
  Typography,
  InputEmail,
  InputText,
  InputSelect,
  InputDatePicker,
} from "components";
import { constants } from "../../duck";
import { Country, TranslatedRecord } from "types";

const PREFIX = "info";

interface InfoProps {
  countryList: Country[];
  genders: TranslatedRecord[];
}

const Info: FC<InfoProps> = ({ countryList, genders }) => {
  return (
    <>
      <Row justify="center">
        <Typography.Title level={5}>
          <FormattedMessage id="verifyInternal.fixedInfo" />
        </Typography.Title>
      </Row>
      <Row gutter={14} justify="space-around">
        <Col {...constants.COL_PROPS}>
          <InputText
            required
            name={[PREFIX, "firstName"]}
            label="common.firstName"
          />
          <InputText
            required
            name={[PREFIX, "lastName"]}
            label="common.lastName"
          />
          <InputEmail required name={[PREFIX, "email"]} label="common.email" />
          <InputText name={[PREFIX, "middleName"]} label="common.middleName" />
        </Col>
        <Col {...constants.COL_PROPS}>
          <InputSelect
            name={[PREFIX, "gender"]}
            label="gender.title"
            options={genders}
            getOptionProps={(opt) => ({
              value: opt.label.slice(0, 1).toUpperCase(),
              children: opt.label,
            })}
          />
          <InputDatePicker
            isAfterToday
            name={[PREFIX, "dob"]}
            label="common.dateOfBirth"
          />
        </Col>
        <Col {...constants.COL_PROPS}>
          <InputText
            name={[PREFIX, "placeOfBirth"]}
            label="common.placeOfBirth"
          />
          <InputText
            name={[PREFIX, "countryOfBirth"]}
            label="common.placeOfBirthCountry"
          />
        </Col>
        <Col xs={12} sm={8} md={4} lg={4}>
          <InputSelect
            name={[PREFIX, "nationality"]}
            label="verifyInternal.nationality"
            options={countryList}
            getOptionProps={(opt) => ({
              value: opt.iso_alpha_3,
              children: opt.full_name,
            })}
          />
          <InputText
            name={[PREFIX, "stateOfBirth"]}
            label="verifyInternal.stateOfBirth"
          />
        </Col>
      </Row>
    </>
  );
};

export default Info;
